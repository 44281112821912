import { IProductSearch } from '@/types/product.types'
import { IPlatform } from '@/types/platform.types'

export interface IPromotion {
  id?: number;
  name: string;
  from: string;
  to: string;
  enabled: boolean;
  user_name?: string;
  platforms: IPlatform[];
  type: IPromotionType | '';
  is_can_remove: boolean;
}

const defaultDateTime: Date = new Date()
defaultDateTime.setMinutes(0)
defaultDateTime.setSeconds(0)

export const defaultPromotion: IPromotion = {
  name: '',
  from: defaultDateTime.toString(),
  to: defaultDateTime.toString(),
  enabled: false,
  type: '',
  platforms: [{
    id: 1,
    type: 'site',
  }],
  is_can_remove: true,
}

export enum IPromotionType {
  SALE = 'sale',
  PROMO_CODE = 'promo-code',
}

export interface IPromotionCategory {
  id: number;
  name: string;
  with_children: boolean;
  discount: number | null;
  percent: number | null;
  is_increased_discount: boolean;
}

export interface IEditPromotionCategory {
  category_id: number | null;
  discount: number | null;
  percent: number | null;
  with_children: boolean;
}

export const defaultEditPromotionCategory: IEditPromotionCategory = {
  category_id: null,
  discount: null,
  percent: null,
  with_children: false,
}

export interface ISelectedPromotionProduct extends IProductSearch {
  discountChoice: 0|1;
  allColorsSwitch: boolean;
  discountOrPercent: string | null;
}

export interface IAttachPromotionProduct {
  product_id: number | null;
  discount?: number;
  percent?: number;
  color_id?: number;
}

export interface IPromotionProduct {
  id: number;
  article_number: string;
  name: string;
  color_id: number;
  image_id: number;
  price: number;
  percent: number | null;
  discount: number | null;
  is_increased_discount: boolean;
}

export const defaultPromotionProduct: IPromotionProduct = {
  id: 0,
  article_number: '',
  name: '',
  color_id: 0,
  image_id: 0,
  price: 0,
  percent: null,
  discount: null,
  is_increased_discount: false,
}

export interface IEditPromotionProduct {
  product_id: number | null;
  discount?: number;
  percent?: number;
  color_id?: number;
}