import tokenService from '@/services/tokenService'
import axiosWithSettings from '@/axiosWithSettings'
import { IUser } from '@/types/user.types'
import { IPermission, IRole } from '@/types/roleAndPermission.types'

export const userStore = {
  namespaced: true,
  state: (): {
    user?: IUser,
    roles: IRole[],
    permissions: IPermission[],
  } => ({
    roles: [],
    permissions: [],
  }),
  getters: {
    isLogged(state): boolean {
      return !!state.user
    },
    getRoles(state): IRole[] {
      return state.roles
    },
    getPermissions(state): IPermission[] {
      return state.permissions
    },
    getUser(state): IUser {
      return state.user
    },
  },
  mutations: {
    setUser(state, data: IUser): void {
      state.user = data

      state.roles = data.roles
      let allPermissions: IPermission[] = []

      data.roles.forEach((role: IRole): void => {
        allPermissions = allPermissions.concat(role.permissions)
      })
      state.permissions = allPermissions
    },
  },
  actions: {
    logout(context): void {
      axiosWithSettings.post(process.env.VUE_APP_CATALOG_API_URL + '/logout')
        .then((): void => {
          tokenService.removeAuthToken()
          context.commit('setUser', null)
        })
    },
  },
}