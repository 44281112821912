import axios from 'axios'
import { ElNotification } from 'element-plus'

export const brandsStore = {
  namespaced: true,
  state: () => ({
    brands: [],
  }),

  getters: {
    brands(state) {
      return state.brands
    },
  },

  mutations: {
    fetchBrands(state, brands) {
      state.brands = brands
    },
  },

  actions: {
    async fetchBrands(context) {
      await axios.get('/brands')
        .then(res => {
          context.commit('fetchBrands', res.data.brands)
          return res
        })
        .catch(err => {
          ElNotification({
            title: 'Error',
            message: err.response.data.message,
            type: 'error',
          })
          throw new Error(err.response.data.message)
        })
    },
  },
}