<template>
  <marketplaces-form
    :initial-form-data="{...formData}"
    :handle-save="save"
    :message="message"
    :is-creating="true"
    :model-value="modelValue"
    :title="title"
    @close="$emit('update:modelValue', false)" />
</template>

<script setup>

  import { watch } from 'vue'
  import MarketplacesForm from '@/components/marketplace/MarketplacesForm'
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  const store = useStore()
  const emit = defineEmits(['create'])
  defineProps({
    modelValue: Boolean,
    title: String,
  })

  const formData = ref({
    name: '',
    short_name: '',
    currency_sign: '',
    brand_id: '',
  })
  const message = ref('')

  const brand = store.getters['main/currentBrand']
  if (brand) {
    formData.value.brand_id = brand.id
  }

  watch(() => store.getters['main/currentBrand'], function(brand) {
    formData.value.brand_id = brand.id
  })

  const save = async (newObject) => {
    emit('create', newObject)
  }

</script>

<style scoped>

</style>