<script setup>
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import RequestHelper from '@/helpers/RequestHelper'
  import { ElNotification } from 'element-plus'
  import { useRoute } from 'vue-router'

  const route = useRoute()

  const request = new RequestHelper()
  const store = useStore()
  const dialog = ref(false)

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })
  const newFit = ref({
    fit_id: null,
    sizes: {},
  })

  const fitsForm = ref(null)
  const createFitForm = ref(null)

  const fitCollection = computed({
    get() {
      const selectedIds = fits.value.map((fit) => fit.id)
      const fitCollection = store.getters['fitsStore/fits']
      return fitCollection.map((fit) => {
        fit.disabled = selectedIds.includes(fit.id)
        return fit
      })
    },
  })

  const fits = computed({
    get() {
      const result = []
      if (store.getters['productStore/fits'] == undefined) {
        return []
      }
      for (const fit of store.getters['productStore/fits']) {
        const find = result.find((m) => m.id === fit.id)
        const size = store.getters['sizesStore/sizes'].find((s) => s.id === fit.size_id)

        if (size) {
          if (find) {
            find.sizes[size.name] = { value: fit.value, id: fit.size_id }
          } else {
            result.push({
              id: fit.id,
              name: fit.name,
              sizes: {
                [size.name]: { value: fit.value, id: fit.size_id },
              },
            })
          }
        }
      }
      return result
    },
  })

  async function attachFit() {
    if (createFitForm.value == null || !createFitForm.value.checkValidity()) {
      createFitForm.value.reportValidity()
      return
    }
    let totalRes = true
    for (const i in newFit.value.sizes) {
      await request
        .put(`products/${store.getters['productStore/product'].id}/attach/fit`, {
          fit_id: newFit.value.fit_id,
          size_id: i,
          value: newFit.value.sizes[i],
        })
        .catch(() => {
          if (totalRes) totalRes = false
        })
    }
    if (totalRes) {
      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Подходящий размер обновлен',
      })
      await store.dispatch('productStore/fetchProduct', { id: route.params.id })
      dialog.value = false
    }
  }

  async function detachFit(fit) {
    await request
      .put(`products/${store.getters['productStore/product'].id}/detach/fit`, {
        fit_id: fit.id,
      })
      .then((res) => {
        if (res.status === 200) {
          ElNotification({
            title: 'Успех',
            type: 'success',
            message: 'Подходящий размер откреплён',
          })
        }
      })
    await store.dispatch('productStore/fetchProduct', { id: route.params.id })
  }

  function closeDialog() {
    newFit.value = {
      fit_id: null,
      sizes: {},
    }
    dialog.value = false
  }

  //TODO: переделать на сохранение массивом и скорее всего по кнопке
  async function saveOneFit(fitData) {
    if (fitsForm.value == null || !fitsForm.value.checkValidity()) {
      fitsForm.value.reportValidity()
      return
    }
    let totalRes = true
    for (const i in fitData.sizes) {
      await request
        .put(`products/${store.getters['productStore/product'].id}/attach/fit`, {
          fit_id: fitData.id,
          size_id: fitData.sizes[i].id,
          value: fitData.sizes[i].value,
        })
        .catch(() => {
          if (totalRes) totalRes = false
        })
    }
    if (totalRes) {
      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Подходящий размер обновлен',
      })
      await store.dispatch('productStore/fetchProduct',{ id: route.params.id })
      await closeDialog()
    }
  }
</script>

<template>
  <div>
    <h3>Подходит</h3>
    <br>
    <form ref="fitsForm">
      <el-table :data="fits">
        <el-table-column
          label="Название подходящего"
          prop="name"
          fixed />
        <el-table-column
          v-for="size in store.getters['productStore/sizes']"
          :key="size.id"
          :label="size.name"
          :prop="'sizes.' + size.name">
          <template #default="scope">
            <el-input
              v-model="scope.row['sizes'][size.name]['value']"
              :disabled="!canEdit"
              type="number"
              :min="1"
              required
              :prop="scope.row['sizes'][size.name]['value']"
              @change="saveOneFit(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column
          label="Действия"
          width="100px"
          fixed="right">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-col :span="6">
                <el-button
                  :disabled="!canEdit"
                  type="danger"
                  icon="Delete"
                  circle
                  @click="detachFit(scope.row)" />
              </el-col>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </form>
    <el-button
      :disabled="!canEdit"
      class="mt-4 mb-2 mt-2"
      type="primary"
      plain
      style="width: 100%"
      @click="dialog = true">
      Добавить подходящее
    </el-button>

    <el-dialog
      v-model="dialog"
      title="Добавить подходящее"
      @close="closeDialog">
      <div class="dialog_body">
        <form ref="createFitForm">
          <div class="size_input">
            <span>подходящее</span>
            <el-select
              v-model="newFit.fit_id"
              placeholder="Выберите подходящее">
              <el-option
                v-for="fit in fitCollection"
                :key="fit.id"
                :label="fit.name"
                :value="fit.id"
                :disabled="fit.disabled" />
            </el-select>
          </div>
          <div
            v-for="size in store.getters['productStore/sizes']"
            :key="size.id"
            class="size_input">
            <span>{{ size.name }}</span>
            <el-input
              v-model="newFit.sizes[size.id]"
              type="number"
              :min="1"
              required />
          </div>
        </form>
      </div>

      <template #footer>
        <el-button
          type="primary"
          @click="attachFit">
          Сохранить
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.dialog_body {
  display: flex;
  align-items: center;

  .size_input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 0;

    span {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .el-input {
      width: 70px;
    }

    &:first-child {
      span {
        text-transform: none;
      }
    }
  }
}

.el-input {
  width: 100px;
}
</style>
