import { IProductSearch } from '@/types/product.types'
import { defaultJsonField, DefaultJsonType } from '@/types/defaultJsonField.types'

export interface IProductSelection {
  id?: number;
  name: string;
  slug: string;
  description: string;
  seo_description: DefaultJsonType;
  seo_keywords: DefaultJsonType;
  ai_description: DefaultJsonType;
  created_at: string;
  updated_at: string;
}

export const defaultProductSelection: IProductSelection = {
  name: '',
  slug: '',
  description: '',
  seo_description: { ...defaultJsonField },
  seo_keywords: { ...defaultJsonField },
  ai_description: { ...defaultJsonField },
  created_at: '',
  updated_at: '',
}

export interface ISelectionHasProduct {
  product_id: number;
  color_id: number;
  position: number;
  product_name: string;
  color_name: string;
  article_number: string;
}

export const defaultSelectionProduct: ISelectionHasProduct = {
  product_id: 0,
  color_id: 0,
  position: 0,
  product_name: '',
  color_name: '',
  article_number: '',
}

export interface ISelectedSelectionProduct extends IProductSearch {
  allColorsSwitch: boolean;
}

export interface IAttachSelectionProduct {
  product_id: number | null;
  color_id?: number;
}