<script setup>
  import { useStore } from 'vuex'
  import { ref, computed } from 'vue'
  import AddColor from '@/components/product/AddColor'
  import ImageHelper from '@/helpers/ImageHelper'
  import ImageColorPicker from './ImgColorPicker'
  import { ElLoading } from 'element-plus'
  import { EditPen } from '@element-plus/icons-vue'

  const imageHelper = new ImageHelper()

  const store = useStore()


  const shadeDialogVisible = ref(false)
  const shade = ref()
  const shadedColor = ref({})
  const shadedImage = computed(() => {
    return store.getters['productStore/images'].find(i => i.color_id === shadedColor.value.id)
  })

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
    canAdd: {
      type: Boolean,
      default: false,
    },
  })

  function shadeColor(color) {
    shadedColor.value = color
    shade.value = color.custom_value
    shadeDialogVisible.value = true
  }

  function selectColor(color) {
    store.commit('productStore/selectColorId', color.id)
  }

  async function attachColor() {
    await store.dispatch('productStore/attachColor', {
      id: shadedColor.value.id,
      shade: shade.value,
    }).then(() => {
      shadeDialogVisible.value = false
    })
  }

  const imageColorPicker = ref(null)
  function setColorFromImage(color) {
    shade.value = color
  }

  function closeDialog() {
    shade.value = null
  }

  async function destroyColor () {
    const loading = ElLoading.service({
      lock: true,
      text: 'Загрузка...',
      background: 'rgba(0, 0, 0, 0.7)',
    })

    try {
      await store.dispatch('productStore/destroyColor', shadedColor.value.id)
      await store.dispatch('productStore/fetchProduct', { id: store.getters['productStore/product'].id })
    }
    finally {
      loading.close()
      shadeDialogVisible.value = false
    }

  }

</script>

<template>
  <div :class="{cardColors: true, horizontal: false}">
    <div
      v-for="color in store.getters['productStore/colors']"
      :key="color.id"
      class="wrapper">
      <div
        v-if="canEdit"
        class="edit"
        @click="shadeColor(color)">
        <el-icon>
          <EditPen />
        </el-icon>
      </div>
      <div
        :class="{color: true, selected: store.getters['productStore/selectedColor']===color.id}"
        :data-color="color.value"
        :style="{background: color.custom_value}"
        @click="selectColor(color)">
        <span>{{ color.name.ru }}</span>
      </div>
    </div>

    <el-dialog
      v-model="shadeDialogVisible"
      width="400px"
      :title="`Уточнение оттенка &quot;${shadedColor.name?.ru}&quot;`"
      append-to-body
      @close="closeDialog">
      <el-color-picker
        v-model="shade"
        size="large" />
      <div
        v-if="shadedImage&&shadeDialogVisible"
        class="image-color-picker">
        <ImageColorPicker
          ref="imageColorPicker"
          :width="290"
          :show-color="true"
          :init-color="shade"
          :imagesrc="imageHelper.getUrl(shadedImage.id)"
          @setColor="setColorFromImage" />
      </div>
      <template #footer>
        <span class="dialog-footer">

          <el-popconfirm
            width="330"
            confirm-button-type="danger"
            confirm-button-text="Удалить"
            cancel-button-text="Отмена"
            hide-icon
            title="Действительно хотите удалить?"
            @confirm="destroyColor">
            <template #reference>
              <el-button
                v-if="!store.getters['productStore/product'].is_published"
                type="danger"
                style="float: left;">Удалить</el-button>
            </template>
          </el-popconfirm>

          <el-button @click="shadeDialogVisible = false">Закрыть</el-button>
          <el-button
            type="primary"
            @click="attachColor">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>
    <AddColor v-if="canAdd" />
  </div>
</template>


<style lang="scss" scoped>
.cardColors {
  display: flex;
  align-items: center;
  position: sticky;
  top: -20px;
  padding: 10px 0;
  z-index: 100;
  background: white;

  .wrapper {
    position: relative;

    .color {
      position: relative;
      z-index: 10;
    }

    .edit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      height: 30px;
      position: absolute;
      bottom: 10px;
      left: 5%;
      background: #409eff;
      z-index: 1;
      cursor: pointer;
      border-radius: 0 0 5px 5px;
      color: white;
      transition: bottom 200ms;

      &:hover {
        transition: color 200ms;
      }
    }

    &:hover {
      .edit {
        bottom: -20px;
      }
    }
  }
}
.image-color-picker{
  margin-top: 20px;
}
</style>
