<script setup>
  import { useStore } from 'vuex'
  import { ref, watch } from 'vue'

  const store = useStore()

  const promotions = ref([])
  const selectedPromotionColor = ref()
  const currentPromotion = ref()

  watch(() => store.getters['productStore/selectedColor'], (selectedColor) => {
    promotions.value = store.getters['productStore/product'].promotions
    selectedPromotionColor.value = selectedColor
    setCurrentPromotion()
  })

  const setCurrentPromotion = () => {
    currentPromotion.value = promotions.value.filter(
      (promotion) => promotion.color_id === selectedPromotionColor.value,
    )[0]
  }
</script>

<template>
  <div>
    <el-row>
      <el-col class="row-column">
        <span>Акции: </span>
        <el-row
          v-if="currentPromotion"
          style="margin-bottom: 0">
          <el-tag
            style="margin-left: 10px"
            size="large"
            type="info">
            {{ currentPromotion.name }}:
            {{ currentPromotion.price + ' ₽' }}
            ({{ currentPromotion.percent
              ? currentPromotion.percent + '%'
              : currentPromotion.discount + '₽'
            }})
          </el-tag>
        </el-row>
      </el-col>
    </el-row>
    <el-divider />
  </div>
</template>

<style lang="scss" scoped>
.el-input {
  margin-left: 10px;
  margin-right: 20px;
  width: 200px;
}
</style>