import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const sizesStore = {
  namespaced: true,
  state: () => ({
    sizes: [],
  }),

  getters: {
    sizes(state) {
      return state.sizes
    },
  },

  mutations: {
    async fetchSizes(state) {
      await request.get('sizes').then((res)=>{
        state.sizes = res.data.sizes
      })
    },
  },

  actions: {
    async fetchSizes(context, id) {
      await context.commit('fetchSizes', id)
    },
  },
}