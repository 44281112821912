import { createStore } from 'vuex'
import { mainStore } from '@/store'
import { productStore } from '@/store/product'
import { languagesStore } from '@/store/languages'
import { photoModelsStore } from '@/store/photoModels'
import { categoriesStore } from '@/store/categories'
import { measurementsStore } from '@/store/measurements'
import { marketplacesStore } from '@/store/marketplaces'
import { sizesStore } from '@/store/sizes'
import { imagesStore } from '@/store/images'
import { colorsStore } from '@/store/colors'
import { colorGroupsStore } from '@/store/colorGroups'
import { sitesStore } from '@/store/sites'
import { compositionsStore } from '@/store/compositions'
import { collectionsStore } from '@/store/collections'
import { fitsStore } from '@/store/fits'
import { manufactureCategoriesStore } from '@/store/manufactureCategory'
import { manufactureGroupsStore } from '@/store/manufactureGroups'
import { manufactureTypesStore } from '@/store/manufactureTypes'
import { capsulesStore } from '@/store/capsules'
import { manufactureCaresStore } from '@/store/manufactureCares'
import setupInterceptors from '@/services/setupInterceptors'
import { brandsStore } from '@/store/brands'
import { productSalePlatformsStore } from '@/store/productSalePlatforms'
import { userStore } from '@/store/user'
import { permissionStore } from '@/store/permissions'
import { roleStore } from '@/store/roles'
import { compositionGroupsStore } from '@/store/compositionGroups'

export const store = createStore({
  modules: {
    main: mainStore,
    productStore,
    languagesStore,
    photoModelsStore,
    categoriesStore,
    compositionsStore,
    compositionGroupsStore,
    collectionsStore,
    measurementsStore,
    fitsStore,
    marketplacesStore,
    sizesStore,
    colorsStore,
    colorGroupsStore,
    sitesStore,
    imagesStore,
    manufactureCategoriesStore,
    manufactureGroupsStore,
    manufactureTypesStore,
    capsulesStore,
    manufactureCaresStore,
    brandsStore,
    productSalePlatformsStore,
    userStore,
    permissionStore,
    roleStore,
  },
})

setupInterceptors(store)
