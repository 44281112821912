<template>
  <CategoriesForm
    :initial-form-data="{ ...formData }"
    :handle-save="save"
    :message="message"
    :is-creating="true"
    :model-value="modelValue"
    :title="title"
    :categories="categories"
    :parent-id="parentId"
    :size-charts="sizeCharts"
    @close="$emit('update:modelValue', false)" />
</template>

<script setup>
  import { watch } from 'vue'
  import CategoriesForm from '@/components/category/CategoriesForm'
  import { ref } from 'vue'
  import { useStore } from 'vuex'

  const emit = defineEmits(['create'])

  defineProps({
    modelValue: Boolean,
    title: String,
    categories: {
      type: Array,
      required: false,
    },
    sizeCharts: {
      type: Array,
      required: false,
    },
    parentId: {
      type: Number,
      default: 0,
    },
    site_name: {
      type: Object,
      required: false,
    },
  })

  const store = useStore()
  // let currentBrand = computed(() => store.getters['main/currentBrand'])

  const formData = ref({
    name: '',
    transport_attrs: {
      width: '',
    },
    site_name: {
      ru: '',
      en: '',
    },
    site_slug: {
      ru: '',
      en: '',
    },
    site_seo_description: {
      ru: '',
      en: '',
    },
    site_seo_keywords: {
      ru: '',
      en: '',
    },
    is_marking_required: false,
    marking_product_group: null,
    visible_in_shop: false,
    size_chart: null,
  })

  const brand = store.getters['main/currentBrand']
  if (brand) {
    formData.value.brand_id = brand.id
  }

  watch(
    () => store.getters['main/currentBrand'],
    function (brand) {
      formData.value.brand_id = brand.id
    },
  )

  const message = ref('')

  const save = async (newObject) => {
    emit('create', newObject)
  }
</script>

<style scoped></style>
