export interface IRole {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  permissions: IPermission[];
}

export const defaultRole: IRole = {
  id: 0,
  name: '',
  created_at: '',
  updated_at: '',
  permissions: [],
}

export interface IPermission {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface IEditRole {
  id?: number;
  name: string;
}

export const defaultCreateRole: IEditRole = {
  name: '',
}

export interface IAttachPermissionToRole {
  permissions: number[]|string[];
}

export const defaultAttachPermission: IAttachPermissionToRole = {
  permissions: [],
}

export interface IAttachRoleToUser {
  roles: number[];
}

export const defaultAttachRole: IAttachRoleToUser = {
  roles: [],
}

