import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const measurementsStore = {
  namespaced: true,
  state: () => ({
    measurements: [],
  }),

  getters: {
    measurements(state) {
      return state.measurements
    },
  },

  mutations: {
    async fetchMeasurements(state) {
      await request.get('measurements').then((res)=>{
        state.measurements = res.data.measurements
      })
    },
  },

  actions: {
    async fetchMeasurements(context, id) {
      await context.commit('fetchMeasurements', id)
    },
  },
}