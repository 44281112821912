<script setup>
  import { onMounted, reactive, ref, watch, computed } from 'vue'
  import axios from 'axios'
  import { ElNotification } from 'element-plus'

  const props = defineProps({
    isCreating: Boolean,
    initialFormData: Object,
    handleSave: Function,
    message: String,
    modelValue: Boolean,
    title: String,
  })

  const form = ref({})
  const ruleFormRef = ref()

  const rules = reactive({
    name: [
      { required: true, message: 'Пожалуйста, заполните поле' },
    ],
    size_group_id: [
      { required: true, message: 'Пожалуйста, заполните поле' },
    ],
  })

  watch(props, (props) => {
    form.value = { ...props.initialFormData }
  })

  onMounted(() => {
    form.value = { ...props.initialFormData }
  })

  const save = async (formEl) => {
    await formEl.validate((valid) => {
      if (valid) {
        props.handleSave(form.value)
      }
    })
  }

  const emit = defineEmits(['close'])

  const handleClose = () => {
    emit('close')
  }

  const isSizeGroupsSelectLoading = ref(false)
  const sizeGroups = ref([])
  const fetchSizeGroups = async () => {
    isSizeGroupsSelectLoading.value = true

    try {
      await axios.get('/size-groups')
        .then((response) => {
          sizeGroups.value = response.data
        })
    } catch (e) {
      ElNotification({
        title: 'Ошибка',
        message: 'Не удалось загрузить данные',
        type: 'error',
      })
      throw new Error(e.response.data.message)
    } finally {
      isSizeGroupsSelectLoading.value = false
    }
  }
  fetchSizeGroups()

  const groupOptions = computed(() => {
    return sizeGroups.value.map((sizeGroup) => {
      return {
        value: sizeGroup.id,
        label: sizeGroup.name,
      }
    })
  })

  const isSizesSelectLoading = ref(false)
  const sizes = ref([])
  const fetchSizes = async () => {
    isSizesSelectLoading.value = true

    try {
      await axios.get('/sizes')
        .then((response) => {
          sizes.value = response.data?.sizes
        })
    } catch (e) {
      ElNotification({
        title: 'Ошибка',
        message: 'Не удалось загрузить данные',
        type: 'error',
      })
      throw new Error(e.response.data.message)
    } finally {
      isSizesSelectLoading.value = false
    }
  }
  fetchSizes()


  const sizesOptions = computed(() => {
    return sizes.value.map((size) => {
      return {
        value: size.id,
        label: size.name,
      }
    })
  })
</script>

<template>
  <el-dialog
    :model-value="modelValue"
    width="800px"
    :title="title"
    class="custom-dialog"
    :before-close="handleClose">
    <slot />

    <el-row>
      <el-col :span="20">
        <el-form
          ref="ruleFormRef"
          :model="form"
          label-width="200px"
          :rules="rules">
          <el-form-item
            label="Название"
            prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item
            label="Группа"
            prop="size_group_id">
            <el-select
              v-model="form.size_group_id"
              :loading="isSizeGroupsSelectLoading"
              placeholder="Группа">
              <el-option
                v-for="item in groupOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Композитный размер?"
            prop="is_composite">
            <el-checkbox
              v-model="form.is_composite" />
          </el-form-item>
          <el-form-item
            label="Композитные размеры"
            prop="size_composites">
            <el-select
              v-model="form.size_composites"
              :loading="isSizeGroupsSelectLoading"
              placeholder="Размеры"
              multiple>
              <el-option
                v-for="item in sizesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div v-if="message">
      {{ message }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="save(ruleFormRef)">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>

</style>
