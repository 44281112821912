<script setup>
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import { ElNotification } from 'element-plus'
  import axios from 'axios'
  import { useRoute } from 'vue-router'

  const route = useRoute()
  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  store.dispatch('manufactureCaresStore/fetchManufactureCares')

  const selectedCare = computed({
    get() {
      return store.getters['productStore/manufactureCares']
    },
    set(careCollection) {
      store.commit('productStore/updateManufactureCares', careCollection)
    },
  })

  const careCollection = computed({
    get() {
      const selectedIds = selectedCare.value.map((care) => care.id)
      const careCollection = store.getters['manufactureCaresStore/manufactureCares']
      return careCollection.map((care) => {
        care.disabled = selectedIds.includes(care.id)
        return care
      })
    },
  })

  const careDialog = ref(false)

  function closeCareDialog() {
    careDialog.value = false
  }

  async function saveCares() {
    const saveCareCollection = []

    selectedCare.value.forEach((item) => {
      saveCareCollection.push({
        care_id: item.id,
      })
    })

    try {
      await axios.put(`products/${route.params.id}/attach/manufacture/cares`, {
        cares: saveCareCollection,
      })

      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Данные о уходе за товаром обновлены',
      })
      careDialog.value = false
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: error.response.data.message,
      })
    }
    await store.dispatch('productStore/fetchProduct', { id: route.params.id })
  }

  async function addCare() {
    selectedCare.value.push({
      id: null,
      name: null,
    })
  }

  async function detachCare(index) {
    selectedCare.value.splice(index, 1)
  }
</script>

<template>
  <el-row align="middle">
    <h4>Уход:</h4>
    <el-tag
      v-for="care in selectedCare"
      :key="care.id"
      class="mr-1"
      size="large"
      type="info">
      {{ care.name }}
    </el-tag>
    <el-button
      :disabled="!canEdit"
      class="button-new-tag ml-1"
      @click="careDialog = true">
      + Редактировать уход
    </el-button>

    <el-dialog
      v-model="careDialog"
      title="Изменить уход"
      @close="closeCareDialog">
      <div class="dialog_body">
        <el-form
          ref="form"
          :model="selectedCare"
          label-width="20px">
          <el-table
            :data="selectedCare"
            stripe>
            <el-table-column label="Виды ухода">
              <template #default="scope">
                <el-form-item
                  v-if="scope && scope.$index >= 0"
                  label=" "
                  :prop="'selectedCare.' + scope.$index + '.id'">
                  <el-select
                    v-model="selectedCare[scope.$index].id"
                    placeholder="Выберите вид ухода">
                    <el-option
                      v-for="care in careCollection"
                      :key="care.id"
                      :label="care.name"
                      :value="care.id"
                      :disabled="care.disabled" />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column label="Действие">
              <template #default="scope">
                <el-form-item
                  v-if="scope && scope.$index >= 0"
                  label=" ">
                  <el-button
                    type="danger"
                    size="small"
                    icon="Delete"
                    circle
                    @click="detachCare(scope.$index)" />
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
          <el-form-item class="mt-2">
            <el-button
              type="primary"
              plain
              icon="Plus"
              @click="addCare()">
              Добавить вид ухода
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
        <el-button
          type="primary"
          @click="saveCares()">
          Сохранить
        </el-button>
      </template>
    </el-dialog>
  </el-row>
</template>
<style lang="scss" scoped>
h4 {
  margin-right: 10px;
  width: 200px;
}
</style>
