<template>
  <el-dialog
    :model-value="modelValue"
    width="20%"
    :title="title"
    class="custom-dialog"
    :before-close="handleClose">
    <slot />

    <el-form
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
      label-position="top">
      <el-form-item
        label="Номер телефона"
        prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="+79999999999" />
      </el-form-item>
      <el-form-item
        label="Номинал"
        prop="amount">
        <el-input
          v-model="form.amount"
          placeholder="500" />
      </el-form-item>
      <el-form-item
        label="Дата окончания действия"
        prop="until_at">
        <el-date-picker
          v-model="form.until_at"
          type="date"
          format="DD.MM.YYYY" />
      </el-form-item>
      <el-form-item
        label="Номер сертификата"
        prop="number">
        <el-input
          v-model="form.number"
          placeholder="1QZOJFZK2VQC" />
      </el-form-item>
      <el-form-item
        label="Был оплачен?"
        prop="is_paid">
        <el-checkbox
          v-model="form.is_paid" />
      </el-form-item>
    </el-form>

    <div v-if="message">
      {{ message }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="save(ruleFormRef)">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue'

  const props = defineProps({
    isCreating: Boolean,
    initialFormData: Object,
    handleSave: Function,
    message: String,
    modelValue: Boolean,
    title: String,
  })

  const form = ref({
    phone: null,
    amount: null,
    until_at: null,
    number: null,
  })

  const ruleFormRef = ref()

  const rules = reactive({
    phone: [
      { required: true, message: 'Пожалуйста, заполните поле' },
    ],
    amount: [
      { required: true, message: 'Пожалуйста, заполните поле' },
    ],
    until_at: [
      { required: true, message: 'Пожалуйста, заполните поле' },
    ],
  })

  watch(props, (props) => {
    if (form.value == null) {
      form.value = { ...props.initialFormData }
    }
  })

  onMounted(() => {
    form.value = { ...props.initialFormData }
  })

  const save = async (formEl) => {
    await formEl.validate((valid) => {
      if (valid) {
        props.handleSave(form.value)
      }
    })
  }

  const emit = defineEmits(['close'])

  const handleClose = () => {
    emit('close')
  }

</script>

<style scoped>
.color-value {
  margin-left: 6px;
}
</style>
