<template>
  <el-dialog
    :model-value="modelValue"
    width="800px"
    :title="title"
    class="custom-dialog"
    :before-close="handleClose">
    <slot />

    <el-row>
      <el-col :span="20">
        <el-form
          ref="ruleFormRef"
          :model="form"
          label-width="220px"
          :rules="rules">
          <el-form-item
            label="Сезон"
            prop="name">
            <el-select
              v-model="form.type"
              placeholder="Выберите сезон">
              <el-option
                v-for="collection in collections"
                :key="collection.id"
                :label="collection.type"
                :value="collection.value" />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Год"
            prop="year">
            <el-input v-model="form.year" />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div v-if="message">
      {{ message }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="save(ruleFormRef)">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
  import { onMounted, reactive, ref, watch, computed } from 'vue'

  const props = defineProps({
    isCreating: Boolean,
    initialFormData: Object,
    handleSave: Function,
    message: String,
    modelValue: Boolean,
    title: String,
  })

  const form = ref({})
  const ruleFormRef = ref()

  const collections = computed({
    get() {
      return [
        { id: 1, type: 'SS', value: 'SS' },
        { id: 2, type: 'FW', value: 'FW' },
      ]
    },
  })

  const rules = reactive({
    type: [{ required: true, message: 'Пожалуйста, заполните поле' }],
    year: [{ required: true, message: 'Пожалуйста, заполните поле' }],
  })

  watch(props, (props) => {
    form.value = JSON.parse(JSON.stringify(props.initialFormData))
  })

  onMounted(() => {
    form.value = JSON.parse(JSON.stringify(props.initialFormData))
  })

  const save = async (formEl) => {
    await formEl.validate((valid) => {
      if (valid) {
        props.handleSave(form.value)
      }
    })
  }

  const emit = defineEmits(['close'])

  const handleClose = () => {
    emit('close')
  }
</script>

<style scoped></style>
