<script setup>
  import axios from 'axios'
  import { computed, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { ElNotification } from 'element-plus'

  const store = useStore()
  const route = useRoute()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const marketplaces = computed(() => store.getters['marketplacesStore/marketplaces'])
  const barcodes = computed(() => store.getters['productStore/barcodes'])
  const selectedColor = computed(() => store.getters['productStore/selectedColor'])
  const productSizes = computed(() => store.getters['productStore/sizes'])

  const selectedMarketplaceId = ref(null)
  const marketplacesForm = ref(null)

  const marketplacesBarcodes = computed({
    get() {
      if (selectedMarketplaceId.value == null) {
        return []
      }

      const marketplaceBarcodes = {}

      productSizes.value.forEach((productSize) => {
        const productSizeId = productSize.id

        const barcodeData = barcodes.value.find((item) => {
          if (
            item.marketplace_id === selectedMarketplaceId.value &&
            item.color_id === selectedColor.value &&
            item.size_id === productSizeId
          ) {
            return item
          }
        })

        if (barcodeData !== undefined) {
          marketplaceBarcodes[productSize.name] = barcodeData
        } else {
          marketplaceBarcodes[productSize.name] = {
            barcode: null,
            color_id: selectedColor.value,
            marketplace_id: selectedMarketplaceId.value,
            size_id: productSize.id,
          }
        }
      })

      return [marketplaceBarcodes]
    },
  })

  /** При загрузке компонента выбирать первый размер продукта */

  watch(
    () => marketplaces.value,
    (marketplace) => {
      if (marketplace && selectedMarketplaceId.value == null) {
        selectedMarketplaceId.value = marketplace[0]?.id
      }
    },
  )

  async function generateMarketplaceBarcodes() {
    const res = await axios
      .put('/barcodes/generate/' + route.params.id)
      .then((res) => {

        return res
      })
      .catch((err) => {
        ElNotification({
          title: 'Error',
          message: err.response.data.message,
          type: 'error',
        })
        throw new Error(err.response.data.message)
      })
    if (res.status === 200) {
      store.dispatch('productStore/fetchProduct', { id: route.params.id, color_id: selectedColor.value })
      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Штрих-коды успешно сгенерированы',
      })
    }
  }

  function saveMarketplaceBarcode(data) {
    axios
      .put(`/products/${route.params.id}/attach/marketplace-barcode`, data)
      .then((res) => {
        if (res.status === 200) {
          ElNotification({
            title: 'Успех',
            type: 'success',
            message: 'Штрих-коды успешно сгенерированы',
          })
        }
      })
      .catch((err) => {
        ElNotification({
          title: 'Error',
          message: err.response.data.message,
          type: 'error',
        })
        throw new Error(err.response.data.message)
      })
  }
</script>

<template>
  <el-row>
    <h3>Штрих-коды маркеплейсов</h3>
  </el-row>

  <el-tabs
    v-if="marketplaces.length"
    v-model="selectedMarketplaceId"
    type="border-card">
    <el-tab-pane
      v-for="marketplace in marketplaces"
      :key="marketplace.name"
      :label="marketplace.name"
      :name="marketplace.id">
      <form ref="marketplacesForm">
        <el-table :data="marketplacesBarcodes">
          <el-table-column
            v-for="size in store.getters['productStore/sizes']"
            :key="size.id"
            :label="size.name"
            :prop="'sizes.' + size.name">
            <template #default="scope">
              <el-input
                v-model="scope.row[size.name]['barcode']"
                :disabled="!canEdit"
                type="text"
                :min="1"
                required
                :prop="scope.row[size.name]['barcode']"
                onwheel="this.blur()"
                @change="saveMarketplaceBarcode(scope.row[size.name])" />
            </template>
          </el-table-column>
        </el-table>
      </form>
      <el-button
        :disabled="!canEdit"
        class="mt-4 mb-2 mt-2"
        type="primary"
        plain
        style="width: 100%"
        @click="generateMarketplaceBarcodes">
        Сгенерировать штрих-коды для площадок
      </el-button>
    </el-tab-pane>
  </el-tabs>
</template>

<style lang="scss" scoped>
.el-input {
  width: 90%;
}
</style>
