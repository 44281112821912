<script setup>
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  import StrHelper from '@/helpers/StrHelper'
  import axios from 'axios'
  import { ElNotification } from 'element-plus'

  const SH = new StrHelper()
  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const content = computed(() => {
    return store.getters['productStore/content'] ?? null
  })

  const model101 = [
    {
      title: 'Название товара',
      key: 'title',
      strong: true,
    },
    {
      title: 'SEO Название товара',
      key: 'seo_title',
    },
    {
      title: 'SEO Описание товара',
      key: 'seo_description',
      type: 'textarea',
    },
    {
      title: 'SEO Ключевые слова',
      key: 'seo_keywords',
    },
    {
      title: 'Ссылка',
      key: 'slug',
      generated: true,
      genField: 'title',
      can_be_disabled: true,
    },
  ]

  const model102 = [
    {
      title: 'Описание',
      key: 'description',
    },
  ]

  /**
   * Берёт из цвета параметр
   * @param {number} color_id
   * @param {string} key
   * @param {string} lang
   */
  function takeFromColor(color_id, key, lang) {
    const source = store.getters['productStore/product'].contents.find(
      (item) => item.color_id === color_id && item.platform_id === null,
    )
    content.value[key][lang] = source[key][lang]
  }

  /**
   * Берёт весь контент из цвета
   * @param {number} color_id
   */
  function takeAllFromColor(color_id) {
    const source = store.getters['productStore/product'].contents.find(
      (item) => item.color_id === color_id && item.platform_id === null,
    )
    content.value['title'] = source['title']
    content.value['description'] = source['description']
    content.value['seo_title'] = source['seo_title']
    content.value['seo_description'] = source['seo_description']
    content.value['seo_keywords'] = source['seo_keywords']
    content.value['slug'] = source['slug']
    content.value['care'] = source['care']
  }

  /**
   * Создаёт slug, записывает и сохранаяет
   * @param {string|number} lang
   */
  function createSlug(lang) {
    const currentColor = store.getters['colorsStore/colors'].find(
      (color) => color.id === store.getters['productStore/selectedColor'],
    )
    content.value.slug[lang] = SH.slug(
      store.getters['productStore/product'].article_number + '-' + currentColor.slug[lang],
    )
  }

  /**
   * Сохраняет открытый контент
   * */
  async function saveAll() {
    await axios
      .put(
        `products/${store.getters['productStore/product'].id}/content`,
        serialize(content.value),
      )
      .then(() => {
        ElNotification({
          title: 'Успех',
          type: 'success',
          message: 'Свойства успешно обновлены',
        })
      })
      .catch(() => {
        ElNotification({
          title: 'Ошибка',
          type: 'error',
          message: 'Свойства не обновлены',
        })
      })
  }

  /**
   * Сериализует все `object` внутри источника
   * @param {object} source
   * */
  function serialize(source) {
    const result = {}
    for (const key in source) {
      if (typeof source[key] !== 'object' || source[key] === null) {
        result[key] = source[key]
      } else {
        result[key] = JSON.stringify(source[key])
      }
    }
    return result
  }
  
  const sale_platforms = computed(() => {
    if (store.getters['productStore/salePlatforms']) {
      return store.getters['productStore/salePlatforms'].filter(
        (el) =>
          el.color_id === store.getters['productStore/selectedColor'] &&
          el.platform_type === 'App\\Models\\Site',
      )
    } else {
      return null
    }
  })

  async function saveActivity(platform) {
    try {
      const res = await axios.put(
        `/products/${store.getters['productStore/product'].id}/sale-platforms/active`,
        platform,
      )
      ElNotification({
        title: 'Сохранено',
        type: 'success',
        message: 'Активность цвета изменена',
      })
    } catch (exception) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: `${exception.response.data.message}`,
      })
      platform.active = false
    }
  }

  async function savePreorder(platform) {
    await axios.put(
      `/products/${store.getters['productStore/product'].id}/sale-platforms/preorder`,
      platform,
    )
  }

  const isSlugDisabled = () => {
    const product = store.getters['productStore/product']
    return product.is_published
  }
</script>

<template>
  <div>
    <div
      v-if="sale_platforms"
      class="sale_platforms">
      <div class="active">
        <el-row :gutter="10">
          <el-col :span="3">
            <strong>Активность цвета на площадке</strong>
          </el-col>
          <el-col
            v-for="platform in sale_platforms"
            :key="platform.id"
            :span="2">
            <span
              v-if="store.getters['sitesStore/sites'].length > 0"
              class="site_title">{{
                store.getters["sitesStore/sites"].find(
                  (s) => s.id === platform.platform_id
                ).short_name
              }}</span>
            <el-switch
              v-model="platform.active"
              :disabled="!canEdit"
              @change="saveActivity(platform)" />
          </el-col>
        </el-row>
      </div>
      <div class="preorder">
        <el-row :gutter="10">
          <el-col :span="3">
            <strong>Предзаказ</strong>
          </el-col>
          <el-col
            v-for="platform in sale_platforms"
            :key="platform.id"
            :span="2">
            <span
              v-if="store.getters['sitesStore/sites'].length > 0"
              class="site_title">{{
                store.getters["sitesStore/sites"].find(
                  (s) => s.id === platform.platform_id
                ).short_name
              }}</span>
            <el-switch
              v-model="platform.preorder"
              :disabled="!canEdit"
              @change="savePreorder(platform)" />
          </el-col>
        </el-row>
      </div>
    </div>
    <el-divider />

    <el-row>
      <el-col :span="18">
        <h3>Описание в карточке товара для клиента</h3>
      </el-col>
    </el-row>
    <div
      v-for="lang in store.getters['languagesStore/languages']"
      :key="lang.code"
      class="lang">
      <el-row
        v-for="model in model102"
        :key="model.key"
        justify="space-between">
        <el-col
          class="col_title"
          :class="{ strong: model.strong }"
          :span="4">
          {{ model.title }} {{ lang.code }}
        </el-col>
        <el-col :span="13">
          <el-input
            v-model="content[model.key][lang.code]"
            :disabled="!canEdit"
            type="textarea"
            rows="6" />
        </el-col>
        <el-col :span="6">
          <el-select
            v-if="!model.generated"
            :disabled="!canEdit"
            placeholder="Взять из цвета">
            <el-option
              v-for="color in store.getters['productStore/colors']"
              :key="color.value"
              :label="color.name.ru"
              :value="color.value"
              @click="takeFromColor(color.id, model.key, lang.code)" />
          </el-select>
          <el-button
            v-else
            :disabled="!canEdit"
            type="primary"
            plain
            @click="createSlug(lang.code)">
            Сгенерировать
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-divider />
    <el-row>
      <el-col :span="18">
        <h3>Мета данные в карточке товара для клиента</h3>
      </el-col>
      <el-col :span="6">
        <el-select
          :disabled="!canEdit"
          class="takeAll"
          placeholder="Взять всё из цвета">
          <el-option
            v-for="color in store.getters['productStore/colors']"
            :key="color.value"
            :label="color.name.ru"
            :value="color.value"
            @click="takeAllFromColor(color.id)" />
        </el-select>
      </el-col>
    </el-row>
    <div
      v-for="lang in store.getters['languagesStore/languages']"
      :key="lang.code"
      class="lang">
      <el-row
        v-for="model in model101"
        :key="model.key"
        justify="space-between">
        <el-col
          class="col_title"
          :class="{ strong: model.strong }"
          :span="4">
          {{ model.title }} {{ lang.code }}
        </el-col>
        <el-col :span="13">
          <el-input
            v-model="content[model.key][lang.code]"
            placeholder=""
            :disabled="(model.can_be_disabled && isSlugDisabled()) || !canEdit" />
        </el-col>
        <el-col :span="6">
          <el-select
            v-if="!model.generated"
            :disabled="!canEdit"
            placeholder="Взять из цвета">
            <el-option
              v-for="color in store.getters['productStore/colors']"
              :key="color.value"
              :label="color.name.ru"
              :value="color.value"
              @click="takeFromColor(color.id, model.key, lang.code)" />
          </el-select>
          <el-button
            v-else
            type="primary"
            plain
            :disabled="(model.can_be_disabled && isSlugDisabled()) || !canEdit"
            @click="createSlug(lang.code)">
            Сгенерировать
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="save_button">
      <el-button
        :disabled="!canEdit"
        type="primary"
        class="save"
        @click="saveAll">
        Сохранить описания
      </el-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sale_platforms {
  .site_title {
    margin-right: 10px;
  }
}

.el-row {
  display: flex;
  align-items: center;
}

.col_title {
  display: flex;
  justify-content: flex-end;

  &.strong {
    font-weight: 600;
  }
}

.lang {
  margin-bottom: 50px;
}

.el-button {
  &.save {
    width: 100%;
    //height: 50px;
    font-weight: 600;
  }
}

.save_button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: -20px;
  background: white;
  height: 50px;
}
</style>
