<template>
  <ConfirmModal
    v-model="deleteDialogVisible"
    :confirm-function="handleConfirmedDelete"
    text="Вы уверены, что хотите удалить категорию?" />

  <CategoriesCreate
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Категория на сайте"
    :categories="categoriesForSelect"
    :parent-id="newParentId"
    :size-charts="sizeCharts"
    @create="create" />
  <CategoriesEdit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    :categories="categoriesForSelect"
    :size-charts="sizeCharts"
    title="Категория на сайте"
    @updateObject="updateObject" />

  <header>
    <el-row
      justify="space-between">
      <el-col :span="8">
        <h1>Список категорий товаров на сайте</h1>
      </el-col>
      <el-col
        :span="6"
        justify="right">
        <el-row justify="end">
          <el-button
            v-if="changedPositions.length"
            :disabled="!canEditCategories"
            type="success"
            :loading="positionsSaveLoading"
            @click="saveChangedPositions">
            Сохранить позиции
          </el-button>

          <el-button
            :disabled="!canEditCategories"
            type="success"
            @click="startCreatingRootCategory">
            Создать
          </el-button>
        </el-row>
      </el-col>
    </el-row>
  </header>

  <el-tree
    v-if="!loadingData"
    :data="mainObjects"

    draggable
    default-expand-all
    node-key="id"
    :expand-on-click-node="false"
    :props="{ class: 'tree-node-base-class' }"
    @node-drop="handleDrop">
    <template #default="{ node, data }">
      <div class="catalog__categories-tree__actions">
        <div
          v-if="canEditCategories"
          class="tree-node__button"
          title="Изменить категорию"
          @click="handleEdit(node, data)">
          <Edit
            style="width: 0.9em; height: 0.9em" />
        </div>
        <div
          v-if="canEditCategories"
          class="tree-node__button"
          title="Добавить подкатегорию"
          @click="startCreatingSubcategory(node, data)">
          <Plus
            style="width: 0.9em; height: 0.9em" />
        </div>
      </div>
      <div class="dd-handle">
        <span>{{ node.label }}</span>
      </div>
    </template>
  </el-tree>
</template>

<script>
  import ConfirmModal from '@/components/shared/ConfirmModal'
  import { computed, ref, watch } from 'vue'
  import useTable from '@/components/shared/table/useTable'
  import CategoriesCreate from '@/components/category/CategoriesCreate'
  import CategoriesEdit from '@/components/category/CategoriesEdit'

  import {
    Delete,
    Edit,
    Plus,
  } from '@element-plus/icons-vue'
  import { ElNotification } from 'element-plus'
  import { useStore } from 'vuex'
  import axios from 'axios'

  export default {
    name: 'CategoriesPage',
    components: { Edit, Plus, ConfirmModal, CategoriesCreate, CategoriesEdit },
    setup() {
      const modelName = ref('category')
      const modelNamePlural = ref('categories')
      const newParentId = ref(0)
      let deletingNode = null

      const store = useStore()

      let extraData
      if (!store.getters['main/currentBrand']) {
        extraData = {
          loadOnMounted: false,
        }
      } else {
        extraData = {
          extraQuery: 'brand_id=' + store.getters['main/currentBrand'].id,
        }
      }

      extraData.checkBrand = true

      const tableHelper = useTable(modelName, modelNamePlural, extraData)

      const handleDelete = (node, data) => {
        deletingNode = node
        tableHelper.deleteDialogVisible.value = true
        tableHelper.deletingId.value = data.id
      }

      const handleConfirmedDelete = async () => {
        tableHelper.deleteDialogVisible.value = false

        try {
          const url =
            process.env.VUE_APP_CATALOG_API_URL +
            '/api/v1/' +
            modelNamePlural.value +
            '/' +
            tableHelper.deletingId.value
          await axios.delete(url)
          ElNotification({
            title: 'Успешно',
            message: 'Категория успешно удалена',
            type: 'success',
          })
          const parent = deletingNode.parent
          const children = parent.data.children || parent.data
          const index = children.findIndex((d) => d.id === tableHelper.deletingId.value)
          children.splice(index, 1)
          tableHelper.mainObjects.value = [...tableHelper.mainObjects.value]
        } catch (exception) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось удалить',
            type: 'error',
          })
        }
      }

      const handleEdit = (node, data) => {
        tableHelper.editeDialogVisible.value = true
        tableHelper.selectedIndex.value = node
        tableHelper.editingObject.value = { ...data }
      }

      const updateObject = async (node, newObject) => {
        newObject = formatData(newObject)

        const url =
          process.env.VUE_APP_CATALOG_API_URL +
          '/api/v1/' +
          modelNamePlural.value +
          '/' +
          newObject.id
        try {
          await axios.patch(url, newObject)
          ElNotification({
            title: 'Успешно',
            message: 'Категория успешно обновлена',
            type: 'success',
          })
          tableHelper.editeDialogVisible.value = false
          node.data = newObject
          node.data.label = newObject.id + ': ' + newObject.name
        } catch (exception) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось обновить',
            type: 'error',
          })
        }
      }

      const formatData = (newObject) => {
        if (newObject.parent_id === 0) {
          newObject.parent_id = null
        }

        return newObject
      }

      const create = async (newObject, settings = {}) => {
        newObject = formatData(newObject)
        const extraQueryParams = 'extraQuery' in settings ? '?' + settings.extraQuery : ''

        try {
          const url =
            process.env.VUE_APP_CATALOG_API_URL +
            '/api/v1/' +
            modelNamePlural.value +
            '/' +
            extraQueryParams
          await axios.post(url, newObject)
          ElNotification({
            title: 'Успешно',
            message: 'Категория успешно создана',
            type: 'success',
          })
          tableHelper.createDialogVisible.value = false
          tableHelper.loadingData = true

          const extraData = {
            extraQuery: 'brand_id=' + store.getters['main/currentBrand'].id,
          }
          tableHelper.loadData(1, extraData)
        } catch (exception) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось сохранить категорию',
            type: 'error',
          })
        }
      }

      const handleDrop = async (draggingNode, dropNode, dropType) => {
        let position, parent_id
        if (dropType === 'inner') {
          position = 0
          parent_id = dropNode.data.id
        } else {
          const parent = dropNode.parent
          const children = parent.data.children || parent.data
          position = children.findIndex((d) => d.id === dropNode.data.id)
          parent_id = parent.data.id

          if (dropType === 'before') {
            position--
          } else {
            position++
          }
        }

        changedPositions.value.push({ category_id: draggingNode.data.id, parent_id, position })
      }

      const startCreatingSubcategory = (node, data) => {
        tableHelper.createDialogVisible.value = true
        newParentId.value = data.id
      }

      const startCreatingRootCategory = () => {
        tableHelper.createDialogVisible.value = true
        newParentId.value = 0
      }

      const positionsSaveLoading = ref(false)
      const saveChangedPositions = async () => {

        positionsSaveLoading.value = true
        try {

          tableHelper.loadingData = true
          let url = ''


          for await (const data of changedPositions.value) {
            url = process.env.VUE_APP_CATALOG_API_URL +
              '/api/v1/categories/' +
              data.category_id +
              '/reorder'

            await axios.put(url, { parent_id: data.parent_id, position: data.position })
          }


          positionsSaveLoading.value = false
          changedPositions.value = []
          ElNotification({
            title: 'Успешно',
            message: 'Позиции сохранены',
            type: 'success',
          })
        } catch {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось изменить позицию',
            type: 'error',
          })
        }
      }

      const categoriesForSelect = computed(() => {
        const firstValue = { label: 'Нет', value: 0, id: 0 }
        return [firstValue, ...tableHelper.mainObjects.value]
      })

      watch(
        () => store.getters['main/currentBrand'],
        function (brand) {
          const extraData = {
            extraQuery: 'brand_id=' + brand.id,
          }
          tableHelper.loadData(1, extraData)
        },
      )

      const count = ref(1)
      const increaseCount = (n) => {
        count.value += n
      }

      const changedPositions = ref([])

      const canEditCategories = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit categories').length >= 1)

      const sizeCharts = ref([])

      const fetchSizeCharts = async () => {
        try {
          await axios.get('/categories/size-charts')
            .then((response) => {
              console.log(response.data)
              sizeCharts.value = response.data
            })
        } catch (e) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось загрузить данные',
            type: 'error',
          })
          throw new Error(e.response.data.message)
        }
      }

      fetchSizeCharts()

      return {
        ...tableHelper,
        increaseCount,
        count,
        startCreatingRootCategory,
        categoriesForSelect,
        newParentId,
        create,
        handleDelete,
        handleEdit,
        updateObject,
        handleDrop,
        handleConfirmedDelete,
        Delete,
        Edit,
        modelNamePlural,
        startCreatingSubcategory,
        saveChangedPositions,
        changedPositions,
        tableHelper,
        positionsSaveLoading,
        canEditCategories,
        sizeCharts,
      }
    },
  }
</script>

<style>
.dd-handle {
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #333;
  display: block;
  font-weight: 700;
  padding: 4px 10px;
  text-decoration: none;
}

.tree-node-base-class {
  padding-top: 11px;
}

.tree-node__button {
  display: inline-block;
  padding: 4px 7px;
  border: 1px solid #ccc;
  border-collapse: collapse;
}
</style>
