import { IRole } from '@/types/roleAndPermission.types'
import { Ref, ref } from 'vue'

export const expandRows = () => {
  const expandRowKeys: Ref<number[]> = ref<number[]>([])
  const handleExpandChange = (row: IRole): void => {
    const id: number = row.id
    const lastId: number = expandRowKeys.value[0]
    // disable multiple row expanded
    expandRowKeys.value = id === lastId ? [] : [id]
  }

  const onCellClick = (row, column): void => {
    if (column.type === 'expand') {
      handleExpandChange(row)
    }
  }

  return {
    onCellClick,
    handleExpandChange,
  }
}