<script setup>
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { ElNotification } from 'element-plus'
  import axios from 'axios'

  const store = useStore()
  const route = useRoute()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const selectedCapsule = computed({
    get() {
      return store.getters['productStore/capsule']?.id
    },
    set(capsule) {
      store.commit('productStore/updateCapsule', capsule)
    },
  })

  async function save(capsule) {
    axios
      .put(`/products/${route.params.id}/attach/capsule`, {
        capsule_id: capsule,
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        ElNotification({
          title: 'Error',
          message: err.response.data.message,
          type: 'error',
        })
        throw new Error(err.response.data.message)
      })
  }

  const props = {
    label: 'name',
  }
</script>

<template>
  <div>
    <strong>Капсула: </strong>
    <el-select
      v-model="selectedCapsule"
      :disabled="!canEdit"
      placeholder="Выберите капсулу"
      default-expand-all
      :props="props"
      check-strictly
      @change="save($event)">
      <el-option
        v-for="capsule in store.getters['capsulesStore/capsules']"
        :key="capsule.id"
        :value="capsule.id"
        :label="capsule.name">
        {{ capsule.name }}
      </el-option>
    </el-select>
  </div>
</template>

<style scoped>
.el-switch {
  margin-left: 20px;
}
</style>
