<script setup>
  import { ref } from 'vue'
  import ProductCollections from './ProductCollections'
  import EcomDescription from './EcomDescription'
  import MarketplaceDescriptions from './MarketplaceDescriptions'
  import { useStore } from 'vuex'
  import SizeSelector from '@/components/product/content/SizeSelector.vue'
  import MarketplaceBarcode from '@/components/product/content/MarketplaceBarcode.vue'


  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const activeTab = ref('ecom')

  function tabChange(name) {
    let platform = 'ecom'
    if (name !== 'ecom') {
      platform = store.getters['marketplacesStore/marketplaces'].find(
        (m) => m.short_name === name,
      )
    }
    store.commit('productStore/selectPlatform', platform)
  }

</script>

<template>
  <div class="main_content">
    <div class="content_data">
      <div class="measurements_108">
        <product-collections :can-edit="canEdit" />
      </div>
      <div class="mb-2">
        <size-selector :can-edit="canEdit" />
      </div>
      <div class="mb-2">
        <marketplace-barcode :can-edit="canEdit" />
      </div>

      <div class="platform_content">
        <el-tabs
          v-model="activeTab"
          type="border-card"
          class="demo-tabs"
          @tab-change="tabChange">
          <el-tab-pane
            label="E-Com"
            name="ecom">
            <ecom-description :can-edit="canEdit" />
          </el-tab-pane>
          <el-tab-pane
            v-for="marketplace in store.getters['marketplacesStore/marketplaces']"
            :key="marketplace.id"
            disabled
            :label="marketplace.name"
            :name="marketplace.short_name">
            <marketplace-descriptions :can-edit="canEdit" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main_content {
  display: flex;
  width: 100%;

  .aside_data {
    width: 300px;
    margin-right: 20px;
  }

  .content_data {
    //max-width: calc(100% - 280px);
    width: 100%;

    .measurements_108 {
    }

    .platform_content {
      .el-tabs {
      }
    }
  }
}

.el-row {
  margin-bottom: 20px;

  .el-col {
    h3 {
      margin-right: 10px;
    }
  }
}
</style>

<style lang="scss">
.row-column {
  display: flex;
  align-items: center;

  h3 {
    margin-right: 10px;
  }
}

.el-row {
  margin-bottom: 20px;

  .el-col {
    &.label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;

      &.strong {
        font-weight: 600;
      }
    }
  }
}
</style>
