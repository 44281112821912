<script setup>
  import { computed, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'
  import { reactive } from 'vue'
  import _ from 'lodash'
  import axios from 'axios'

  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const categories = computed({
    get() {
      return store.getters['categoriesStore/categories']
    },
  })

  const mainCategoryId = computed({
    get () {
      return store.getters['productStore/categories']?.main?.id
    },
    set (id) {
      store.commit('productStore/selectMainCategory', id)
    },
  })

  const selectedSubcategories = computed({
    get () {
      const filteredCategories = store.getters['productStore/categories']['sub'].filter(item => item.selected !== false)

      //Приходят категории для каждого цвета, оставляем только уникальные
      return _.uniq(filteredCategories.map((category) => category.id))
    },
    set (categoryIds) {
      store.commit('productStore/selectSubcategories', categoryIds)
    },
  })

  function selectionChange (data) {
    const selected = []
    data.forEach((item) => selected.push({ id: item }))
    selectedSubcategories.value = selected
  }

  async function save () {
    const toSend = []

    selectedSubcategories.value.forEach((category) => {
      toSend.push({
        id: category,
        main: false,
      })
    })

    toSend.push({
      id: mainCategoryId.value,
      main: true,
    })

    await axios.put(`products/${store.getters['productStore/product'].id}/sync/categories`, {
      categories: toSend,
    })
      .then((res) => {
        if (res.status === 200) {
          ElNotification({
            title: 'Успех',
            type: 'success',
            message: 'Данные о категориях товара обновлены',
          })
        } else {
          ElNotification({
            title: 'Ошибка',
            type: 'error',
            message: res.message,
          })
        }
      })
  }

  const ruleFormRef = ref()

  const ruleForm = reactive({
    mainCategoryId: mainCategoryId,
  })

  const rules = reactive({
    mainCategoryId: [{
      required: true, message: 'Выберите категорию', trigger: 'blur',
    }],
  })

  const submitForm = (formEl) => {
    if (!formEl) return
    formEl.validate((valid) => {
      if (valid) {
        save()
      } else {
        return false
      }
    })
  }

  const mainCategorySelect = ref()
  const mainCategoryPath = ref('')

  watch((mainCategoryId), (value) => {
    // почему то при иниализации currentNode не проставляется, только при клике
    if (!mainCategorySelect.value.getCurrentNode()) {
      setTimeout(() => {
        mainCategorySelect.value.setCurrentKey(value)
        mainCategoryPath.value = mainCategorySelect.value.getCurrentNode()?.path
      }, 500)
    } else {
      mainCategoryPath.value = mainCategorySelect.value.getCurrentNode()?.path
    }
  })
</script>

<template>
  <div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules">
      <el-row>
        <h3>Категории на сайте</h3>
      </el-row>
      <el-row style="gap:20px">
        <el-col :span="8">
          <el-row>
            <h3 style="margin-bottom: 10px">
              Основная категория:
            </h3>
            <el-form-item
              prop="mainCategoryId"
              class="form-input-block">
              <el-tree-select
                ref="mainCategorySelect"
                v-model="mainCategoryId"
                :disabled="!canEdit"
                placeholder="Выберите главную категорию"
                default-expand-all
                :props="{ path: 'path', label: 'name' }"
                check-strictly
                filterable
                :data="categories"
                node-key="id" />
            </el-form-item>
          </el-row>
          <el-row>
            <p>Полный путь товара: {{ mainCategoryPath }}</p>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <h3 style="margin-bottom: 10px">
              Также показывать в:
            </h3>
            <el-tree-select
              v-model="selectedSubcategories"
              placeholder="Выберите доп. категории"
              :disabled="!canEdit"
              node-key="id"
              :props="{ value: 'id', label: 'label', key: 'id' }"
              default-expand-all
              multiple
              check-strictly
              :data="categories"
              filterable
              @change="selectionChange($event)" />
          </el-row>
        </el-col>
        <el-col>
          <el-row>
            <el-button
              type="primary"
              plain
              :disabled="!canEdit"
              @click="submitForm(ruleFormRef)">
              Сохранить категорию
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>
.el-select {
  width: 100%;
}

.el-form-item {
  margin-bottom: 0px;
}

.form-input-block {
  width: 100%;
  display: block;
}
</style>
