import Tracker from '@openreplay/tracker'
import { useStore } from 'vuex'

export function startTracker (config: { projectKey: string }) {
  const store = useStore()

  const user = store.getters['userStore/getUser']

  const tracker = new Tracker({
    projectKey: config?.projectKey,
    ingestPoint: 'https://openreplay.tbb.team/ingest',
    defaultInputMode: 0,
    obscureTextNumbers: false,
    obscureTextEmails: false,
    __DISABLE_SECURE_MODE: process.env.NODE_ENV === 'development',
  })

  tracker.start({
    userID: `${user.id}`,
    metadata: {
      email: user.email,
      env: process.env.NODE_ENV === 'development' ? 'development' : 'production',
    },
  })

  return {
    tracker,
  }
}
