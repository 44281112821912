import { Ref, ref } from 'vue'

export const sortByField = () => {
  const sortBy:Ref<string> = ref<string>('')

  function changeSortDirection(sort: {
    column: string,
    prop: string,
    order: string,
  }): void {
    if (sort.order === 'ascending') {
      sortBy.value = sort.prop
    } else if (sort.order === 'descending') {
      sortBy.value = '-' + sort.prop
    } else {
      sortBy.value = ''
    }
  }

  function addSortToQueryParams(searchParams: URLSearchParams, value: string): void {
    searchParams.set('sort', value)
  }

  return {
    sortBy,
    changeSortDirection,
    addSortToQueryParams,
  }
}