<template>
  <div
    v-if="formData"
    class="color-edit">
    <ColorsForm
      v-model="myModelValue"
      :initial-form-data="formData"
      :handle-save="handleSave"
      :is-creating="false"
      :model-value="modelValue"
      :title="title"
      @close="$emit('update:modelValue', false)">
      <div class="title">
        {{ formData.name.ru }}
      </div>
    </ColorsForm>
  </div>
</template>

<script setup>
  import ColorsForm from '@/components/color/ColorsForm'
  const emit = defineEmits(['updateObject'])

  const props = defineProps({
    formData: Object,
    updateObject: Function,
    index: Number,
    modelValue: Boolean,
    title: String,
  })

  const handleSave = async (form) => {
    emit('updateObject', props.index, form)
  }

</script>

<style scoped>

</style>
