<script setup>
  import { useStore } from 'vuex'
  import { computed, ref, watch } from 'vue'

  const store = useStore()

  const productColorPrice = ref()

  const selectedColor = computed(() => store.getters['productStore/selectedColor'])
  watch(selectedColor, () => {
    const product = store.getters['productStore/product']
    if (selectedColor.value) {
      const productPrice = product.prices.find(price => price.color_id === selectedColor.value)
      if (productPrice) {
        productColorPrice.value = productPrice.price
      } else {
        productColorPrice.value = 0
      }
    }
  })
</script>

<template>
  <div>
    <el-row justify="space-between">
      <el-col
        class="row-column"
        :span="10">
        <span>РЦ: </span>
        <el-input
          v-model="productColorPrice"
          type="number"
          min="0"
          disabled>
          <template #append>
            ₽
          </template>
        </el-input>
      </el-col>
    </el-row>
  </div>
  <el-divider />
</template>

<style lang="scss" scoped>
.el-input {
  margin-left: 10px;
  margin-right: 20px;
  width: 200px;
}
</style>