import axios from 'axios'
import { ElNotification } from 'element-plus'

export const capsulesStore = {
  namespaced: true,
  state: () => ({
    capsules: [],
  }),

  getters: {
    capsules(state) {
      return state.capsules
    },
  },

  mutations: {
    fetchCapsules(state, capsules) {
      state.capsules = capsules
    },
  },

  actions: {
    async fetchCapsules(context) {
      await axios.get('/capsules')
        .then(res => {
          context.commit('fetchCapsules', res.data.capsules)
          return res
        })
        .catch(err => {
          ElNotification({
            title: 'Error',
            message: err.response.data.message,
            type: 'error',
          })
          throw new Error(err.response.data.message)
        })
    },
  },
}