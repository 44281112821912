<template>
  <CertificateCreate
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Создание сертификата"
    @create="create" />

  <CertificateEdit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    title="Редактирование сертификата"
    @updateObject="updateObject" />

  <div>
    <header>
      <el-row
        justify="space-between"
        class="mb-2">
        <el-col :span="6">
          <h1>Список сертификатов</h1>
        </el-col>
        <el-col
          :span="12"
          justify="ri">
          <el-row justify="end">
            <el-button
              :disabled="!canEditCertificates"
              type="success"
              class="inline-block"
              @click="createDialogVisible = true">
              Создать
            </el-button>
          </el-row>
        </el-col>
      </el-row>
      <el-row justify="space-between">
        <el-col :span="24">
          <el-input
            v-model="searchInput"
            placeholder="Номер сертификата, номер телефона"
            class="input-with-select"
            clearable>
            <template #append>
              <el-button icon="Search" />
            </template>
          </el-input>
        </el-col>
      </el-row>
    </header>

    <el-table
      v-loading="loading"
      :data="mainObjects"
      class="resource-table paginated">
      <el-table-column
        label="ID"
        prop="id" />
      <el-table-column
        label="Номер сертификата"
        prop="number" />
      <el-table-column
        label="Телефон"
        prop="phone" />
      <el-table-column
        label="Номинал"
        prop="amount" />
      <el-table-column
        label="Использованный номинал"
        prop="used_amount" />
      <el-table-column
        label="Действителен до"
        prop="until_at" />
      <el-table-column
        label="Дата создания"
        prop="created_at" />
      <el-table-column
        label="Дата Обновления"
        prop="updated_at" />

      <el-table-column
        label="Действия"
        fixed="right"
        width="120px">
        <template #default="scope">
          <el-space
            wrap
            size="large">
            <el-col :span="6">
              <el-button
                :disabled="!canEditCertificates"
                type="primary"
                icon="Edit"
                circle
                @click="handleEdit(scope.$index, scope.row)" />
            </el-col>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:page-size="paginationPageSize"
      layout="prev, pager, next"
      :total="total"
      :current-page="currentPage"
      @current-change="setPage" />
  </div>
  <div class="mt-1 text-danger">
    {{ message }}
  </div>
</template>

<script>
  import { computed, onMounted, ref, watch } from 'vue'
  import useTable from '@/components/shared/table/useTable'
  import { Delete, Edit, Search } from '@element-plus/icons-vue'
  import { tablePagination } from '@/composables/tablePagination'
  import axios from 'axios'
  import { ElNotification } from 'element-plus'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import CertificateCreate from '@/components/certificate/CertificateCreate.vue'
  import CertificateEdit from '@/components/certificate/CertificateEdit.vue'

  export default {
    name: 'CommodityGroupsPage',
    components: { CertificateEdit, CertificateCreate },
    setup() {
      const modelName = ref('certificate')
      const modelNamePlural = ref('certificates')
      const loading = ref(false)
      const router = useRouter()
      const route = useRoute()

      const store = useStore()

      const extraData = {
        loadOnMounted: false,
      }

      const tableHelper = useTable(modelName, modelNamePlural, extraData)

      const {
        pageSize: paginationPageSize,
        currentPage,
        addPaginationToQueryParams,
      } = tablePagination()

      const setPage = (page) => {
        router.replace({ path: route.path, query: { page } })
        currentPage.value = parseInt(page)
        loadData(page)
      }

      const searchInput = ref('')
      watch(searchInput, () => {
        tableHelper.mainObjects.value = []
        currentPage.value = 1
        loadData()
      })

      const loadData = async () => {
        loading.value = true

        const searchParams = new URLSearchParams('')
        addPaginationToQueryParams(searchParams)
        searchParams.set('brand_id', store.getters['main/currentBrand'].id)

        if (searchInput.value !== '') {
          searchParams.set('filter[search]', searchInput.value)
        }

        const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/' +
          'certificates?' + searchParams
        try {
          await axios.get(url).then((response) => {
            tableHelper.mainObjects.value = response.data.data
            tableHelper.total.value = response.data.meta.total

          }).finally(() => {
            loading.value = false
          })
        } catch (exception) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось загрузить данные',
            type: 'error',
          })
        }
      }

      const updateObject = async (index, oldNumber, newObject) => {
        const url =
          process.env.VUE_APP_CATALOG_API_URL +
          '/api/v1/' +
          modelNamePlural.value +
          '/' +
          oldNumber
        try {
          const data = await axios.patch(url, newObject)
          tableHelper.mainObjects.value[index] = { ...data.data.certificate }
          ElNotification({
            title: 'Успешно',
            message: 'Сертификат успешно обновлен',
            type: 'success',
          })
          tableHelper.editeDialogVisible.value = false
        } catch (exception) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось обновить',
            type: 'error',
          })
        }
      }

      watch(() => store.getters['main/currentBrand'], function () {
        loadData()
      })

      onMounted(() => {
        const page = route.query.page ? route.query.page : 1
        currentPage.value = parseInt(page)
        loadData(page)
      })

      const canEditCertificates = computed(() => {
        const store = useStore()
        return store.getters['userStore/getPermissions'].filter(e => e.name === 'edit certificates').length >= 1
      })

      return {
        ...tableHelper,
        loadData,
        setPage,
        updateObject,
        currentPage,
        Delete,
        Edit,
        Search,
        modelNamePlural,
        searchInput,
        route,
        loading,
        paginationPageSize,
        canEditCertificates,
      }
    },
  }
</script>

<style></style>
