<template>
  <span><span>Штрих-код</span><el-input
    :model-value="barcode"
    readonly
    style="width: 200px; margin-left: 10px;" /></span>
</template>

<script setup>
  import { computed, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()

  // const marketplaces = computed(() => store.getters['marketplacesStore/marketplaces'])
  const barcodes = computed(() => store.getters['productStore/barcodes'])
  const selectedColor = computed(() => store.getters['productStore/selectedColor'])
  const selectedSize = computed(() => store.getters['productStore/selectedSize'])
  const selectedPlatform = computed(() => store.getters['productStore/selectedPlatform'])
  const barcode = ref(null)

  watch([selectedColor, selectedPlatform], () => loadPlatformBarcode())
  onMounted(() => loadPlatformBarcode())

  function loadPlatformBarcode () {

    if (selectedPlatform.value && selectedColor.value && selectedSize.value) {
      let barcodeData

      if (selectedPlatform.value?.type === 'App\\Models\\Marketplace') {
        barcodeData = barcodes.value.find(item => item.marketplace_id === selectedPlatform.value.id
          && item.color_id === selectedColor.value
          && item.size_id === selectedSize.value)
      } else {
        barcodeData = barcodes.value.find(item => !item.marketplace_id
          && item.color_id === selectedColor.value
          && item.size_id === selectedSize.value)
      }

      barcode.value = barcodeData?.barcode
    }
  }

</script>

<style scoped>

</style>