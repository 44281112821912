<script setup>
  import { computed, reactive, ref, onMounted, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import ImgUpload from './ImgUpload.vue'
  import ImageHelper from '@/helpers/ImageHelper'
  import { ElLoading } from 'element-plus'
  import draggable from 'vuedraggable'

  const store = useStore()
  const imageHelper = new ImageHelper()
  const imgPreview = ref(null)
  const route = useRoute()
  const defaultImages = ref([])
  const uploadData = reactive({
    color_id: null,
    product_id: null,
    platform_id: null,
    platform_type: '',
  })
  const platformId = ref('')
  const product = computed(() => {
    return store.state.productStore.product
  })
  /*const marketplaces = computed(() => {
  return store.state.marketplacesStore.marketplaces
})*/
  const sites = computed(() => {
    return store.state.sitesStore.sites
  })
  const platforms = computed(() => {
    //TODO: временно скрыты маркетплейсы
    //const arrM = getArray(marketplaces.value, 'Marketplace')
    const arrS = getArray(sites.value, 'Site')
    return [...arrS]
  })

  const originalImages = computed(() => {
    return filteredImages('original')
  })

  const filteredImages = (group) => {
    return product.value.images.filter(el => el.group === group && el.color_id === uploadData.color_id && el.platform_id === uploadData.platform_id)
  }

  watch(() => uploadData.platform_id, (val) => {
    if (val) {
      imgPreview.value = null
    }
  })
  watch(() => uploadData.color_id, (val) => {
    if (val) {
      imgPreview.value = null
    }
  })
  watch(() => product.value.images, () => {
    defaultImages.value = filteredImages('default')
  })

  function getArray(platform, platformType) {
    const arr = []
    const salesPlatform = product.value.sale_platforms.filter(platform => platform.color_id === uploadData.color_id)
    salesPlatform.forEach(item => {
      const find = platform.filter(el => el.id === item.platform_id && item.platform_type.includes(platformType))
      if (find && find.length) {
        find[0].platform_type = platformType
        arr.push(find[0])
      }
    })
    return arr
  }
  function createSpinner() {
    return ElLoading.service({
      lock: true,
      text: 'Loading',
    })
  }

  async function onDelete(id) {
    const spinner = createSpinner()
    try {
      await store.dispatch('imagesStore/deleteImage', id)
      await store.dispatch('productStore/fetchProduct', { id: route.params.id })
      if (imgPreview.value && imgPreview.value.id === id) {
        imgPreview.value = null
      }
    } catch (e) {
      return
    } finally {
      spinner.close()
    }
  }

  async function duplicateHandler() {
    const spinner = createSpinner()
    const data = {
      product_id: uploadData.product_id,
      platform_id: 1,
      platform_type: 'site',
      to_platform_id: uploadData.platform_id,
      to_platform_type: uploadData.platform_type.toLocaleLowerCase(),
    }
    try {
      await store.dispatch('imagesStore/duplicateImages', data)
      await store.dispatch('productStore/fetchProduct', { id: route.params.id })
    } catch (error) {
      return
    } finally {
      spinner.close()
    }
  }
  async function onChange(val) {
    const spinner = createSpinner()
    if (val.moved) {
      const id = val.moved.element.id
      const position = val.moved.newIndex
      try {
        await store.dispatch('imagesStore/reorderImage', { id, position })
        await store.dispatch('productStore/fetchProduct', { id: route.params.id })
      } catch (error) {
        return error
      }
      spinner.close()
    }
  }
  function onPlatformChange(item) {
    uploadData.platform_id = item.id
    uploadData.platform_type = item.platform_type
    defaultImages.value = filteredImages('default')
  }
  function onColorChange() {
    defaultImages.value = filteredImages('default')
  }
  onMounted(() => {
    uploadData.color_id = product.value.colors[0]?.id ?? ''
    uploadData.platform_type = 'site'
    uploadData.product_id = product.value.id
    uploadData.platform_id = platforms.value[0]?.id ?? ''
    platformId.value = `${platforms.value[0]?.id}Site`
    defaultImages.value = filteredImages('default')
  })
</script>

<template>
  <el-row :gutter="20">
    <el-col :span="6">
      <div class="btn__wrapper mb-2">
        <el-button
          v-if="uploadData.platform_id !== 1 && platforms.length > 0"
          @click="duplicateHandler">
          Скопировать все фото с RU
        </el-button>
      </div>
      <div>
        <el-image
          v-if="imgPreview"
          :src="imageHelper.getUrl(imgPreview.id)"
          fit="cover" />
        <el-skeleton
          v-else
          style="width: 100%;">
          <template #template>
            <el-skeleton-item
              variant="image"
              style="width: 100%; height: 300px" />
          </template>
        </el-skeleton>
        <p class="mt-2">
          <b>Артикул:</b> {{ product.article_number }}
        </p>
      </div>
    </el-col>
    <el-col :span="18">
      <el-radio-group
        v-model="uploadData.color_id"
        fill="inherit">
        <el-radio
          v-for="color in product.colors"
          :key="color.id"
          :label="color.id"
          class="radio__color"
          :style="`background-color: ${color.value}`"
          @change="onColorChange">
          <span>{{ color.name.ru }}</span>
        </el-radio>
      </el-radio-group>
      <div class="mt-2">
        <el-radio-group v-model="platformId">
          <el-radio
            v-for="item in platforms"
            :key="`${item.id}-${item.platform_type}`"
            :label="`${item.id}${item.platform_type}`"
            class="platform__radio"
            @change="onPlatformChange(item)">
            <span class="platform__item">
              <span><b>{{ item.short_name }}</b></span>
              <el-icon :size="32">
                <Picture />
              </el-icon>
            </span>
          </el-radio>
        </el-radio-group>
      </div>
      <div
        v-if="platforms.length"
        class="mt-2">
        <h2 class="image__heading">
          Фото для площадки
        </h2>
        <div class="image__box">
          <draggable
            v-model="defaultImages"
            item-key="id"
            @change="onChange">
            <template #item="{element}">
              <div
                class="image__small"
                :class="{'image__box-active' : imgPreview?.id === element.id}">
                <el-image
                  :src="imageHelper.getUrl(element.id)"
                  @click="imgPreview = element" />
                <el-button
                  class="button__close"
                  icon="Close"
                  size="small"
                  circle
                  @click="onDelete(element.id)" />
              </div>
            </template>
          </draggable>

          <el-skeleton
            v-if="!defaultImages.length"
            style="width: 120px; margin-right: 10px;">
            <template #template>
              <el-skeleton-item
                variant="image"
                style="width: 120px; height: 179px" />
            </template>
          </el-skeleton>
          <ImgUpload
            class="image__uploader"
            :data="{...uploadData, group: 'default'}" />
        </div>
      </div>
      <div
        v-if="platforms.length"
        class="mt-2">
        <h2 class="image__heading">
          Исходники фото на Арт+цвет
        </h2>
        <div class="image__box">
          <div
            v-for="img in originalImages"
            :key="img.id"
            class="image__small"
            :class="{'image__box-active' : imgPreview?.id === img.id}">
            <el-image
              :src="imageHelper.getUrl(img.id)"
              @click="imgPreview = img" />
            <el-button
              class="button__close"
              icon="Close"
              size="small"
              circle
              @click="onDelete(img.id)" />
          </div>
          <el-skeleton
            v-if="!originalImages.length"
            style="width: 120px; margin-right: 10px;">
            <template #template>
              <el-skeleton-item
                variant="image"
                style="width: 120px; height: 179px" />
            </template>
          </el-skeleton>
          <ImgUpload
            class="image__uploader"
            :data="{...uploadData, group: 'original'}" />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="scss">
  .radio__color{
    border: 2px solid #ffffff;
    border-radius: 4px;
    &.el-radio{
      margin-right: 6px;
    }
    .el-radio__inner{
      display: none
    }
    .el-radio__label{
      padding-left: 8px;
      padding-right: 8px;
      color: #ffffff;
      text-shadow: 0 0 3px rgb(0 0 0 / 60%);
    }
    &.is-checked,
    &:hover{
      outline: #333 solid 1px;
    }
    .el-radio__input.is-checked+.el-radio__label {
      color: #ffffff;
    }
  }
  .image__small{
    width: 120px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .button__close{
    position: absolute;
    top: 2px;
    right: 2px;
  }
  .image__uploader {
    width: 120px;
  }
  .image__box{
    display: flex;
    .el-image{
      border: 2px solid white;
    }
    &-active{
      .el-image{
        border: 2px solid black;
      }
    }
  }
  .image__heading{
    font-size: 16px;
    margin-bottom: 15px;
  }
  .platform{
    &__radio{
      .el-radio__inner{
        display: none
      }
    }
    &__item{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .btn__wrapper{
    height: 35px;
  }
</style>
