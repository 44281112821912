<template>
  <ConfirmModal
    v-model="deleteDialogVisible"
    :confirm-function="handleConfirmedDelete"
    text="Вы уверены, что хотите удалить сайт?" />
                
  <SitesCreate
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Создать сайт"
    @create="create" />
  <SitesEdit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    title="Изменить сайт"
    @updateObject="updateObject" />

  <div>
    <header>
      <el-row
        justify="space-between"
        class="mb-2">
        <el-col :span="6">
          <h1>Список сайтов</h1>
        </el-col>
        <el-col
          :span="3"
          justify="ri">
          <el-row justify="end">
            <el-button
              :disabled="!canEditDictionaries"
              type="success"
              fixed="right"
              @click="createDialogVisible = true">
              Создать
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </header>


    <div v-if="!loadingData">
      <el-table
        :data="mainObjects"
        class="resource-table">
        <el-table-column
          label="ID"
          prop="id"
          sortable />
        <el-table-column
          label="Название"
          prop="name"
          sortable />
        <el-table-column
          label="Бренд"
          prop="brand_name"
          sortable />
        <el-table-column
          label="Краткое название"
          prop="short_name"
          sortable />
        <el-table-column
          label="Ссылка"
          prop="url"
          sortable />
        <el-table-column
          label="Обозначение валюты"
          prop="currency_sign"
          sortable />

        <el-table-column
          label="Действия"
          fixed="right"
          width="120px">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-col :span="6">
                <el-button
                  :disabled="!canEditDictionaries"
                  type="primary"
                  icon="Edit"
                  circle
                  @click="handleEdit(scope.$index, scope.row)" />
              </el-col>
              <!--              Удаление отключено до продумывания функционала удаления-->
              <!--              <el-col :span="6">
                <el-button type="danger" icon="Delete" circle @click="handleDelete(scope.$index, scope.row)"/>
              </el-col>-->
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-1 text-danger">
      {{ message }}
    </div>
  </div>
</template>

<script>

  import ConfirmModal from '@/components/shared/ConfirmModal'
  import { computed, ref, watch } from 'vue'
  import useTable from '@/components/shared/table/useTable'
  import SitesCreate from '@/components/site/SitesCreate'
  import SitesEdit from '@/components/site/SitesEdit'

  import {
    Delete,
    Edit,
  } from '@element-plus/icons-vue'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'

  export default {
    name: 'SitesPage',
    components: { ConfirmModal, SitesCreate, SitesEdit },
    setup() {
      const modelName = ref('site')
      const modelNamePlural = ref('sites')

      const store = useStore()

      let extraData
      if (!store.getters['main/currentBrand']) {
        extraData = {
          loadOnMounted: false,
        }
      } else {
        extraData = {
          extraQuery: 'brand_id=' +  store.getters['main/currentBrand'].id,
        }
      }

      extraData.checkBrand = true

      const tableHelper = useTable(modelName, modelNamePlural, extraData)
      const loadData = tableHelper.loadData

      watch(() => store.getters['main/currentBrand'], function(brand) {
        const extraData = {
          extraQuery: 'brand_id=' + brand.id,
        }
        loadData(1, extraData)
      })

      const create = async (newObject, settings = {}) => {

        if (!store.getters['main/currentBrand']) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось опредеилть текущий бренд',
            type: 'error',
          })

          return false
        }
        settings = {
          extraQuery: 'brand_id=' + store.getters['main/currentBrand'].id,
        }

        await tableHelper.create(newObject, settings)
      }

      const canEditDictionaries = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit system dictionaries').length >= 1)

      return {
        ...tableHelper,
        create,
        Delete,
        Edit,
        modelNamePlural,
        canEditDictionaries,
      }
    },
  }
</script>

<style>

</style>
