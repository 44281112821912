<template>
  <collections-form
    :initial-form-data="{ ...formData }"
    :handle-save="save"
    :message="message"
    :is-creating="true"
    :model-value="modelValue"
    :title="title"
    @close="$emit('update:modelValue', false)" />
</template>

<script setup>
  import { watch } from 'vue'
  import CollectionsForm from '@/components/collection/CollectionsForm'
  import { ref } from 'vue'
  import { useStore } from 'vuex'


  const store = useStore()
  const emit = defineEmits(['create'])
  defineProps({
    modelValue: Boolean,
    title: String,
  })

  const formData = ref({
    type: '',
    year: '',
  })
  const message = ref('')

  const brand = store.getters['main/currentBrand']
  if (brand) {
    formData.value.brand_id = brand.id
  }

  watch(
    () => store.getters['main/currentBrand'],
    function (brand) {
      formData.value.brand_id = brand.id
    },
  )

  const save = async (newObject) => {
    emit('create', newObject)
  }
</script>

<style scoped></style>
