<template>
  <div
    v-if="formData"
    class="size-edit">
    <capsule-form
      :initial-form-data="formData"
      :handle-save="handleSave"
      :is-creating="false"
      :model-value="modelValue"
      :title="title"
      @close="$emit('update:modelValue', false)">
      <div class="title">
        {{ formData.name }}
      </div>
    </capsule-form>
  </div>
</template>

<script setup>
  import CapsuleForm from '@/components/capsule/CapsuleForm'
  const emit = defineEmits(['updateObject'])

  const props = defineProps({
    formData: Object,
    updateObject: Function,
    index: Number,
    modelValue: Boolean,
    title: String,
  })

  const handleSave = async (form) => {
    emit('updateObject', props.index, form)
  }
</script>

<style scoped></style>
