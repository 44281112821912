export default class StrHelper {
  slug(phrase) {
    let answer = ''
    const converter = {
      'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
      'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
      'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
      'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
      'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
      'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
      'э': 'e',    'ю': 'yu',   'я': 'ya',

      '(': '-',
      ')': '-',
      '!': '-',
      '&': '-',
      '?': '-',
      ':': '-',
      ';': '-',
      '`': '-',
      ' ': '-',
    }

    phrase = phrase.toLowerCase()
    for (let i = 0; i < phrase.length; ++i ) {
      if (converter[phrase[i]] === undefined) {
        answer += phrase[i]
      } else {
        answer += converter[phrase[i]]
      }
    }

    return answer
  }
}