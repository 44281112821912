import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const compositionsStore = {
  namespaced: true,
  state: () => ({
    compositions: [],
  }),

  getters: {
    compositions(state) {
      return state.compositions
    },
  },

  mutations: {
    async fetchCompositions(state) {
      await request.get('compositions').then((res)=>{
        state.compositions = res.data.compositions
      })
    },
  },

  actions: {
    async fetchCompositions(context, id) {
      await context.commit('fetchCompositions', id)
    },
  },
}