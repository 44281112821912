export const calculatePrices = () => {
  function roundAndEndWith90(x: number): number {
    if ((x - 90) % 100 === 0) {
      return x
    }
    return Math.round(x / 100) * 100 + 90
  }

  function calculatePercent(basePrice, discountPrice): number {
    const percent: number = Math.round((1 - discountPrice / basePrice) * 100)
    return Math.max(percent, 0)
  }

  function calculateDiscountPrice(basePrice: number, percent: number): number {
    const discountPrice: number = basePrice * (1 - percent / 100)
    return roundAndEndWith90(discountPrice)
  }

  function calculateDiscountPriceWithDiscount(basePrice: number, discount: number): number {
    const discountPrice: number = basePrice - discount
    return roundAndEndWith90(discountPrice)
  }

  return {
    calculatePercent,
    calculateDiscountPrice,
    calculateDiscountPriceWithDiscount,
  }
}