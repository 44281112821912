<script setup>
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  import ColorSelector from '@/components/product/ColorSelector.vue'

  const store = useStore()

  const selectedColor = computed(() => store.getters['productStore/selectedColor'])
  const pricings = computed(() => {
    return store.getters['productStore/product']?.pricings
  })

  const costRuSelectedColor = computed(() => {
    if (!pricings.value) {
      return ''
    }

    return pricings.value.find(pricing => pricing.color_id === selectedColor.value)?.cost_ru
  })
</script>

<template>
  <div>
    <h3>Ценообразование</h3>
    <color-selector />
    <h4>Себестоимость Россия: {{ costRuSelectedColor }} ₽</h4>
  </div>
</template>

<style lang="scss" scoped>

</style>
