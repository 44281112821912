<script setup>
  import { useStore } from 'vuex'

  const store = useStore()
</script>

<template>
  <div>
    <strong>Артикул: {{ store.getters['productStore/product'].article_number }} </strong>
  </div>
</template>
