<script setup>
  import { computed, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import axios from 'axios'
  import ImageHelper from '@/helpers/ImageHelper'
  import { ElNotification } from 'element-plus'

  const imageHelper = new ImageHelper()
  const store = useStore()


  const categoryProducts = ref([])


  const category = ref(null)
  const article = ref(null)

  watch(category, () => {
    get()
  })


  async function get() {
    categoryProducts.value = []
    loading.value = true

    try {
      const response = await axios.get(`categories/${category.value}/products`, {
        params: {
          article_number: article.value ? article.value : null,
        },
      })

      categoryProducts.value = response.data.products
    } finally {
      loading.value = false
    }

  }

  async function save() {
    try {
      await axios.put(`categories/${category.value}/products/update-positions`, {
        products: categoryProducts.value.filter(product => product.changed).map(product => {
          return {
            product_id: product.id,
            color_id: product.color_id,
            position: product.position,
          }
        }),
      })

      for (const product of categoryProducts.value) {
        product.changed = false
      }

      ElNotification({
        type: 'success',
        message: 'Позиции товаров изменены',
      })

      await get()
    } finally { /* empty */ }
  }

  const currentBrandId = computed(() => store.getters['main/currentBrand']?.id)

  watch(currentBrandId, async () => {
    await fetchCategory(currentBrandId)
  })

  const fetchCategory = async (currentBrandId) => {
    if (currentBrandId.value) {
      await store.dispatch('categoriesStore/fetchCategories', currentBrandId.value)
    }
  }

  const loading = ref(false)

  fetchCategory(currentBrandId)

  const canEditProductPositions = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit product positions').length >= 1)
</script>

<template>
  <div>
    <div class="header">
      <h1>Позиции товаров в категориях</h1>
    </div>
    <el-divider />
    <div class="actions">
      <div>
        <el-tree-select
          v-model="category"
          placeholder="Выберите категорию"
          default-expand-all
          :props="{label: 'name'}"
          check-strictly
          :data="store.getters['categoriesStore/categories']"
          @swap="true" />
      </div>
      <div>
        <el-input
          v-model="article"
          placeholder="Артикул"
          @change="get" />
      </div>
      <div class="save">
        <el-button
          :disabled="!canEditProductPositions"
          type="primary"
          @click="save">
          Сохранить
        </el-button>
      </div>
    </div>


    <div
      v-loading="loading"
      class="list head">
      <div class="list-item headers">
        <div class="col">
          Фото
        </div>
        <div class="col">
          Артикул
        </div>
        <div class="col">
          Цвет
        </div>
        <div class="col">
          Название
        </div>
        <div class="col">
          Позиция
        </div>
      </div>
    </div>
    <div
      id="list"
      ref="list"
      class="list">
      <div
        v-for="product in categoryProducts"
        :key="product.id"
        class="list-item"
        :data-id="product.id"
        :data-color="product.color"
        :class="{changed: product.changed}">
        <div class="col">
          <img
            v-if="product.image"
            width="100"
            height="100"
            :src="imageHelper.getUrl(product.image.id)"
            alt="">
        </div>
        <div class="col">
          {{ product.article_number }}
        </div>
        <div class="col">
          <div
            class="color"
            :style="{background: product.color}" />
        </div>
        <div class="col">
          {{ product.name }}
        </div>
        <div class="col">
          <el-input
            v-model="product.position"
            :disabled="!canEditProductPositions"
            placeholder="Позиция"
            @change="product.changed = true" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1 {
  font-size: 25px;
}

.actions {
  display: flex;
  margin-bottom: 0;

  > div {
    //width: 20%;
    margin-right: 20px;
  }

  .save {
    justify-self: flex-end;
  }
}

.list {
  display: flex;
  flex-direction: column;

  &.head {
    .list-item {
      background: white;
    }
  }

  .list-item {
    display: flex;
    transition: background-color 200ms;
    border-bottom: 2px solid #e8e8e8;

    img {
      width: 100px;
      height: 100px;
    }

    .color {
      width: 30px;
      height: 30px;
    }

    &.changed {
      background: #fff0e1;
    }

    &.highlight {
      background: #c0dcff;
    }

    .col {
      width: 20%;
      padding: 10px;
    }
  }
}
</style>
