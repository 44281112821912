export interface ISizeGroup {
  id: number;
  name: string;
  position: number;
}

export const defaultSizeGroup: ISizeGroup = {
  name: '',
  id: 0,
  position: 0,
}

export interface ICreateSizeGroup {
  name: string;
}

export const defaultCreateSizeGroup: ICreateSizeGroup = {
  name: '',
}

export interface ISize {
  id: number;
  name: string;
  position: number;
  size_group_id: number;
}

export const defaultSize: ISize = {
  name: '',
  id: 0,
  position: 0,
  size_group_id: 0,
}

export interface ICreateSize {
  name: string;
  size_group_id: number;
  is_composite: boolean;
  size_composites: object;
}

export const defaultCreateSize: ICreateSize = {
  name: '',
  size_group_id: 1,
  is_composite: false,
  size_composites: [],
}
