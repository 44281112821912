import { Ref, ref } from 'vue'

export const tablePagination = () => {
  const pageSize: Ref<number> = ref<number>(40)
  const currentPage: Ref<number> = ref<number>(1)

  function setPage(page: number): void {
    currentPage.value = page
    scrollToTop()
  }

  function scrollToTop(): void {
    window.scrollTo(0,0)
  }

  function addPaginationToQueryParams(searchParams: URLSearchParams): void {
    searchParams.set('per_page', String(pageSize.value))
    searchParams.set('page', String(currentPage.value))
  }

  return {
    pageSize,
    currentPage,
    setPage,
    addPaginationToQueryParams,
  }
}
