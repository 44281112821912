import axios from 'axios'
import { ElNotification } from 'element-plus'

export const manufactureCategoriesStore = {
  namespaced: true,
  state: () => ({
    manufactureCategories: [],
  }),

  getters: {
    manufactureCategories(state) {
      return state.manufactureCategories
    },
  },

  mutations: {
    fetchManufactureCategories(state, manufactureCategories) {
      state.manufactureCategories = manufactureCategories
    },
  },

  actions: {
    async fetchManufactureCategories(context) {
      await axios.get('/manufacture-categories')
        .then(res => {
          context.commit('fetchManufactureCategories', res.data.manufacture_categories)
          return res
        })
        .catch(err => {
          ElNotification({
            title: 'Error',
            message: err.response.data.images,
            type: 'error',
          })
          throw new Error(err.response.data.message)
        })
    },
        
  },
}