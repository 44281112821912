import { IRole } from '@/types/roleAndPermission.types'

export interface IUser {
  id: number;
  email: string;
  name: string;
  created_at: string;
  updated_at: string;
  roles: IRole[];
}

export const defaultUser: IUser = {
  id: 0,
  email: '',
  name: '',
  created_at: '',
  updated_at: '',
  roles: [],
}

export interface IRegisterUser {
  email: string;
  name: string;
  password: string;
}

export const defaultRegisterUser: IRegisterUser = {
  email: '',
  name: '',
  password: '',
}

export interface IUpdateUser {
  id: number;
  email: string;
  name: string;
}