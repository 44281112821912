<script setup>
  import { computed, ref, watch } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()
  const loading = ref(false)

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const selectedColor = computed(() => store.getters['productStore/selectedColor'])
  const productColors = computed(() => store.getters['productStore/colors'])

  const isColorEnabled = ref(false)

  watch(() => selectedColor.value, () => {
    const productColorData = productColors.value.find(color => color.id === selectedColor.value)
    isColorEnabled.value = productColorData?.enabled ?? false
  })

  watch(() => productColors.value, () => {
    const productColorData = productColors.value.find(color => color.id === selectedColor.value)
    isColorEnabled.value = productColorData?.enabled ?? false
  })

  async function save () {
    loading.value = true
    await store.dispatch('productStore/changeColorActivity', { colorId: selectedColor.value, enabled: isColorEnabled.value })
    loading.value = false
  }
</script>

<template>
  <div class="color_activity_toggler">
    <b>Активность цвета на всех площадках</b>
    <el-switch
      v-model="isColorEnabled"
      :disabled="!canEdit"
      size="large"
      :loading="loading"
      @change="save" />
  </div>
</template>

<style scoped>
.color_activity_toggler{
  margin-bottom: 20px;
}
</style>
