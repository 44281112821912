import { onMounted, ref } from 'vue'

import { ElMessageBox, ElNotification } from 'element-plus'
import { useStore } from 'vuex'
import axios from 'axios'

export default function (modelName, modelNamePlural, extraData = {}) {
  const store = useStore()

  const createDialogVisible = ref(false)
  const editeDialogVisible = ref(false)
  const deleteDialogVisible = ref(false)
  const dragDialogVisible = ref(false)
  const loadingData = ref(true)
  const selectedIndex = ref(0)
  const deletingId = ref(0)
  const message = ref('')
  const mainObjects = ref([])

  const pageSize = ref(40)
  const total = ref(0)
  const editingObject = ref({})

  const loadData = async (page = 1, settings = {}) => {
    let extraQueryParams = 'extraQuery' in settings? '&' + settings.extraQuery: ''
    if (!extraQueryParams) {
      extraQueryParams = 'extraQuery' in extraData? '&' + extraData.extraQuery: ''
    }

    const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/'
            + modelNamePlural.value + '?per_page=9999&page=' + page + extraQueryParams

    try {
      const response = await axios.get(url)
      loadingData.value = false
      mainObjects.value = Object.values(response.data)[0] //response.data[modelNamePlural.value];
      total.value = response.data.total
    } catch (exception) {
      loadingData.value = false
      ElNotification({
        title: 'Ошибка',
        message: 'Не удалось загрузить данные',
        type: 'error',
      })
    }
  }

  const setPage = page => {
    loadData(page)
  }

  const makeSaveAlert = () => {
    ElNotification({
      title: 'Успешно',
      message: 'Данные успешно сохранены',
      type: 'success',
    })
  }

  const makeUpdateAlert = () => {
    ElNotification({
      title: 'Успешно',
      message: 'Данные успешно обновлены',
      type: 'success',
    })
  }

  const makeDeleteAlert = () => {
    ElNotification({
      title: 'Успешно',
      message: 'Данные успешно удалены',
      type: 'success',
    })
  }

  const makeMoveAlert = () => {
    ElNotification({
      title: 'Успешно',
      message: 'Позиция успешно изменена',
      type: 'success',
    })
  }

  const makeSaveErrorAlert = exception => {
    let alertMessage = 'Не удалось сохранить'
    if (exception?.response?.data?.message) {
      alertMessage += '. Ошибка: ' + exception.response.data.message
    }
    ElNotification({
      title: 'Ошибка',
      message: alertMessage,
      type: 'error',
    })
  }

  const makeUpdateErrorAlert = exception => {
    let alertMessage = 'Не удалось обновить'
    if (exception?.response?.data?.message) {
      alertMessage += '. Ошибка: ' + exception.response.data.message
    }
    ElNotification({
      title: 'Ошибка',
      message: alertMessage,
      type: 'error',
    })
  }

  const checkSameBrand = (extraData, newObject) => {
    if (!extraData?.checkBrand) {
      return true
    }
    return newObject.brand_id == store.getters['main/currentBrand'].id
  }

  const create = async (newObject, settings = {}) => {
    try {
      const extraQueryParams = 'extraQuery' in settings ? '?' + settings.extraQuery : ''
      const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/'
                + modelNamePlural.value + extraQueryParams

      const response = await axios.post(url, newObject)
      if (checkSameBrand(extraData, newObject)) {
        mainObjects.value.push(response.data[modelName.value])
      }
      makeSaveAlert()

      createDialogVisible.value = false

    } catch (exception) {
      makeSaveErrorAlert(exception)
    }
  }

  const updateObject = async (index, newObject) => {
    const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/' + modelNamePlural.value + '/' + newObject.id

    try {
      const response = await axios.patch(url, newObject)
      makeUpdateAlert()
      editeDialogVisible.value = false
      if (checkSameBrand(extraData, newObject)) {
        const replaceIndex = mainObjects.value.findIndex(object => object.id === response.data[modelName.value].id)
        mainObjects.value[replaceIndex] = { ...response.data[modelName.value] }
      } else {
        delete mainObjects.value[index]
      }
    } catch (exception) {
      makeUpdateErrorAlert(exception)
    }
  }

  /**
     * Удаляет объект после того, как пользователь подтвердил удаление во всплывающем окне
     *
     * @returns {Promise<void>}
     */
  const handleConfirmedDelete = async () => {
    deleteDialogVisible.value = false

    const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/' + modelNamePlural.value + '/' + deletingId.value

    try {
      await axios.delete(url)
      makeDeleteAlert()
      delete mainObjects.value[selectedIndex.value]
    } catch (exception) {
      ElNotification({
        title: 'Ошибка',
        message: 'Не удалось удалить',
        type: 'error',
      })
    }
  }

  const dialogVisible = ref(false)

  const handleClose = (done) => {
    ElMessageBox.confirm('Вы уверены, что хотите закрыть окно?')
      .then(() => {
        done()
      })
  }

  /**
     * Действие открывает окно для удаления записи
     *
     * @param index
     * @param row
     */
  const handleDelete = (index, row) => {
    deleteDialogVisible.value = true
    selectedIndex.value = index
    deletingId.value = row.id
  }

  /**
     * Действие открывает всплывающее окно для редактирования записи
     *
     * @param index
     * @param row
     */
  const handleEdit = (index, row) => {
    editeDialogVisible.value = true
    selectedIndex.value = index
    editingObject.value = { ...row }
  }

  onMounted(() => {
    if (!('loadOnMounted' in extraData) || extraData.loadOnMounted) {
      loadData()
    }
  })

  /**
     * Действие, срабатывающее при переносе элемента
     *
     * @param e
     * @returns {Promise<void>}
     */
  const moveElement = async (e) => {
    const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/'
            + modelNamePlural.value + '/' + e.moved.element.id + '/reorder'
    const data = { position: e.moved.newIndex }

    try {
      await axios.put(url, data)
      makeMoveAlert()
    } catch (exception) {
      ElNotification({
        title: 'Ошибка',
        message: 'Не удалось изменить позицию',
        type: 'error',
      })
      await loadData()
    }
  }

  return {
    modelName,
    moveElement,
    dialogVisible,
    createDialogVisible,
    editeDialogVisible,
    deleteDialogVisible,
    dragDialogVisible,
    selectedIndex,
    deletingId,
    message,
    mainObjects,
    pageSize,
    total,
    editingObject,
    loadingData,
    handleClose,
    loadData,
    setPage,
    handleConfirmedDelete,
    handleDelete,
    create,
    handleEdit,
    updateObject,
  }
}
