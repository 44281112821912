<template>
  <div>
    Административная панель "Каталог"
  </div>
</template>

<script setup>
</script>

<style scoped>

</style>