import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router, RouteRecordRaw,
} from 'vue-router'

import auth from '@/route/middlewares/auth'
import guest from '@/route/middlewares/guest'
import checkRoles from '@/route/middlewares/checkRoles'
import checkPermissions from '@/route/middlewares/checkPermissions'

import indexPage from '../views/main.vue'
import homePage from '../views/home.vue'
import AuthComponent from '../views/auth.vue'
import SizesPage from '../views/resources/sizes.vue'
import PhotoModels from '@/views/resources/photoModels.vue'
import Measurements from '@/views/resources/measurements.vue'
import Marketplaces from '@/views/resources/marketplaces.vue'
import Colors from '@/views/resources/colors.vue'
import ColorGroups from '@/views/resources/colorGroups.vue'
import Languages from '@/views/resources/languages.vue'
import Sites from '@/views/resources/sites.vue'
import Categories from '@/views/resources/categories.vue'
import ProductCatalog from '@/views/resources/productCatalog.vue'
import ProductPrices from '@/views/prices/productPrices.vue'
import ProductPage from '../views/products/_id.vue'
import Compositions from '@/views/resources/compositions.vue'
import CompositionGroups from '@/views/resources/compositionGroups.vue'
import Collections from '@/views/resources/collections.vue'
import Fits from '@/views/resources/fits.vue'
import ManufactureCategories from '@/views/resources/manufactureCategories.vue'
import ManufactureGroups from '@/views/resources/manufactureGroups.vue'
import ManufactureTypes from '@/views/resources/manufactureTypes.vue'
import Capsules from '@/views/resources/capsules.vue'
import ManufactureCares from '@/views/resources/manufactureCares.vue'
import promotionsPage from '@/views/promotions/promotionsPage.vue'
import ManufacturersPage from '@/views/resources/manufacturers.vue'
import NewCategoriesPage from '@/views/resources/newCategories.vue'
import NecklinesPage from '@/views/resources/necklines.vue'
import WashingsPage from '@/views/resources/washings.vue'
import AccurateColorsPage from '@/views/resources/accurateColors.vue'
import AssortmentGroupsPage from '@/views/resources/assortmentGroups.vue'
import CommodityGroupsPage from '@/views/resources/commodityGroups.vue'
import { store } from '@/store/store'
import ProductPositions from '@/views/productPositions.vue'
import ProductNewSection from '@/views/productNewSection.vue'
import CertificatesPage from '@/views/certificates/certificatesPage.vue'
import UserPage from '@/views/user/usersPage.vue'
import activityLogsPage from '@/views/activity/activityLogsPage.vue'
import rolesAndPermissionsPage from '@/views/user/rolesAndPermissionsPage.vue'
import selectionsPage from '@/views/selections/selectionsPage.vue'


interface IRouteMeta {
  middleware: unknown[],
  layout: string,
  roles?: string[],
  permissions?: string[],
}

interface IRoute {
  path: string,
  component: unknown,
  meta: IRouteMeta,
  name?: string,
}

const routes: readonly RouteRecordRaw[] & readonly IRoute[] = [
  {
    path: '/',
    component: indexPage,
    meta: {
      middleware: [
        auth,
      ],
      layout: 'menu',
    },
  },
  {
    path: '/sizes',
    component: SizesPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/marketplaces',
    component: Marketplaces,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view system dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/measurements',
    component: Measurements,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/photo-models',
    component: PhotoModels,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/color-groups', component: ColorGroups,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/colors', component: Colors,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/languages',
    component: Languages,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view system dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/sites',
    component: Sites,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view system dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/categories',
    component: Categories,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view categories',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/products',
    component: ProductCatalog,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view products',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/home',
    component: homePage,
    name: 'home',
    meta: {
      middleware: [
        auth,
      ],
      layout: 'menu',
    },
  },
  {
    path: '/login',
    component: AuthComponent,
    meta: {
      middleware: [
        guest,
      ],
      layout: 'auth',
    },
    name: 'login',
  },
  {
    path: '/products/:id',
    component: ProductPage,
    meta: {
      middleware: [
        auth,
      ],
      layout: 'menu',
    },
    name: 'ProductPage',
  },
  {
    path: '/prices',
    component: ProductPrices,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view prices',
      ],
      layout: 'menu',
    },
    name: 'ProductPrices',
  },
  {
    path: '/compositions',
    component: Compositions,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/composition-groups',
    component: CompositionGroups,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/collections',
    component: Collections,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/fits',
    component: Fits,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/manufacture-categories',
    component: ManufactureCategories,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/manufacture-groups',
    component: ManufactureGroups,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/manufacture-types',
    component: ManufactureTypes,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/capsules',
    component: Capsules,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/manufacture-cares',
    component: ManufactureCares,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/promotions',
    component: promotionsPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view promotions',
      ],
      layout: 'menu',
    },
    name: 'promotionsPage',
  },
  {
    path: '/certificates',
    component: CertificatesPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view certificates',
      ],
      layout: 'menu',
    },
    name: 'certificatesPage',
  },
  {
    path: '/manufacturers',
    component: ManufacturersPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/positions',
    component: ProductPositions,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view product positions',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/new',
    component: ProductNewSection,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view product new section positions',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/new-categories',
    component: NewCategoriesPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/necklines',
    component: NecklinesPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/washings',
    component: WashingsPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/accurate-colors',
    component: AccurateColorsPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/assortment-groups',
    component: AssortmentGroupsPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/commodity-groups',
    component: CommodityGroupsPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view production dictionaries',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/users',
    component: UserPage,
    meta: {
      middleware: [
        auth,
        checkPermissions,
      ],
      permissions: [
        'view users',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/activity-logs',
    component: activityLogsPage,
    meta: {
      middleware: [
        auth,
        checkPermissions,
      ],
      permissions: [
        'view logs',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/roles-and-permissions',
    component: rolesAndPermissionsPage,
    meta: {
      middleware: [
        auth,
        checkPermissions,
      ],
      permissions: [
        'view roles and permissions',
      ],
      layout: 'menu',
    },
  },
  {
    path: '/selections',
    component: selectionsPage,
    meta: {
      middleware: [
        auth,
      ],
      permissions: [
        'view selections',
      ],
      layout: 'menu',
    },
    name: 'selectionsPage',
  },
]

const router: Router = createRouter({
  history: createWebHistory(),
  routes,
})

/* Creates a `nextMiddleware()` function which not only
runs the default `next()` callback but also triggers
the subsequent Middleware function.
*/
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters: unknown[]): void => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (to.meta.middleware) {
    const middleware = to.meta.middleware
    const context = {
      to,
      from,
      next,
      store,
      router,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({
      ...context,
      next: nextMiddleware,
    })
  }

  return next()
})

export default router
