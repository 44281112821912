<script>
  import axios from 'axios'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'

  export default {
    data() {
      return {
        dialogVisible: false,
        store: useStore(),
        popupLoading: false,
        form: {
          name: '',
          article_number: '',
        },
      }
    },
    computed: {
      canCreateProduct() {
        const store = useStore()
        return store.getters['userStore/getPermissions'].filter(e => e.name === 'create products').length >= 1
      },
    },
    methods: {
      clearForm() {
        this.form = {
          title: '',
          article_number: '',
        }
      },
      async save() {
        this.popupLoading = true
        await axios.post('/products', {
          brand_id: this.store.getters['main/currentBrand'].id,
          article_number: this.form.article_number,
          enabled: 0,
          rrc_ru: 0,
          rrc_global: 0,
          is_refundable: 1,
          name: this.form.name,
        }).then(()=>{
          this.$notify({
            title: 'Успех',
            message: 'Товар добавлен',
            type: 'success',
          })
          this.$emit('update')
          this.dialogVisible = false
        }).catch(()=>{
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось добавить товар',
            type: 'error',
          })
        })
        this.popupLoading = false
      },

      handleClose() {
        this.dialogVisible = false
      },
    },
  }
</script>

<template>
  <div>
    <el-button
      type="primary"
      :before-close="handleClose"
      :disabled="!canCreateProduct"
      @click="dialogVisible = true">
      Новый товар
    </el-button>
    <el-dialog
      v-model="dialogVisible"
      v-loading="popupLoading"
      width="20%"
      title="Новый товар"
      class="custom-dialog"
      :after-close="clearForm">
      <el-form
        ref="form"
        :model="form"
        label-position="top">
        <el-form-item label="Товар">
          <el-input
            v-model="form.name"
            placeholder="Платье" />
        </el-form-item>
        <el-form-item label="Артикул">
          <el-input
            v-model="form.article_number"
            placeholder="109348" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible=false">Закрыть</el-button>
          <el-button
            type="primary"
            @click="save">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

