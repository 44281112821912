<script setup>
  import ConfirmModal from '@/components/shared/ConfirmModal'
  import { ref } from 'vue'
  import SizesCreate from '@/components/size/SizesCreate'
  import SizesEdit from '@/components/size/SizesEdit'
  import draggable from 'vuedraggable'
  import useTable from '@/components/shared/table/useTable'


  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const modelName = ref('size')
  const modelNamePlural = ref('sizes')

  const {
    handleEdit,
    mainObjects,
    deleteDialogVisible,
    handleConfirmedDelete,
    createDialogVisible,
    create,
    editeDialogVisible,
    editingObject,
    selectedIndex,
    updateObject,
    dragDialogVisible,
    moveElement,
    loadingData,
    message,
  } = useTable(modelName, modelNamePlural)
</script>

<template>
  <confirm-modal
    v-model="deleteDialogVisible"
    :confirm-function="handleConfirmedDelete"
    text="Вы уверены, что хотите удалить размер?" />

  <sizes-create
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Создать размер"
    @create="create" />
  <sizes-edit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    title="Изменить размер"
    @updateObject="updateObject" />

  <div>
    <header>
      <el-row
        justify="space-between"
        class="mb-2">
        <el-col
          :span="24"
          justify="ri">
          <el-row justify="end">
            <el-button
              :disabled="!canEdit"
              type="primary"
              class="inline-block"
              @click="dragDialogVisible = true">
              Перетащить элементы
            </el-button>
            <el-button
              :disabled="!canEdit"
              type="success"
              class="inline-block"
              @click="createDialogVisible = true">
              Создать
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </header>

    <el-dialog
      :model-value="dragDialogVisible"
      :before-close="() => dragDialogVisible = false"
      width="800px"
      title="Отсортировать элементы"
      class="custom-dialog"
      top="10px">
      <draggable
        v-model="mainObjects"
        group="people"
        item-key="id"
        @change="moveElement">
        <template #item="{element}">
          <div class="list-group-item">
            <div
              v-for="(key, index) in ['id', 'name', 'group']"
              :key="index"
              class="sort-cell">
              {{ element[key] }}
            </div>
          </div>
        </template>
      </draggable>
    </el-dialog>

    <div v-if="!loadingData">
      <el-table
        :data="mainObjects"
        class="resource-table">
        <el-table-column
          label="ID"
          prop="id"
          sortable />
        <el-table-column
          label="Название"
          prop="name"
          sortable />
        <el-table-column
          label="Группа"
          prop="size_group_name"
          sortable />
        <el-table-column
          label="Композитный">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-text>{{ scope.row.is_composite ? 'Да' : 'Нет' }}</el-text>
            </el-space>
          </template>
        </el-table-column>

        <el-table-column
          label="Действия"
          fixed="right"
          width="120px">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-col :span="6">
                <el-button
                  :disabled="!canEdit"
                  type="primary"
                  icon="Edit"
                  circle
                  @click="handleEdit(scope.$index, scope.row)" />
              </el-col>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-1 text-danger">
      {{ message }}
    </div>
  </div>
</template>

<style>

</style>
