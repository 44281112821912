import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const marketplacesStore = {
  namespaced: true,
  state: () => ({
    marketplaces: [],
  }),

  getters: {
    marketplaces(state) {
      return state.marketplaces
    },
  },

  mutations: {
    async set(state, data) {
      state.marketplaces = data
    },
  },

  actions: {
    async fetch(context, brand_id = null) {
      let data = []
      let query = ''
      if (brand_id ) {
        query = '?brand_id=' + brand_id
      }
      await request.get('marketplaces'+query).then((res) => {
        data = res.data.marketplaces
      })
      await context.commit('set', data)
    },
  },
}

export class marketplaceStore {
}