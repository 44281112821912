import { createApp } from 'vue'
import App from './App.vue'
import router from './route'
import ElementPlus, { ElNotification } from 'element-plus'
import './assets/style/reset.css'
import './assets/style/styles.scss'
import 'element-plus/dist/index.css'
import { store } from '@/store/store'
import VueEditor from 'vue3-editor'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axiosWithSettings from '@/axiosWithSettings'
import { AxiosResponse } from 'axios'
import { IUser } from '@/types/user.types'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(store)
app.use(ElementPlus)
app.use(VueEditor)

axiosWithSettings.get('/users/me')
  .then((response: AxiosResponse<IUser>): void => {
    store.commit('userStore/setUser', response.data)
  })
  .catch((): void => {
    ElNotification({
      title: '',
      message: 'Пользователь не авторизован!',
      type: 'info',
    })
  })
  .finally(() => {
    app.use(router)
    app.mount('#app')
  })
