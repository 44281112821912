import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const collectionsStore = {
  namespaced: true,
  state: () => ({
    collections: [],
  }),

  getters: {
    collections(state) {
      return state.collections
    },
  },

  mutations: {
    async fetchCollections(state) {
      await request.get('collections').then((res)=>{
        state.collections = res.data.collections
      })
    },
  },

  actions: {
    async fetchCollections(context, id) {
      await context.commit('fetchCollections', id)
    },
  },
}