<template>
  <el-dialog
    :model-value="modelValue"
    width="20%"
    :title="title"
    class="custom-dialog"
    :before-close="handleClose">
    <slot />

    <el-form
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
      label-position="top">
      <el-form-item label="Название">
        <el-input
          v-model="form.name"
          placeholder="Зелёный" />
      </el-form-item>
      <el-form-item label="Значение">
        <el-color-picker v-model="form.value" />
        <span class="color-value">
          {{ form.value }}
        </span>
      </el-form-item>
    </el-form>

    <div v-if="message">
      {{ message }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="save(ruleFormRef)">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue'

  const props = defineProps({
    isCreating: Boolean,
    initialFormData: Object,
    handleSave: Function,
    message: String,
    modelValue: Boolean,
    title: String,
  })

  const form = ref({
    name: null,
    value: null,
  })
  const ruleFormRef = ref()

  const rules = reactive({
    name: [
      { required: true, message: 'Пожалуйста, заполните поле' },
    ],
    value: [
      { required: true, message: 'Пожалуйста, заполните поле' },
    ],
  })

  watch(props, (props) => {
    if (form.value == null) {
      form.value = { ...props.initialFormData }
    }
  })

  onMounted(() => {
    form.value = { ...props.initialFormData }
  })

  const save = async (formEl) => {
    await formEl.validate((valid) => {
      if (valid) {
        props.handleSave(form.value)
      }
    })
  }

  const emit = defineEmits(['close'])

  const handleClose = () => {
    emit('close')
  }

</script>

<style scoped>
.color-value {
  margin-left: 6px;
}
</style>
