import axios from 'axios'

export default class RequestHelper {
  getBaseUrl() {
    return process.env.VUE_APP_CATALOG_API_URL + '/api/v1/'
  }

  /**
     * Performs a request, using base URL
     * @param path
     * @param verb
     * @param data
     * @returns {Promise<*>}
     */
  async handleRequest(path, verb, data = {}) {
    const params = [this.getBaseUrl() + path]
    if (['post', 'patch', 'put'].includes(verb)) {
      params.push(data)
    }

    let result
    try {
      result = await axios[verb](...params)
        .then(function (response) {
          return response
        })
        .catch(function (response) {
          return response
        })
    } catch (error) {
      result = { status: 0 }
    }
    return result
  }

  async get(path) {
    return await this.handleRequest(path, 'get')
  }

  async delete(path) {
    return await this.handleRequest(path, 'delete')
  }

  async post(path, data = {}) {
    return await this.handleRequest(path, 'post', data)
  }

  async patch(path, data = {}) {
    return await this.handleRequest(path, 'patch', data)
  }

  async put(path, data = {}) {
    return await this.handleRequest(path, 'put', data)
  }



}