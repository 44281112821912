import axios from 'axios'

export const colorGroupsStore = {
  namespaced: true,
  state: () => ({
    color_groups: [],
  }),

  getters: {
    colorGroups(state) {
      return state.color_groups
    },
  },

  mutations: {
    async setColorGroups(state, colorGroups) {
      state.color_groups = colorGroups
    },
  },

  actions: {
    async fetchColorGroups(context) {
      await axios.get('color-groups')
        .then(res => {
          context.commit('setColorGroups', res.data.color_groups)
        })
    },
  },
}
