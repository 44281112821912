import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const fitsStore = {
  namespaced: true,
  state: () => ({
    fits: [],
  }),

  getters: {
    fits(state) {
      return state.fits
    },
  },

  mutations: {
    async fetchFits(state) {
      await request.get('fits').then((res)=>{
        if (res.data !== undefined) {
          state.fits = res.data.fits
        }
      })
    },
  },

  actions: {
    async fetchFits(context, id) {
      await context.commit('fetchFits', id)
    },
  },
}