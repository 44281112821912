<template>
  <el-dialog
    :model-value="modelValue"
    width="30%"
    :title="title"
    :before-close="close">
    <span>{{ $props.text }}</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close()">Отменить</el-button>
        <el-button
          type="primary"
          @click="handleConfirmed">
          Подтвердить
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>

  import { ref } from 'vue'

  export default {
    name: 'ConfirmModal',
    props: {
      confirmFunction: Function,
      title: {
        type: String,
        default: 'Нужно подтверждение',
      },
      text: String,
      modelValue: Boolean,
    },
    data() {
      return {
        dialogVisible: ref(false),
      }
    },
    methods: {
      close() {
        this.$emit('update:modelValue', false)
      },
      handleConfirmed() {
        this.$props.confirmFunction()
        this.close()
      },
    },
  }
</script>
