<template>
  <div>
    <header>
      <h1>Главная страница</h1>
    </header>
  </div>
</template>

<script setup>

</script>
