import axios from 'axios'

export const compositionGroupsStore = {
  namespaced: true,
  state: () => ({
    compositionGroups: [],
  }),

  getters: {
    compositionGroups(state) {
      return state.compositionGroups
    },
  },

  mutations: {
    fetchCompositionGroups(state, compositionGroups) {
      state.compositionGroups = compositionGroups
    },
  },

  actions: {
    async fetchCompositionGroups(context) {
      await axios.get('/composition-groups').then((res)=> {
        context.commit('fetchCompositionGroups', res.data.composition_groups)
      })
    },
  },
}