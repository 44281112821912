<script setup>
  import { onMounted, watch } from 'vue'
  import { useStore } from 'vuex'
  import { ref } from 'vue'
  import { ElNotification } from 'element-plus'
  import axios from 'axios'


  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const barcodes = ref(null)
  const readyToShow = ref(false)
  const dataLoaded = ref(false)

  const fillEmptyBarcodeByPlatforms = (colorId, sizesFromStore) => {
    const barcodeByColor = barcodes.value[colorId]
    sizesFromStore.forEach(size => {
      const sizeId = size.id
      if (!barcodeByColor?.[sizeId]) {
        barcodeByColor[sizeId] = { id: 0, barcode: 0 }
      }
    })
  }

  const fillEmptyBarcodesByAllPlatforms = (colorId, sizesFromStore) => {
    barcodes.value[colorId] = {}
    sizesFromStore.forEach( size => {
      barcodes.value[colorId][size.id] = { id: 0, barcode: 0 }
    })
  }

  const fillEmptyBarcodes = () => {
    const colorFromStore = store.getters['productStore/colors']
    const sizesFromStore = store.getters['productStore/sizes']
    if (!colorFromStore || !sizesFromStore || !dataLoaded.value) {
      return true
    }
    colorFromStore.forEach( color => {
      const colorId = color.id
      if (!barcodes.value?.[colorId]) {
        fillEmptyBarcodesByAllPlatforms(colorId, sizesFromStore)
      } else {
        fillEmptyBarcodeByPlatforms(colorId, sizesFromStore)
      }
    })
    readyToShow.value = true
  }

  const loadData = async () => {
    const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/'
      + 'products/' + store.getters['productStore/product'].id + '/barcodes'

    try {
      const response = await axios.get(url)
      barcodes.value= response.data.products_barcodes
      dataLoaded.value = true
      fillEmptyBarcodes()
    } catch (e) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось загрузить штрих коды',
      })
    }
  }

  const save = async (colorId, sizeId, value) => {
    const barcode = barcodes.value[colorId][sizeId]
    barcode.barcode = value
    const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/' +
      'products/' + store.getters['productStore/product'].id + '/barcode'

    try {
      if (barcode.id) {
        await axios.patch(url, barcode)
      } else {
        barcode.size_id = sizeId
        barcode.color_id = colorId
        await axios.post(url, barcode)
        ElNotification({
          title: 'Сохранено',
          type: 'info',
          message: 'Штрих-код обновлён',
        })
      }
    } catch (e) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось обновить штрих-код',
      })
    }
  }

  onMounted(() => {
    if (store.getters['productStore/product'].id) {
      loadData()
    }
  })

  watch(() => store.getters['productStore/product'], function() {
    loadData()
  })

  watch(() => store.getters['productStore/colors'], function() {
    fillEmptyBarcodes()
  })

  watch(() => store.getters['productStore/sizes'], function() {
    fillEmptyBarcodes()
  })
</script>

<template>
  <div
    v-if="readyToShow"
    class="main_content">
    <el-row
      :gutter="20"
      class="sizes">
      <el-col
        v-for="size in store.getters['productStore/sizes']"
        :key="size.id"
        :span="4">
        <div class="grid-content ep-bg-purple" />
        <div class="size-name flex-center">
          {{ size.name }}
        </div>
      </el-col>
    </el-row>
    <el-row
      v-for="color in store.getters['productStore/colors']"
      :key="color.id"
      :gutter="20">
      <el-col
        v-for="size in store.getters['productStore/sizes']"
        :key="size.id"
        :span="4">
        <div class="grid-content ep-bg-purple" />
        <div class="flex-center">
          <el-input-number
            v-model="barcodes[color.id][size.id].barcode"
            :disabled="canEdit"
            :controls="false"
            type="text"
            :min="0"
            @change="(value) => save(color.id, size.id, value)" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.main_content {
  width: 100%;
}

.size-name {
  font-size: 2rem;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizes {
  margin-bottom: 36px;
}
</style>