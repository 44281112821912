<script setup>
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import { ElNotification } from 'element-plus'
  import axios from 'axios'

  const store = useStore()
  const selectedColorId = computed(() => {
    return store.getters['productStore/selectedColor']
  })
  const loading = ref(false)

  const selectedColorIsNew = computed(() => {

    return store.getters['productStore/product']?.new_section_colors
      ?.find(id => id === selectedColorId.value) >= 0
  })

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })


  const attachNew = async () => {
    loading.value = true

    try {
      await axios.put('products/new/attach', {
        products: [
          {
            product_id: store.getters['productStore/product'].id,
            color_id: selectedColorId.value,
            position: 0,
          },
        ],
      })

      store.getters['productStore/product'].new_section_colors.push(selectedColorId.value)

      ElNotification({
        message: 'Товар добавлен в новинки',
        type: 'success',
      })
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        message: error?.response?.data?.message,
        type: 'error',
      })
    } finally {
      loading.value = false
    }
  }

  const removeNew = async () => {
    loading.value = true
    try {
      await axios.put('products/new/remove', {
        product_id: store.getters['productStore/product'].id,
        color_id: selectedColorId.value,
      })

      const removedIndex = store.getters['productStore/product'].new_section_colors.indexOf(selectedColorId.value)
      if (removedIndex !== -1) {
        store.getters['productStore/product'].new_section_colors.splice(removedIndex, 1)
      }

      ElNotification({
        message: 'Товар удалён из новинок',
        type: 'success',
      })
    } catch (error) {
      ElNotification({
        title: 'Error',
        message: error?.response?.data?.message,
        type: 'error',
      })
    } finally {
      loading.value = false
    }
  }
</script>

<template>
  <div>
    <el-switch
      :disabled="!canEdit"
      :active-value="true"
      :inactive-value="false"
      :loading="loading"
      :model-value="selectedColorIsNew"
      size="large"
      @click="selectedColorIsNew ? removeNew() : attachNew()" />
    <strong>Новинка</strong>
  </div>
</template>

<style scoped>
.el-switch {
  margin-right: 20px;
}
</style>
