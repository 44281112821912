import axios from 'axios'

export const productSalePlatformsStore = {
  namespaced: true,
  state: () => ({

  }),
  getters: {

  },
  mutations: {

  },
  actions: {
    async updateAllColorsActive(context, data) {
      await axios.put(`/products/${data.id}/sale-platforms/active/set-for-all-colors`, data.data)
    },
    async updateColorsActive(context, data) {
      await axios.put(`/products/${data.id}/sale-platforms/active`, data.data)
    },
  },
}