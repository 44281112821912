<script setup>
  import { useStore } from 'vuex'
  import { ref, computed } from 'vue'
  import axios from 'axios'
  import { ElNotification } from 'element-plus'


  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  store.dispatch('collectionsStore/fetchCollections')

  const dialog = ref(false)

  const selectedCollection = ref(null)

  const selectedColl = computed({
    get() {
      return selectedCollection.value
    },
    set(value) {
      selectedCollection.value = value
    },
  })

  const productCollection = computed({
    get() {
      return store.getters['productStore/collections']
        ? store.getters['productStore/collections']
        : []
    },
    set(collections) {
      store.commit('productStore/updateCollections', collections)
    },
  })

  const collections = computed({
    get() {
      const selectedIds = productCollection.value.map((collection) => collection.id)
      const collections = store.getters['collectionsStore/collections']
      return collections.map((collection) => {
        collection.disabled = selectedIds.includes(collection.id)
        return collection
      })
    },
  })

  function closeDialog() {
    selectedColl.value = { id: null, name: null, year: null }
  }

  async function attachCollection() {
    const newCollection = productCollection.value

    const addedCollection = collections.value.find(
      (collection) => collection.id == selectedColl.value,
    )
    const isCollectionExists = productCollection.value.find(
      (collection) => collection.id == selectedColl.value,
    )
    if (isCollectionExists !== undefined) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: 'Коллекция уже прикреплена к продукту',
      })
      return
    }
    try {
      newCollection.push(addedCollection)
      productCollection.value = newCollection
      await axios.put(
        process.env.VUE_APP_CATALOG_API_URL +
          `/api/v1/products/${store.getters['productStore/product'].id}/attach/collection`,
        {
          collection_id: selectedCollection.value,
        },
      )

      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Коллекция добавлена',
      })
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: error.response.data.message,
      })
    }
    dialog.value = false
    selectedCollection.value = { id: null, name: null, year: null }
  }

  async function detachCollection(id) {
    const collection = store.getters['productStore/collections'].find(
      (collection) => collection.id == id,
    )
    if (collection == null) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: 'Коллекция уже удалена',
      })
      return
    }
    try {
      await axios.put(
        process.env.VUE_APP_CATALOG_API_URL +
          `/api/v1/products/${store.getters['productStore/product'].id}/detach/collection`,
        {
          collection_id: id,
        },
      )
      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Коллекция удалена',
      })
      const newCollection = productCollection.value.filter(
        (collection) => collection.id != id,
      )
      productCollection.value = newCollection
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: error.response.data.message,
      })
    }
    dialog.value = false
  }
</script>

<template>
  <h3>Коллекция</h3>
  <div>
    <el-row :gutter="20">
      <el-col
        v-for="collection in store.getters['productStore/collections']"
        :key="collection.id"
        class="wrapper"
        :span="5">
        <el-col>
          <span :offset="1">{{ collection.type + " " + collection.year }}</span>
          <el-button
            type="danger"
            size="small"
            icon="Delete"
            circle
            @click="detachCollection(collection.id)" />
        </el-col>
      </el-col>
    </el-row>

    <el-button
      class="mt-4 mb-2 mt-2"
      :disabled="!canEdit"
      type="primary"
      plain
      style="width: 100%"
      @click="dialog = true">
      Добавить коллекцию
    </el-button>

    <el-dialog
      v-model="dialog"
      title="Добавить коллекцию"
      @close="closeDialog">
      <div class="dialog_body">
        <div class="size_input">
          <span>Коллекция</span>
          <el-select
            v-model="selectedCollection"
            placeholder="Выберите колллекцию">
            <el-option
              v-for="collection in collections"
              :key="collection.id"
              :label="collection.type + ' ' + collection.year"
              :value="collection.id"
              :disabled="collection.disabled" />
          </el-select>
        </div>
      </div>
      <template #footer>
        <el-button
          type="primary"
          @click="attachCollection">
          Сохранить
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.wrapper span {
  padding-right: 10px;
}

.dialog_body {
  display: flex;
  align-items: center;

  .size_input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 0;

    span {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .el-input {
      width: 70px;
    }

    &:first-child {
      span {
        text-transform: none;
      }
    }
  }
}
</style>
