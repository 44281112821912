<template>
  <ConfirmModal
    v-model="deleteDialogVisible"
    :confirm-function="handleConfirmedDelete"
    text="Вы уверены, что хотите удалить обмер?" />
  <MeasurementsCreate
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Создать обмер"
    @create="create" />
  <MeasurementsEdit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    title="Изменить обмер"
    @updateObject="updateObject" />

  <div>
    <header>
      <el-row
        justify="space-between"
        class="mb-2">
        <el-col :span="6">
          <h1>Список обмеров</h1>
        </el-col>
        <el-col
          :span="12"
          justify="ri">
          <el-row justify="end">
            <el-button
              :disabled="!canEditDictionaries"
              type="primary"
              class="inline-block"
              @click="dragDialogVisible = true">
              Перетащить элементы
            </el-button>
            <el-button
              :disabled="!canEditDictionaries"
              type="success"
              class="inline-block"
              @click="createDialogVisible = true">
              Создать
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </header>

    <el-dialog
      v-if="!loadingData"
      :model-value="dragDialogVisible"
      :before-close="() => dragDialogVisible = false"
      width="800px"
      title="Отсортировать элементы"
      class="custom-dialog"
      top="10px">
      <draggable
        v-model="mainObjects"
        group="people"
        item-key="id"
        @change="moveElement">
        <template #item="{element}">
          <div class="list-group-item">
            <div
              v-for="(key, index) in ['id', 'name']"
              :key="index"
              class="sort-cell">
              {{ element[key] }}
            </div>
          </div>
        </template>
      </draggable>
    </el-dialog>

    <div v-if="!loadingData">
      <el-table
        :data="mainObjects"
        class="resource-table">
        <el-table-column
          label="ID"
          prop="id"
          sortable />
        <el-table-column
          label="Название"
          prop="name"
          sortable />

        <el-table-column
          label="Действия"
          fixed="right"
          width="120px">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-col :span="6">
                <el-button
                  :disabled="!canEditDictionaries"
                  type="primary"
                  icon="Edit"
                  circle
                  @click="handleEdit(scope.$index, scope.row)" />
              </el-col>
              <el-col :span="6">
                <el-button
                  :disabled="!canEditDictionaries"
                  type="danger"
                  icon="Delete"
                  circle
                  @click="handleDelete(scope.$index, scope.row)" />
              </el-col>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-1 text-danger">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import ConfirmModal from '@/components/shared/ConfirmModal'
  import { computed, ref } from 'vue'
  import useTable from '@/components/shared/table/useTable'
  import MeasurementsCreate from '@/components/measurement/MeasurementsCreate'
  import MeasurementsEdit from '@/components/measurement/MeasurementsEdit'

  import {
    Delete,
    Edit,
  } from '@element-plus/icons-vue'
  import draggable from 'vuedraggable'
  import { useStore } from 'vuex'

  export default {
    name: 'MeasurementsPage',
    components: { ConfirmModal, MeasurementsCreate, MeasurementsEdit, draggable },
    setup() {
      const store = useStore()
      const modelName = ref('measurement')
      const modelNamePlural = ref('measurements')

      const tableHelper = useTable(modelName, modelNamePlural)

      const canEditDictionaries = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit dictionaries').length >= 1)

      return {
        ...tableHelper,
        Delete,
        Edit,
        modelNamePlural,
        canEditDictionaries,
      }
    },
  }
</script>

<style>

</style>
