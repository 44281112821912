export interface IProductPrices {
  id: number;
  article_number: string
  name: string;
  image_id: number|null;
  price: number;
  updated_at: string;
  colors: IProductColor[];
  scheduled_price: IScheduledProductPrice|null;
  is_low_price: boolean;
}

export const defaultProductPrices: IProductPrices = {
  id: 0,
  article_number: '',
  name: '',
  image_id: null,
  price: 0,
  updated_at: '',
  colors: [],
  scheduled_price: null,
  is_low_price: false,
}

export interface IProductColor {
  id: number;
  name: string;
  value: string;
  price: number;
  updated_at: string;
  image_id: number|null;
  is_low_price: boolean;
}

export interface IScheduledProductPrice {
  price: number;
  started_at: string;
  user_name: string
}