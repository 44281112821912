<script setup>

  import SizesForm from '@/components/size/SizesForm'

  const emit = defineEmits(['updateObject', 'update:modelValue'])
  const props = defineProps({
    formData: Object,
    updateObject: Function,
    index: Number,
    modelValue: Boolean,
    title: String,
  })

  const handleSave = async (form) => {
    emit('updateObject', props.index, form)
  }
</script>

<template>
  <div
    v-if="formData"
    class="size-edit">
    <sizes-form
      :initial-form-data="formData"
      :handle-save="handleSave"
      :is-creating="false"
      :model-value="modelValue"
      :title="title"
      @close="$emit('update:modelValue', false)">
      <div class="title">
        {{ formData.name }}
      </div>
    </sizes-form>
  </div>
</template>

<style scoped>

</style>