import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const photoModelsStore = {
  namespaced: true,
  state: () => ({
    photoModels: [],
  }),

  getters: {
    photoModels(state) {
      return state.photoModels
    },
  },

  mutations: {
    async fetchPhotoModels(state, photo_models) {
      state.photoModels = photo_models
    },
  },

  actions: {
    async fetchPhotoModels(context) {
      await request.get('photo-models').then((res) => {
        context.commit('fetchPhotoModels', res.data.photo_models)
      })
    },
  },
}