<template>
  <ConfirmModal
    v-model="deleteDialogVisible"
    :confirm-function="handleConfirmedDelete"
    text="Вы уверены, что хотите удалить вид производства?" />
  <ManufactureTypeCreate
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Создать вид производства"
    @create="create" />
  <ManufactureTypeEdit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    title="Изменить вид производства"
    @updateObject="updateObject" />

  <header>
    <el-row
      justify="space-between"
      class="mb-2">
      <el-col :span="6">
        <h1>Список видов производств</h1>
      </el-col>
      <el-col
        :span="12"
        justify="ri">
        <el-row justify="end">
          <el-button
            :disabled="!canEditProductionDictionaries"
            type="success"
            class="inline-block"
            @click="createDialogVisible = true">
            Создать
          </el-button>
        </el-row>
      </el-col>
    </el-row>
  </header>

  <el-dialog
    v-if="!loadingData"
    :model-value="dragDialogVisible"
    :before-close="() => (dragDialogVisible = false)"
    width="800px"
    title="Отсортировать элементы"
    class="custom-dialog"
    top="10px">
    <draggable
      v-model="mainObjects"
      group="people"
      item-key="id"
      @change="moveElement">
      <template #item="{ element }">
        <div class="list-group-item">
          <div
            v-for="(key, index) in ['id', 'name']"
            :key="index"
            class="sort-cell">
            {{ element[key] }}
          </div>
        </div>
      </template>
    </draggable>
  </el-dialog>

  <div v-if="!loadingData">
    <el-table
      :data="mainObjects"
      class="resource-table">
      <el-table-column
        label="ID"
        prop="id"
        sortable />
      <el-table-column
        label="Название"
        prop="name"
        sortable />

      <el-table-column
        label="Действия"
        fixed="right"
        width="120px">
        <template #default="scope">
          <el-space
            wrap
            size="large">
            <el-col :span="6">
              <el-button
                :disabled="!canEditProductionDictionaries"
                type="primary"
                icon="Edit"
                circle
                @click="handleEdit(scope.$index, scope.row)" />
            </el-col>
            <el-col :span="6">
              <el-button
                :disabled="!canEditProductionDictionaries"
                type="danger"
                icon="Delete"
                circle
                @click="handleDelete(scope.$index, scope.row)" />
            </el-col>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="mt-1 text-danger">
    {{ message }}
  </div>
</template>

<script>
  import ConfirmModal from '@/components/shared/ConfirmModal'
  import { computed, ref } from 'vue'
  import useTable from '@/components/shared/table/useTable'
  import ManufactureTypeCreate from '@/components/manufacture_type/ManufactureTypeCreate'
  import ManufactureTypeEdit from '@/components/manufacture_type/ManufactureTypeEdit'

  import { Delete, Edit } from '@element-plus/icons-vue'
  import { useStore } from 'vuex'

  export default {
    name: 'ManufactureGroups',
    components: { ConfirmModal, ManufactureTypeCreate, ManufactureTypeEdit },
    setup() {
      const store = useStore()
      const modelName = ref('manufacture_type')
      const modelNamePlural = ref('manufacture-types')

      const tableHelper = useTable(modelName, modelNamePlural)

      const canEditProductionDictionaries = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit production dictionaries').length >= 1)

      return {
        ...tableHelper,
        Delete,
        Edit,
        modelNamePlural,
        canEditProductionDictionaries,
      }
    },
  }
</script>

<style></style>
