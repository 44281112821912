import axios from 'axios'
import { ElNotification } from 'element-plus'

export const imagesStore = {
  namespaced: true,
  state: () => ({
    status: false,
  }),
  getters: {
  },
  mutations: {
    setImgStatus(state, payload) {
      state.status = payload
    },
  },
  actions: {
    async addImage({ commit }, payload) {
      commit('setImgStatus', true)
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      const data = new FormData()
      for (const file of payload.images) {
        data.append('images[]', file)
      }
      data.append('color_id', payload.color_id)
      data.append('product_id', payload.product_id)
      data.append('platform_id', payload.platform_id)
      data.append('platform_type', payload.platform_type.toLowerCase())
      data.append('group', payload.group)
      await axios.post('/images', data, config)
        .then(res => {
          commit('setImgStatus', false)
          return res
        })
        .catch(err => {
          commit('setImgStatus', false)
          throw new Error(err.response.data.message)
        })
    },
    async deleteImage({ commit }, id) {
      commit('setImgStatus', true)
      await axios.delete(`/images/${id}` )
        .then(res => {
          commit('setImgStatus', false)
          return res
        })
        .catch(err => {
          commit('setImgStatus', false)
          ElNotification({
            title: 'Error',
            message: 'Ошибка удаления',
            type: 'error',
          })
          throw new Error(err)
        })
    },
    async duplicateImages({ commit }, data) {
      commit('setImgStatus', true)
      await axios.post('/images/duplicate', data)
        .then(res => {
          commit('setImgStatus', false)
          return res
        })
        .catch(err => {
          commit('setImgStatus', false)
          ElNotification({
            title: 'Error',
            message: err.response.data.images,
            type: 'error',
          })
          throw new Error(err)
        })
    },
    async reorderImage({ commit }, params) {
      commit('setImgStatus', true)
      await axios.put(`/images/${params.id}/reorder`, { position: params.position })
        .then(res => {
          commit('setImgStatus', false)
          return res
        })
        .catch(err => {
          ElNotification({
            title: 'Error',
            message: err.response.data.images,
            type: 'error',
          })
          commit('setImgStatus', false)
          throw new Error(err)
        })
    },
  },
}