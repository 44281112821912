import { ElNotification } from 'element-plus'
import { IPermission } from '@/types/roleAndPermission.types'

export default function checkPermissions({ to, next, store, router }): void {
  const permissions: string[] = to.meta.permissions

  permissions.forEach((permission: string): void => {
    const userPermissions: IPermission[] = store.getters['userStore/getPermissions']

    if (userPermissions.filter((e: IPermission): boolean => e.name === permission).length < 1) {
      ElNotification({
        title: '',
        message: 'Нет прав!',
        type: 'info',
      })
      return router.push({ name: 'home' })
    }
  })

  next()
  return
}