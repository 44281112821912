<template>
  <PhotoModelsCreate
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Добавить фотомодель"
    @create="create" />
  <PhotoModelsEdit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    title="Изменить данные фотомодели"
    @updateObject="updateObject" />
  <div>
    <header>
      <el-row
        justify="space-between"
        class="mb-2">
        <el-col :span="6">
          <h1>Список фотомоделей</h1>
        </el-col>
        <el-col
          :span="3"
          justify="ri">
          <el-row justify="end">
            <el-button
              :disabled="!canEditDictionaries"
              type="success"
              fixed="right"
              @click="createDialogVisible = true">
              Создать
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </header>

    <div v-if="!loadingData">
      <el-table
        :data="mainObjects"
        class="resource-table">
        <el-table-column
          label="ID"
          prop="id"
          sortable />
        <el-table-column
          label="Имя"
          prop="name"
          sortable />
        <el-table-column
          label="Высота (см.)"
          prop="height"
          sortable />
        <el-table-column
          label="Вес (кг.)"
          prop="weight"
          sortable />
        <el-table-column
          label="Талия (см.)"
          prop="waist"
          sortable />
        <el-table-column
          label="Грудь (см.)"
          prop="chest"
          sortable />
        <el-table-column
          label="Бёдра (см.)"
          prop="hips"
          sortable />

        <el-table-column
          label="Действия"
          fixed="right"
          width="120px">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-col :span="6">
                <el-button
                  :disabled="!canEditDictionaries"
                  type="primary"
                  icon="Edit"
                  circle
                  @click="handleEdit(scope.$index, scope.row)" />
              </el-col>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-1 text-danger">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import useTable from '@/components/shared/table/useTable'
  import PhotoModelsCreate from '@/components/photo_model/PhotoModelsCreate'
  import PhotoModelsEdit from '@/components/photo_model/PhotoModelsEdit'

  import {
    Edit,
  } from '@element-plus/icons-vue'
  import { useStore } from 'vuex'

  export default {
    name: 'PhotoModelsPage',
    components: { PhotoModelsCreate, PhotoModelsEdit },
    setup() {
      const store = useStore()
      const modelName = ref('photo_model')
      const modelNamePlural = ref('photo-models')

      const tableHelper = useTable(modelName, modelNamePlural)

      const canEditDictionaries = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit dictionaries').length >= 1)

      return {
        ...tableHelper,
        Edit,
        modelNamePlural,
        canEditDictionaries,
      }
    },
  }
</script>

<style>

</style>
