<script>
  import { onMounted, ref, watch } from 'vue'
  import useTable from '@/components/shared/table/useTable'
  import { useRouter, useRoute } from 'vue-router'
  import ImageHelper from '@/helpers/ImageHelper'
  import NewDialog from '@/components/product/NewDialog'

  import {
    Delete,
    Edit,
    Search,
  } from '@element-plus/icons-vue'
  import { ElNotification } from 'element-plus'
  import { useStore } from 'vuex'
  import axios from 'axios'
  import { sortByField } from '@/composables/sortByField'
  import { tablePagination } from '@/composables/tablePagination'


  export default {
    name: 'ProductsPage',
    components: {
      NewDialog,
    },
    setup() {
      const modelName = ref('product')
      const modelNamePlural = ref('products')
      const router = useRouter()
      const route = useRoute()
      const categories = ref([])
      const selectedCategory = ref(null)
      const activityFilter = ref(null)
      const imageHelper = new ImageHelper()
      const loading = ref(false)

      const extraData = {
        loadOnMounted: false,
      }

      const store = useStore()

      const tableHelper = useTable(modelName, modelNamePlural, extraData)
      const {
        sortBy,
        changeSortDirection,
        addSortToQueryParams,
      } = sortByField()
      const {
        pageSize: paginationPageSize,
        currentPage,
        addPaginationToQueryParams,
      } = tablePagination()

      let loadOnMounted = false

      if (store.getters['main/currentBrand']) {
        loadOnMounted = true
      }

      watch(() => store.getters['main/currentBrand'], function () {
        sortChange(defaultSort)
        loadCategories()
      })

      const searchInput = ref('')
      watch(searchInput, () => {
        tableHelper.mainObjects.value = []
        currentPage.value = 1

        const prevSearchInput = searchInput.value

        setTimeout(() => {
          if (prevSearchInput === searchInput.value) {
            loadData()
          }
        }, 500)
      })

      const loadData = async () => {
        loading.value = true

        const searchParams = new URLSearchParams('')
        addPaginationToQueryParams(searchParams)
        searchParams.set('brand_id', store.getters['main/currentBrand'].id)

        if (searchInput.value !== '') {
          searchParams.set('filter[search]', searchInput.value)
        }

        if (selectedCategory.value) {
          searchParams.set('filter[category_id]', selectedCategory.value)
        }

        if (activityFilter.value) {
          const enabled = activityFilter.value == 1 ? 1 : 0
          searchParams.set('filter[enabled]', enabled)
        }

        if (sortBy.value !== '') {
          addSortToQueryParams(searchParams, sortBy.value)
        }

        const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/' +
          'products/catalog/list?' + searchParams
        try {
          await axios.get(url).then((response) => {
            tableHelper.mainObjects.value = response.data.data
            tableHelper.total.value = response.data.meta.total

          }).finally(() => {
            loading.value = false
          })
        } catch (exception) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось загрузить данные',
            type: 'error',
          })
        }
      }

      const setPage = (page) => {
        router.replace({ path: route.path, query: { page } })
        currentPage.value = parseInt(page)
        scrollTableToTop()
        loadData(page)
      }

      const defaultSort = { prop: 'created_at', order: 'descending' }

      onMounted(() => {
        const page = route.query.page ? route.query.page : 1
        currentPage.value = parseInt(page)
        if (loadOnMounted) {
          loadData(page)
          loadCategories()
        }
      })

      const multipleTableRef = ref()
      const toggleSelection = (rows) => {
        if (rows) {
          rows.forEach((row) => {
            multipleTableRef.value.toggleRowSelection(row, undefined)
          })
        } else {
          multipleTableRef.value.clearSelection()
        }
      }

      const activityLoading = ref(false)

      const loadCategories = async () => {
        const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/' +
          'categories?per_page=9999&brand_id=' + store.getters['main/currentBrand'].id
        try {
          const response = await axios.get(url)
          categories.value = response.data.categories
        } catch (e) {
          ElNotification({
            title: 'Ошибка',
            message: 'Не удалось загрузить категории',
            type: 'error',
          })
        }
      }

      const toProductCard = (data) => {
        const link = document.createElement('a')
        link.href = '/products/' + data.id
        link.target = '_blank'
        link.click()
      }

      function scrollTableToTop() {
        multipleTableRef.value.setScrollTop(0)
      }

      const sortChange = (sortField) => {
        changeSortDirection(sortField)
        loadData()
      }

      return {
        ...tableHelper,
        loadData,
        setPage,
        categories,
        selectedCategory,
        toggleSelection,
        currentPage,
        Delete,
        Edit,
        Search,
        modelNamePlural,
        searchInput,
        activityFilter,
        imageHelper,
        route,
        loading,
        toProductCard,
        multipleTableRef,
        activityLoading,
        sortChange,
        paginationPageSize,
        defaultSort,
      }
    },
  }
</script>


<template>
  <div>
    <header>
      <el-row
        type="flex"
        justify="space-between"
        class="mb-2">
        <el-col :span="6">
          <h1>Список товаров</h1>
        </el-col>
        <el-col :span="2">
          <NewDialog @update="loadData(route.query.page ? route.query.page : 1)" />
        </el-col>
      </el-row>
    </header>

    <el-row>
      <div class="mt-4 mr-2 search-input">
        <el-input
          v-model="searchInput"
          placeholder="Артикул, название, штрих-код"
          class="input-with-select"
          clearable>
          <template #append>
            <el-button
              icon="Search"
              @click="loadData(1)" />
          </template>
        </el-input>
      </div>
      <el-form-item
        label="Категория на сайте"
        class="mr-2">
        <el-tree-select
          v-model="selectedCategory"
          :data="categories"
          :render-after-expand="false"
          :default-expand-all="true"
          :check-strictly="true"
          :expand-on-click-node="false"
          node-key="id"
          clearable
          placeholder="Выбрать"
          @change="loadData(1)" />
      </el-form-item>
      <el-form-item label="Активность">
        <el-select
          v-model="activityFilter"
          clearable
          placeholder="Выбрать"
          @change="loadData(1)">
          <el-option
            label="Активен"
            value="1" />
          <el-option
            label="Не активен"
            value="2" />
        </el-select>
      </el-form-item>
    </el-row>
    <div>
      <el-table
        ref="multipleTableRef"
        v-loading="loading"
        :data="mainObjects"
        class="resource-table paginated row-pointer"
        :default-sort="defaultSort"
        @row-click="toProductCard"
        @sort-change="sortChange">
        <el-table-column
          label="ID"
          prop="id"
          width="100px" />
        <el-table-column
          label="Артикул"
          prop="article_number"
          sortable="custom" />
        <el-table-column
          label="Фото"
          align="center">
          <template #default="scope">
            <img
              v-if="scope.row.image"
              :src="imageHelper.getUrl(scope.row.image.id)"
              width="40"
              height="60"
              class="product_image"
              alt="">
          </template>
        </el-table-column>
        <el-table-column
          label="Товар"
          prop="name" />
        <el-table-column
          label="Цвет"
          align="center">
          <template #default="scope">
            <div
              v-for="(value, id) in scope.row.colors"
              :key="id"
              :style="{ background: value.pivot.color ? value.pivot.color : value.value }"
              class="color-square" />
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="РЦ"
          sortable="custom">
          <template #default="scope">
            <div v-if="scope.row.prices.same">
              {{ scope.row.prices.min }} <span v-if="scope.row.prices.min">₽</span>
            </div>
            <div v-else>
              {{ scope.row.prices.min }} - {{ scope.row.prices.max }} ₽
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Дата создания"
          prop="created_at"
          sortable="custom" />
      </el-table>
      <el-pagination
        v-model:page-size="paginationPageSize"
        layout="prev, pager, next"
        :total="total"
        :current-page="currentPage"
        @current-change="setPage" />
    </div>
    <div class="mt-1 text-danger">
      {{ message }}
    </div>
  </div>
</template>

<style scoped>
.product_image {
  max-height: 65px;
}

.search-input {
  width: 300px;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
