import axios from 'axios'
import { ElNotification } from 'element-plus'

export const manufactureGroupsStore = {
  namespaced: true,
  state: () => ({
    manufactureGroups: [],
  }),

  getters: {
    manufactureGroups(state) {
      return state.manufactureGroups
    },
  },

  mutations: {
    fetchManufactureGroups(state, manufactureGroups) {
      state.manufactureGroups = manufactureGroups
    },
  },

  actions: {
    async fetchManufactureGroups(context) {
      await axios.get('/manufacture-groups')
        .then(res => {
          context.commit('fetchManufactureGroups', res.data.manufacture_groups)
          return res
        })
        .catch(err => {
          ElNotification({
            title: 'Error',
            message: err.response.data.images,
            type: 'error',
          })
          throw new Error(err.response.data.message)
        })
    },
        
  },
}