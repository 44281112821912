<script setup>
  import { useStore } from 'vuex'
  import { computed, ref, watch } from 'vue'
  import { ElNotification } from 'element-plus'
  import axios from 'axios'
  import { useRoute } from 'vue-router'
  import ProductManufactureCare from '@/components/product/content/ProductManufactureCare'

  const route = useRoute()
  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const formData = ref({
    group: {
      id: null,
      name: null,
    },
    category: {
      id: null,
      name: null,
    },
    manufacture_type: {
      id: null,
      name: null,
    },
    class_warm: null,
    weight: null,
    box_width: null,
    box_height: null,
    box_length: null,
    is_refundable: false,
    cares: [],
  })

  const selectedManufactureCompositions = computed({
    get() {
      return store.getters['productStore/manufactureCompositions']
    },
    set(compositionIds) {
      store.commit('productStore/updateManufactureCompositions', compositionIds)
    },
  })

  const compositions = computed({
    get() {
      const selectedIds = selectedManufactureCompositions.value.map((item) => item.id)
      const compositionCollection = store.getters['compositionsStore/compositions']
      return compositionCollection.map((composition) => {
        composition.disabled = selectedIds.includes(composition.id)
        return composition
      })
    },
  })

  async function saveCompositions() {
    const compositions = []

    selectedManufactureCompositions.value.forEach((item) => {
      compositions.push({
        composition_id: item.id,
        value: item.value,
      })
    })

    try {
      await axios.put(`products/${route.params.id}/attach/manufacture/compositions`, {
        compositions: compositions,
      })

      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Данные о производственных материалах товара обновлены',
      })
      dialog.value = false
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: error.response.data.message,
      })
    }
    await store.dispatch('productStore/fetchProduct', { id: route.params.id })
  }

  const dialog = ref(false)

  function closeDialog() {
    dialog.value = false
  }

  async function addComposition() {
    selectedManufactureCompositions.value.push({
      id: null,
      name: null,
      name_i18n: { en: null, ru: null },
      value: null,
    })
  }

  async function detachComposition(index) {
    selectedManufactureCompositions.value.splice(index, 1)
  }

  watch(
    () => store.getters['productStore/manufacture'],
    function (manufacture) {
      formData.value.group.id = manufacture?.group?.id
      formData.value.category.id = manufacture?.category?.id
      formData.value.manufacture_type.id = manufacture?.manufacture_type?.id
      formData.value.class_warm = manufacture?.class_warm
      formData.value.weight = manufacture?.weight
      formData.value.box_width = manufacture?.box_width
      formData.value.box_height = manufacture?.box_height
      formData.value.box_length = manufacture?.box_length
      formData.value.is_refundable = manufacture?.is_refundable
      formData.value.compositions = manufacture?.compositions
      formData.value.cares = manufacture?.cares
    },
  )

  async function save(data) {
    const manufactureData = {
      manufacture_group_id: formData.value.group.id,
      manufacture_category_id: formData.value.category.id,
      manufacture_type_id: formData.value.manufacture_type.id,
      class_warm: formData.value.class_warm,
      weight: formData.value.weight,
      box_width: formData.value.box_width,
      box_height: formData.value.box_height,
      box_length: formData.value.box_length,
      is_refundable: formData.value.is_refundable,
      compositions: formData.value.compositions,
    }
    try {
      await axios.put(`products/${route.params.id}/manufacture`, manufactureData)

      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Производственные свойства обновлены',
      })

      manufactureData.cares = formData.value.cares
      store.commit('productStore/updateManufacture', formData)

      dialog.value = false
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: error.response.data.message,
      })
    }
  }
</script>

<template>
  <div>
    <h3>Производство</h3>
    <ul>
      <li>
        <h4>Группа:</h4>
        <el-select
          v-model="formData.group.id"
          :disabled="!canEdit"
          placeholder="Выберите группу"
          default-expand-all
          check-strictly
          @change="save($event)">
          <el-option
            v-for="group in store.getters['manufactureGroupsStore/manufactureGroups']"
            :key="group.id"
            :value="group.id"
            :label="group.name">
            {{ group.name }}
          </el-option>
        </el-select>
      </li>
      <li>
        <h4>Категория:</h4>
        <el-select
          v-model="formData.category.id"
          :disabled="!canEdit"
          placeholder="Выберите категорию"
          default-expand-all
          check-strictly
          @change="save($event)">
          <el-option
            v-for="category in store.getters[
              'manufactureCategoriesStore/manufactureCategories'
            ]"
            :key="category.id"
            :value="category.id"
            :label="category.name">
            {{ category.name }}
          </el-option>
        </el-select>
      </li>
      <li>
        <h4>Вид производства:</h4>
        <el-select
          v-model="formData.manufacture_type.id"
          :disabled="!canEdit"
          placeholder="Выберите вид производства"
          default-expand-all
          check-strictly
          @change="save($event)">
          <el-option
            v-for="manufactureType in store.getters[
              'manufactureTypesStore/manufactureTypes'
            ]"
            :key="manufactureType.id"
            :value="manufactureType.id"
            :label="manufactureType.name">
            {{ manufactureType.name }}
          </el-option>
        </el-select>
      </li>
      <li>
        <h4>Состав:</h4>

        <el-tag
          v-for="composition in selectedManufactureCompositions"
          :key="composition.id"
          class="mr-1"
          size="large"
          type="info">
          {{ composition.name + " " + composition.value + "%" }}
        </el-tag>
        <el-button
          :disabled="!canEdit"
          class="button-new-tag ml-1"
          @click="dialog = true">
          + Редактировать состав
        </el-button>
      </li>
      <li>
        <h4>Класс тепла:</h4>
        <span class="price">
          <el-input
            v-model="formData.class_warm"
            :disabled="!canEdit"
            placeholder="Введите класс тепла"
            @change="save($event)" />
        </span>
      </li>
      <li>
        <h4>Вес изделия (g):</h4>
        <span class="price">
          <el-input
            v-model="formData.weight"
            :disabled="!canEdit"
            type="number"
            placeholder="Введите вес изделия"
            @change="save($event)">
            <template #append>г. </template>
          </el-input>
        </span>
      </li>
      <li class="box">
        <h4>Упаковка (cm):</h4>
        <div>
          <el-input
            v-model="formData.box_length"
            :disabled="!canEdit"
            type="number"
            :min="1"
            :max="100"
            placeholder="Длина"
            class="input-with-select"
            @change="save($event)">
            <template #append>
              см
            </template>
          </el-input>
          <el-input
            v-model="formData.box_height"
            :disabled="!canEdit"
            type="number"
            :min="1"
            :max="100"
            placeholder="Высота"
            @change="save($event)">
            <template #append>
              см
            </template>
          </el-input>
          <el-input
            v-model="formData.box_width"
            :disabled="!canEdit"
            type="number"
            :min="1"
            :max="99"
            placeholder="Ширина"
            @change="save($event)">
            <template #append>
              см
            </template>
          </el-input>
        </div>
      </li>
      <li>
        <h4>Подлежит возврату:</h4>
        <el-switch
          v-model="formData.is_refundable"
          :disabled="!canEdit"
          class="ml-2"
          @click="save($event)" />
      </li>
      <li>
        <product-manufacture-care :can-edit="canEdit" />
      </li>
    </ul>
  </div>
  <el-dialog
    v-model="dialog"
    title="Изменить состав"
    @close="closeDialog">
    <div class="dialog_body">
      <el-form
        ref="form"
        :model="selectedManufactureCompositions"
        label-width="20px">
        <el-table
          :data="selectedManufactureCompositions"
          stripe>
          <el-table-column label="Тип материала">
            <template #default="scope">
              <el-form-item
                v-if="scope && scope.$index >= 0"
                label=" "
                :prop="'selectedManufactureCompositions.' + scope.$index + '.id'">
                <el-select
                  v-model="selectedManufactureCompositions[scope.$index].id"
                  placeholder="Выберите тип материала">
                  <el-option
                    v-for="item in compositions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.disabled" />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="Процент">
            <template #default="scope">
              <el-form-item
                v-if="scope && scope.$index >= 0"
                label=" "
                :prop="'selectedManufactureCompositions.' + scope.$index + '.value'">
                <el-input
                  v-model="selectedManufactureCompositions[scope.$index].value"
                  placeholder="укажите процент">
                  <template #append>
                    %
                  </template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="Действие">
            <template #default="scope">
              <el-form-item
                v-if="scope && scope.$index >= 0"
                label=" ">
                <el-button
                  type="danger"
                  size="small"
                  icon="Delete"
                  circle
                  @click="detachComposition(scope.$index)" />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item class="mt-2">
          <el-button
            type="primary"
            plain
            icon="Plus"
            @click="addComposition()">
            Добавить материал
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-button
        type="primary"
        @click="saveCompositions()">
        Сохранить
      </el-button>
    </template>
  </el-dialog>
</template>
<style lang="scss" scoped>
h3 {
  margin-bottom: 20px;
}

ul {
  margin-bottom: 20px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.box {
      > div {
        display: flex;
        flex-direction: column;

        .el-input {
          //width: 150px;
          margin-bottom: 10px;
        }
      }
    }

    h4 {
      margin-right: 10px;
      width: 200px;
    }
  }
}
</style>
