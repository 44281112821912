<script setup>
  import { ElLoading, ElNotification } from 'element-plus'
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import { useRoute } from 'vue-router'

  const store = useStore()
  const route = useRoute()

  const productId = ref(route.params.id)

  const publish = async () => {

    const loading = ElLoading.service({
      lock: true,
      text: 'Загрузка...',
      background: 'rgba(0, 0, 0, 0.7)',
    })

    await store.dispatch('productStore/publish')
    await store.dispatch('productStore/fetchProduct', { id: productId.value })

    loading.close()

    ElNotification({
      title: 'Успех',
      message: 'Товар опубликован!',
      type: 'success',
    })
  }

  const isLoaded = computed(() => store.getters['productStore/product'].id)
  const isPublished = computed(() => store.getters['productStore/product'].is_published)
</script>

<template>
  <el-row v-if="isLoaded && !isPublished">
    <el-col :span="4">
      <el-alert
        show-icon
        title="Данный товар виден только администратору, после начальной настройки нажмите кнопку &quot;Опубликовать товар&quot;"
        type="warning"
        :closable="false" />
    </el-col>
    <el-col :span="4">
      <el-popconfirm
        width="330"
        confirm-button-type="success"
        confirm-button-text="Опубликовать"
        cancel-button-text="Отмена"
        hide-icon
        title="После публикации будет невозможно удалить цвета и размеры, публикуем?"
        @confirm="publish()">
        <template #reference>
          <el-button type="success">
            Опубликовать товар
          </el-button>
        </template>
      </el-popconfirm>
    </el-col>
  </el-row>
</template>

<style scoped>

</style>
