export const mainStore = {
  namespaced: true,
  state: () => ({
    brand: null,
    brandOptions: null,
    loading: false,
  }),

  getters: {
    loading(state) {
      return state.loading
    },
    currentBrand(state) {
      return state.brand
    },
    brandOptions(state) {
      return state.brandOptions
    },
    loggedIn() {
      return !!sessionStorage.getItem('token')
    },
  },

  mutations: {

    setBrand(state, brand) {
      state.brand = brand
    },
    setBrandOptions(state, brandOptions) {
      state.brandOptions = brandOptions
    },
  },

  actions: {
    setBrand(context, brand) {
      context.commit('setBrand', brand)
    },
    setBrandOptions(context, brandOptions) {
      context.commit('setBrandOptions', brandOptions)
    },
  },
}