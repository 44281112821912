<script setup>
  import { ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { ElLoading } from 'element-plus'
  import { ElNotification } from 'element-plus'

  const props = defineProps({
    data: {
      type: Object,
      required: true,
    },
  })
  const fileList = ref([])
  const store = useStore()
  const route = useRoute()

  async function addPhoto() {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
    })
    const images = []
    fileList.value.forEach(img => {
      images.push(img.raw)
    })
    try {
      await store.dispatch('imagesStore/addImage', {
        ...props.data,
        images,
      })
      await store.dispatch('productStore/fetchProduct', { id: route.params.id })
    } catch (error) {
      ElNotification({
        title: 'Error',
        message: error,
        type: 'error',
      })
    } finally {
      fileList.value = []
      loading.close()
    }
  }

  watch(fileList, (img) => {
    if (img.length > 0) {
      addPhoto()
    }
  })
</script>

<template>
  <div>
    <el-upload
      v-model:file-list="fileList"
      class="upload__box"
      action="#"
      drag
      multiple
      accept="image/png, image/jpeg"
      :auto-upload="false"
      :show-file-list="false">
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        Загрузите <br> PNG, JPEG, JPG
      </div>
    </el-upload>
  </div>
</template>

<style lang="scss">
.upload__box{
  .el-upload-dragger{
    padding: 28px 0;
  }
}
</style>
