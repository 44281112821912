<script setup>
  import { useStore } from 'vuex'


  const store = useStore()
  const props = defineProps({
    image: String,
    title: String,
    price: Number,
    loading: Boolean,
  })
</script>

<template>
  <div
    v-loading="loading"
    class="card">
    <div class="img">
      <img
        :src="image"
        alt="preview image">
    </div>
    <p class="title">
      {{ title }}
    </p>
    <p class="price">
      {{ price }} ₽
    </p>
    <el-button
      icon="Delete"
      type="danger"
      @click="$emit('delete')" />
  </div>
</template>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow: hidden;
  padding: 10px;
  flex-shrink: 0;
  margin: 20px;
  width: 220px;
  height: 350px;
  position: relative;

  &:not(:first-child) {
    margin-left: 0;
  }

  .img {
    border-radius: inherit;
    margin-bottom: 10px;
    overflow: hidden;
    //width: 200px;
    height: 200px;
  }

  .title {
    font-size: 16px;
    text-align: left;
  }

  .price {
    text-align: left;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .el-button {
    position: absolute;
    bottom: 10px;
    width: 90%;
  }
}

</style>