import axios, { AxiosResponse } from 'axios'
import { IRole } from '@/types/roleAndPermission.types'

export const roleStore = {
  namespaced: true,
  state: (): { roles: IRole[] } => ({
    roles: [],
  }),
  getters: {
    getRoles(state): IRole[] {
      return state.roles
    },
  },
  mutations: {
    setRoles(state, data): void {
      state.roles = data
    },
  },
  actions: {
    async fetchRoles(context): Promise<void>  {
      await axios.get('roles').then((res: AxiosResponse<IRole[]>) => {
        context.commit('setRoles', res.data)
      })
    },
  },
}