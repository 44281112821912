<script setup>
  import SizeGroupList from '@/components/size/SizeGroupList.vue'
  import SizeList from '@/components/size/SizeList.vue'
  import { useStore } from 'vuex'
  import { computed } from 'vue'


  const store = useStore()
  const canEditDictionaries = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit dictionaries').length >= 1)
</script>

<template>
  <div>
    <header>
      <el-row
        justify="space-between"
        class="mb-2">
        <el-col :span="6">
          <h1>Список размеров</h1>
        </el-col>
      </el-row>
    </header>
    <el-tabs
      type="border-card">
      <el-tab-pane
        label="Размеры">
        <size-list :can-edit="canEditDictionaries" />
      </el-tab-pane>
      <el-tab-pane
        label="Группы">
        <size-group-list :can-edit="canEditDictionaries" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style>

</style>
