<template>
  <assortment-groups-form
    :initial-form-data="{ ...formData }"
    :handle-save="save"
    :message="message"
    :is-creating="true"
    :model-value="modelValue"
    :title="title"
    @close="$emit('update:modelValue', false)" />
</template>

<script setup>

  import AssortmentGroupsForm from '@/components/assortment_group/AssortmentGroupsForm'
  import { ref } from 'vue'
  const emit = defineEmits(['create'])
  defineProps({
    modelValue: Boolean,
    title: String,
  })

  const formData = ref({
    name: '',
  })
  const message = ref('')

  const save = async (newObject) => {
    emit('create', newObject)
  }
</script>