import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const categoriesStore = {
  namespaced: true,
  state: () => ({
    categories: [],
  }),

  getters: {
    categories(state) {
      return state.categories
    },
  },

  mutations: {
    fetchCategories(state, categories) {
      state.categories = categories
    },
  },

  actions: {
    async fetchCategories(context, brandId) {
      await request.get(`categories?brand_id=${brandId}`).then((res) => {
        context.commit('fetchCategories', res.data.categories)
      })
    },
  },
}