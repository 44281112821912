export interface IPromoCode {
  id: number;
  brand_id: number;
  code: string;
  is_one_time: boolean;
  used_count: number;
  user_name: string;
  percent: number | null;
  discount: number | null;
  with_ltv: boolean;
  with_sales: boolean;
  created_at: string;
  updated_at: string;
}

export interface IPromoCodeEdit {
  id?: number;
  code: string;
  is_one_time: boolean;
  percent: number | null;
  discount: number | null;
  with_ltv: boolean;
  with_sales: boolean;
}

export const defaultPromoCodeEdit: IPromoCodeEdit = {
  code: '',
  is_one_time: false,
  percent: null,
  discount: null,
  with_ltv: false,
  with_sales: false,
}