import axios from 'axios'
import { ElNotification } from 'element-plus'

export const manufactureCaresStore = {
  namespaced: true,
  state: () => ({
    manufactureCares: [],
  }),

  getters: {
    manufactureCares(state) {
      return state.manufactureCares
    },
  },

  mutations: {
    fetchManufactureCares(state, manufactureCares) {
      state.manufactureCares = manufactureCares
    },
  },

  actions: {
    async fetchManufactureCares(context) {
      await axios.get(process.env.VUE_APP_CATALOG_API_URL + '/api/v1/cares')
        .then(res => {
          context.commit('fetchManufactureCares', res.data.cares)
          return res
        })
        .catch(err => {
          ElNotification({
            title: 'Error',
            message: err.response.data.error,
            type: 'error',
          })
          throw new Error(err.response.data.message)
        })
    },
        
  },
}