<script setup>
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import RequestHelper from '@/helpers/RequestHelper'
  import { ElNotification } from 'element-plus'
  import { useRoute } from 'vue-router'

  const route = useRoute()

  const request = new RequestHelper()
  const store = useStore()
  const dialog = ref(false)


  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const newMeasurement = ref({
    measurement_id: null,
    sizes: {},
  })

  const measurementsForm = ref(null)
  const createProductMeasurementForm = ref(null)

  const measurementCollection = computed({
    get() {
      const selectedIds = measurements.value.map((measurement) => measurement.id)
      const measurementsEntities = store.getters['measurementsStore/measurements']
      return measurementsEntities.map((measurement) => {
        measurement.disabled = selectedIds.includes(measurement.id)
        return measurement
      })
    },
  })

  const measurements = computed({
    get() {
      const result = []
      if (store.getters['productStore/measurements'] == undefined) {
        return []
      }
      for (const measure of store.getters['productStore/measurements']) {
        const find = result.find((m) => m.id === measure.id)
        const size = store.getters['sizesStore/sizes'].find(
          (s) => s.id === measure.size_id,
        )

        if (size) {
          if (find) {
            find.sizes[size.name] = { value: measure.value, id: measure.size_id }
          } else {
            result.push({
              id: measure.id,
              name: measure.name,
              sizes: {
                [size.name]: { value: measure.value, id: measure.size_id },
              },
            })
          }
        }
      }
      return result
    },
  })

  async function attachMeasurement() {
    if (
      createProductMeasurementForm.value == null ||
      !createProductMeasurementForm.value.checkValidity()
    ) {
      createProductMeasurementForm.value.reportValidity()
      return
    }
    let totalRes = true
    for (const i in newMeasurement.value.sizes) {
      await request
        .put(`products/${store.getters['productStore/product'].id}/attach/measurement`, {
          measurement_id: newMeasurement.value.measurement_id,
          size_id: i,
          value: newMeasurement.value.sizes[i],
        })
        .catch(() => {
          if (totalRes) totalRes = false
        })
    }
    if (totalRes) {
      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Обмер обновлен',
      })
      await store.dispatch('productStore/fetchProduct', { id: route.params.id })
      dialog.value = false
    }
  }

  async function detachMeasurement(measurement) {
    await request
      .put(`products/${store.getters['productStore/product'].id}/detach/measurement`, {
        measurement_id: measurement.id,
      })
      .then((res) => {
        if (res.status === 200) {
          ElNotification({
            title: 'Успех',
            type: 'success',
            message: 'Обмер откреплён',
          })
        }
      })
    await store.dispatch('productStore/fetchProduct', { id: route.params.id })
  }

  function closeDialog() {
    newMeasurement.value = {
      measurement_id: null,
      sizes: {},
    }
    dialog.value = false
  }

  //TODO: переделать на сохранение массивом и скорее всего по кнопке
  async function saveOneMeasurement(measurementData) {
    if (measurementsForm.value == null || !measurementsForm.value.checkValidity()) {
      measurementsForm.value.reportValidity()
      return
    }
    let totalRes = true
    for (const i in measurementData.sizes) {
      await request
        .put(`products/${store.getters['productStore/product'].id}/attach/measurement`, {
          measurement_id: measurementData.id,
          size_id: measurementData.sizes[i].id,
          value: measurementData.sizes[i].value,
        })
        .catch(() => {
          if (totalRes) totalRes = false
        })
    }
    if (totalRes) {
      ElNotification({
        title: 'Успех',
        type: 'success',
        message: 'Обмер обновлен',
      })
      await store.dispatch('productStore/fetchProduct', { id: route.params.id })
      await closeDialog()
    }
  }
</script>

<template>
  <div>
    <h3>Обмеры</h3>
    <br>
    <form ref="measurementsForm">
      <el-table :data="measurements">
        <el-table-column
          label="Название"
          prop="name"
          width="200px"
          fixed />
        <el-table-column
          v-for="size in store.getters['productStore/sizes']"
          :key="size.id"
          :label="size.name"
          :prop="'sizes.' + size.name">
          <template #default="scope">
            <el-input
              v-model="scope.row['sizes'][size.name]['value']"
              :disabled="!canEdit"
              type="number"
              :min="1"
              required
              :prop="scope.row['sizes'][size.name]['value']"
              @change="saveOneMeasurement(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column
          label="Действия"
          width="100px"
          fixed="right">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-col :span="6">
                <el-button
                  type="danger"
                  :disabled="!canEdit"
                  icon="Delete"
                  circle
                  @click="detachMeasurement(scope.row)" />
              </el-col>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </form>
    <el-button
      :disabled="!canEdit"
      class="mt-4 mb-2 mt-2"
      type="primary"
      plain
      style="width: 100%"
      @click="dialog = true">
      Добавить обмер
    </el-button>

    <el-dialog
      v-model="dialog"
      title="Добавить обмер"
      @close="closeDialog">
      <div class="dialog_body">
        <form ref="createProductMeasurementForm">
          <div class="size_input">
            <span>Обмер</span>
            <el-select
              v-model="newMeasurement.measurement_id"
              placeholder="Выберите обмер">
              <el-option
                v-for="measurement in measurementCollection"
                :key="measurement.id"
                :label="measurement.name"
                :value="measurement.id"
                :disabled="measurement.disabled" />
            </el-select>
          </div>
          <el-alert
            v-if="store.getters['productStore/sizes'].length===0"
            :closable="false"
            type="info"
            class="sizes_empty"
            title="У этого товара нет размеров. Добавьте размеры во вкладке &quot;Коммерция&quot;" />
          <div
            v-for="size in store.getters['productStore/sizes']"
            :key="size.id"
            class="size_input">
            <span>{{ size.name }}</span>
            <el-input
              v-model="newMeasurement.sizes[size.id]"
              type="number"
              required
              :min="1" />
          </div>
        </form>
      </div>

      <template #footer>
        <el-button
          type="primary"
          @click="attachMeasurement">
          Сохранить
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.dialog_body {
  display: flex;
  align-items: center;

  form{
    display: flex;
    width: 100%;
  }

  .el-alert{
    width: 100%;
  }

  .size_input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px 0 0;

    span {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .el-select{
      width: 200px;
    }

    .el-input {
      width: 70px;
    }

    &:first-child {
      span {
        text-transform: none;
      }
    }
  }
}
.el-input {
  width: 100px;
}
</style>
