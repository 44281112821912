<script setup>
  import { useStore } from 'vuex'
  import { computed, ref, watch } from 'vue'
  import RequestHelper from '@/helpers/RequestHelper'
  import BarcodeEdit from '@/components/product/content/BarcodeEdit.vue'
  import { ElLoading } from 'element-plus'


  const request = new RequestHelper()
  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const allSizes = computed(() => store.getters['sizesStore/sizes'])
  const productSizes = computed(() => store.getters['productStore/sizes'])

  const loading = ref(false)
  const openDialog = ref(false)
  const selectedSizeId = ref(null)
  const sizeSelectorValue = ref(null)

  const notUsedSizes = computed(() => {
    return allSizes.value.filter(size => {
      return !productSizes.value.some(productSize => productSize.id === size.id)
    })
  })

  /** При загрузке компонента выбирать первый размер продукта */
  watch(() => productSizes.value, sizes => {
    if (sizes && sizes.length && !selectedSizeId.value) {
      selectedSizeId.value = sizes[0]?.id
    }
  })

  watch(() => selectedSizeId.value, id => store.commit('productStore/selectSizeId', id))

  async function attachSize () {
    loading.value = true

    try {
      await request.put(`products/${store.getters['productStore/product'].id}/attach/size`, {
        size_id: sizeSelectorValue.value,
      })
      await store.dispatch('productStore/fetchProduct', { id: store.getters['productStore/product'].id })
      selectedSizeId.value = sizeSelectorValue.value
    } finally {
      loading.value = false
      openDialog.value = false
      sizeSelectorValue.value = null
    }

  }
  async function destroySize (sizeId) {
    const loading = ElLoading.service({
      lock: true,
      text: 'Загрузка...',
      background: 'rgba(0, 0, 0, 0.7)',
    })

    try {
      await store.dispatch('productStore/destroySize', sizeId)
      await store.dispatch('productStore/fetchProduct', { id: store.getters['productStore/product'].id })
    }
    finally {
      loading.close()
      shadeDialogVisible.value = false
    }
  }
</script>

<template>
  <div>
    <div style="margin-bottom: 10px">
      <el-button
        size="large"
        :disabled="!canEdit"
        @click="openDialog = true">
        Добавить размер
      </el-button>
    </div>

    <el-tabs
      v-if="productSizes.length"
      v-model="selectedSizeId"
      v-loading="loading"
      type="border-card">
      <el-tab-pane
        v-for="size in productSizes"
        :key="size.name"
        :label="size.name"
        :name="size.id"
        @tab-remove="destroySize">
        <el-row>
          <barcode-edit v-if="selectedSizeId === size.id" />
        </el-row>
        <el-row v-if="!store.getters['productStore/product'].is_published">
          <el-popconfirm
            width="330"
            confirm-button-type="danger"
            confirm-button-text="Удалить"
            cancel-button-text="Отмена"
            hide-icon
            title="Действительно хотите удалить?"
            @confirm="destroySize(size.id)">
            <template #reference>
              <el-button type="danger">
                Удалить размер
              </el-button>
            </template>
          </el-popconfirm>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      v-model="openDialog"
      title="Добавить размер"
      width="250">
      <el-select
        v-model="sizeSelectorValue"
        placeholder="Выберите размер"
        filterable
        :loading="!allSizes.length">
        <el-option
          v-for="size in notUsedSizes"
          :key="size.id"
          :label="`${size.name} ${size.group ? '- ' + size.group : ''}`"
          :value="size.id">
          <strong>{{ size.name }}</strong> {{ size.group ? '- ' + size.group : '' }}
        </el-option>
      </el-select>
      <template #footer>
        <el-button
          type="primary"
          plain
          :loading="loading"
          :disabled="!sizeSelectorValue"
          @click="attachSize">
          Добавить
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.sizes {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  .el-tag {
    margin: 3px;
  }
}
</style>
