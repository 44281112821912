import axios, { AxiosResponse } from 'axios'
import { IPermission } from '@/types/roleAndPermission.types'

export const permissionStore = {
  namespaced: true,
  state: (): { permissions: IPermission[] } => ({
    permissions: [],
  }),
  getters: {
    getPermissions(state): IPermission[] {
      return state.permissions
    },
  },
  mutations: {
    setPermissions(state, data): void {
      state.permissions = data
    },
  },
  actions: {
    async fetchPermissions(context): Promise<void>  {
      await axios.get('permissions').then((res: AxiosResponse<IPermission[]>) => {
        context.commit('setPermissions', res.data)
      })
    },
  },
}