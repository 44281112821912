import axios from 'axios'
import tokenService from '@/services/tokenService'

axios.defaults.baseURL = process.env.VUE_APP_CATALOG_API_URL + '/api/v1'

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
if (tokenService.getAuthToken() !== null) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenService.getAuthToken()
}
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withCredentials = true

export default axios