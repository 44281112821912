<template>
  <el-dialog
    :model-value="modelValue"
    width="800px"
    :title="title"
    class="custom-dialog"
    :before-close="handleClose"
    top="10px">
    <slot />
    <el-row>
      <el-col :span="20">
        <el-form
          ref="ruleFormRef"
          :model="form"
          label-width="200px"
          :rules="rules">
          <el-form-item
            label="Название"
            prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item
            v-if="isCreating && categories"
            label="Родитель"
            prop="parent_id">
            <el-tree-select
              v-model="form.parent_id"
              :data="categories"
              :check-strictly="true"
              :render-after-expand="false" />
          </el-form-item>
          <el-form-item
            label="Транспорт. длина (см)"
            prop="transport_attrs.length">
            <el-input v-model="form.transport_attrs.length" />
          </el-form-item>
          <el-form-item
            label="Транспорт. ширина (см)"
            prop="transport_attrs.width">
            <el-input v-model="form.transport_attrs.width" />
          </el-form-item>
          <el-form-item
            label="Транспорт. высота (см)"
            prop="transport_attrs.height">
            <el-input v-model="form.transport_attrs.height" />
          </el-form-item>
          <el-form-item
            label="Транспорт. вес (кг)"
            prop="transport_attrs.weight">
            <el-input v-model="form.transport_attrs.weight" />
          </el-form-item>
          <el-form-item
            label="Название для сайта ру."
            prop="site_name.ru">
            <el-input v-model="form.site_name.ru" />
          </el-form-item>
          <el-form-item
            label="Название для сайта анг."
            prop="site_name.en">
            <el-input v-model="form.site_name.en" />
          </el-form-item>
          <el-form-item
            label="Имя ссылки ру."
            prop="site_slug.ru">
            <el-input v-model="form.site_slug.ru" />
          </el-form-item>
          <el-form-item
            label="Имя ссылки анг."
            prop="site_slug.en">
            <el-input v-model="form.site_slug.en" />
          </el-form-item>
          <el-form-item
            label="SEO Description ру."
            prop="site_seo_description.ru">
            <el-input v-model="form.site_seo_description.ru" />
          </el-form-item>
          <el-form-item
            label="SEO Description анг."
            prop="site_seo_description.en">
            <el-input v-model="form.site_seo_description.en" />
          </el-form-item>
          <el-form-item
            label="SEO Keywords ру."
            prop="site_seo_keywords.ru">
            <el-input v-model="form.site_seo_keywords.ru" />
          </el-form-item>
          <el-form-item
            label="SEO Keywords анг."
            prop="site_seo_keywords.en">
            <el-input v-model="form.site_seo_keywords.en" />
          </el-form-item>
          <el-form-item
            label="Маркировка обязательна"
            prop="is_marking_required">
            <el-switch
              v-model="form.is_marking_required"
              :active-value="1"
              :inactive-value="0"
              size="large" />
          </el-form-item>
          <el-form-item
            label="Товарная группа маркировки"
            prop="marking_product_group">
            <el-select
              v-model="form.marking_product_group"
              filterable
              size="large"
              placeholder="Выберите товарную группу маркировки"
              clearable>
              <el-option 
                label="Нет" 
                :value="null" />
              <el-option 
                label="Предметы одежды (lp)" 
                value="lp" />
              <el-option 
                label="Обувь (shoes)" 
                value="shoes" />
              <el-option 
                label="Товары из натурального меха (furs)" 
                value="furs" />
              <el-option 
                label="Парфюм (perfumery)" 
                value="perfumery" />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Отображается в магазине"
            prop="visible_in_shop">
            <el-switch
              v-model="form.visible_in_shop"
              :active-value="1"
              :inactive-value="0"
              size="large" />
          </el-form-item>
          <el-form-item
            label="Таблица размеров"
            prop="size_chart">
            <el-select
              v-model="form.size_chart"
              filterable
              size="large"
              placeholder="Выберите таблицу размеров"
              clearable>
              <el-option
                v-for="item in sizeChartsSelect"
                :key="item.value"
                :label="item.label"
                :value="item.value" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div v-if="message">
      {{ message }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="save(ruleFormRef)">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch } from 'vue'

  const props = defineProps({
    isCreating: Boolean,
    initialFormData: Object,
    handleSave: Function,
    message: String,
    modelValue: Boolean,
    title: String,
    categories: Array,
    parentId: Number,
    sizeCharts: Array,
  })

  const form = ref({})
  const ruleFormRef = ref()

  const rules = reactive({
    name: [{ required: true, message: 'Пожалуйста, заполните поле' }],
    brand_id: [{ required: true, message: 'Пожалуйста, заполните поле' }],
    currency_sign: [{ required: true, message: 'Пожалуйста, заполните поле' }],
    short_name: [{ required: true, message: 'Пожалуйста, заполните поле' }],
  })

  watch(props, (props) => {
    form.value = props.initialFormData
    if (props.parentId) {
      form.value.parent_id = props.parentId
    }
  })

  onMounted(() => {
    form.value = props.initialFormData
    if (props.parentId) {
      form.value.parent_id = props.parentId
    }
  })

  const sizeChartsSelect = computed(() => {
    return props.sizeCharts?.map((value) => {
      return {
        label: value.text,
        value: value.name,
      }
    })
  })

  const save = async (formEl) => {
    await formEl.validate((valid) => {
      if (valid) {
        props.handleSave(form.value)
      }
    })
  }

  const emit = defineEmits(['close'])

  const handleClose = () => {
    emit('close')
  }
</script>

<style scoped></style>
