import { ref } from 'vue'
import StrHelper from '@/helpers/StrHelper'

import { useStore } from 'vuex'
import RequestHelper from '@/helpers/RequestHelper'
import { ElNotification } from 'element-plus'

export default function (models = []) {
  const str = new StrHelper()
  const store = useStore()
  const request = new RequestHelper()


  const data = ref({
    title: {
      ru: '',
      en: '',
    },
    description: {
      ru: '',
      en: '',
    },
    seo_title: {
      ru: '',
      en: '',
    },
    seo_description: {
      ru: '',
      en: '',
    },
    seo_keywords: {
      ru: '',
      en: '',
    },
    url: {
      ru: '',
      en: '',
    },
    material: {
      ru: '',
      en: '',
    },
    care: {
      ru: '',
      en: '',
    },
    platform_type: null,
    platform_id: null,
  })

  //отслеживаем изменение главного цвета
  /*    store.watch(() => {
        return store.getters["productStore/selectedColor"]
    }, () => {
        sync()
    })

    store.watch(() => {
        return store.getters["productStore/selectedPlatform"]
    }, () => {
        sync()
    })*/


  /**
     * Запускает синхронизацию
     * */
  function sync() {
    for (const model of models) {
      syncVar(model.key)
    }
  }

  function syncAll(color_id) {
    for (const field in data.value) {
      data.value[field] = store.getters['productStore/product'].contents.find(el => el.color_id === color_id)[field]
    }
  }

  /**
     * Синхронизирует локальные значения со стором
     * @param {string|number} key
     */
  function syncVar(key) {
    if (store.getters['productStore/content']) {
      data.value[key] = store.getters['productStore/product'].contents.find(
        el =>
          el.color_id === store.getters['productStore/selectedColor']
                    && el.platform_id === (store.getters['productStore/selectedPlatform'] === 'ecom' ? null : store.getters['productStore/selectedPlatform'].id),
      )[key]
    } else {
      data.value[key] = {
        en: '',
        ru: '',
      }
    }
  }

  /**
     * Создаёт slug, записывает и сохранаяет
     * @param {string|number} lang
     */
  function createSlug(lang) {
    data.value.url[lang] = str.slug(data.value.title[lang] + '-' + store.getters['productStore/product'].article_number)
  }


  async function saveChanges(key, platform = null) {

    await request.put(`products/${store.getters['productStore/product'].id}/content`, {
      color_id: store.getters['productStore/selectedColor'],
      [key]: JSON.stringify(data.value[key]),
      platform_id: platform ? platform.id : null,
      platform_type: platform ? platform.type : null,

    }).then(async (res) => {
      const model = models.find(el => el.key === key)
      await store.dispatch('productStore/fetchProduct', { id: store.getters['productStore/product'].id })
      if (res.status === 200) {
        ElNotification({
          title: 'Успех',
          type: 'success',
          message: `Свойство ${model.title} успешно обновлено`,
        })
      } else {
        ElNotification({
          title: 'Ошибка',
          type: 'error',
          message: `Свойство ${model.title} не было обновлено`,
        })
      }

      sync()

    })
  }

  async function saveAll() {
    await request.put(`products/${store.getters['productStore/product'].id}/content`,
      serialize(store.getters['productStore/content']),
    ).then((res) => {
      if (res.status === 200) {
        store.dispatch('productStore/fetchProduct', { id: store.getters['productStore/product'].id })
        ElNotification({
          title: 'Успех!',
          type: 'success',
          message: 'Изменения сохранены',
        })
      } else {
        ElNotification({
          title: 'Ошибка',
          type: 'error',
          message: 'Изменения не были применены',
        })
      }
    })
  }

  function takeFromColor(color_id, model_key, lang_code) {
    data.value[model_key][lang_code] = store.getters['productStore/product'].contents.find(el => el.color_id === color_id)[model_key][lang_code]
  }

  async function takeAllFromColor(color_id) {
    let sourceContent = store.getters['productStore/product'].contents.find(
      el => el.color_id === color_id &&
                el.platform_id === (store.getters['productStore/selectedPlatform'] === 'ecom' ? null : store.getters['productStore/selectedPlatform'].id))

    sourceContent = JSON.parse(JSON.stringify(sourceContent))
    /*
                       sourceContent.color_id = store.getters['productStore/selectedColor']
                       for (let key in data.value) {
                           data.value[key] = sourceContent[key]
                       }*/
    for (const key in store.getters['productStore/content']) {
      store.getters['productStore/content'][key] = sourceContent[key]
    }
  }

  function serialize(source = data) {
    const result = {}
    for (const key in source) {
      if (typeof source[key] !== 'object' || source[key] === null) {
        result[key] = source[key]
      } else {
        result[key] = JSON.stringify(source[key])
      }
    }
    return result
  }


  return {
    store,
    data,
    takeFromColor,
    createSlug,
    saveChanges,
    takeAllFromColor,
    saveAll,
    serialize,
    sync,
    models,
  }
}
