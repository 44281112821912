<script setup>
  import { useStore } from 'vuex'
  import { ref, computed, watch } from 'vue'
  import RequestHelper from '@/helpers/RequestHelper'
  import { ElNotification } from 'element-plus'
  import { useRoute } from 'vue-router'

  const route = useRoute()
  const store = useStore()
  const request = new RequestHelper()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })


  const selectedModelId = ref()
  const modelLoading = ref(false)

  watch(store.getters['productStore/product'], () => {
    updateSelectModelId()
  })

  watch(() => store.getters['productStore/selectedColor'], () => {
    updateSelectModelId()
  })

  const updateSelectModelId = () => {
    const colorId = store.getters['productStore/selectedColor']
    const productContents = store.getters['productStore/product'].contents
    selectedModelId.value = productContents.find(el => el.color_id === colorId)?.photo_model_id
  }

  const selectedModel = computed(() => {
    if (selectedModelId.value) {
      return store.getters['photoModelsStore/photoModels'].find(el => el.id === selectedModelId.value)
    } else {
      return {
        height: 0,
        chest: 0,
        waist: 0,
        hips: 0,
        weight: 0,
      }
    }
  })

  async function savePhotoModels() {
    if (selectedModelId.value !== null) {
      modelLoading.value = true
      const colorId = store.getters['productStore/selectedColor']
      await request.put(`products/${store.getters['productStore/product'].id}/content`, {
        photo_model_id: selectedModelId.value,
        color_id: colorId,
      }).then((res) => {
        if (res.status === 200) {
          ElNotification({
            title: 'Успех',
            type: 'success',
            message: 'Данные фото-модели обновлены',
          })
        }
      }).finally(() => modelLoading.value = false)
      await store.dispatch('productStore/fetchProduct', { id: route.params.id })
    }
  }
</script>

<template>
  <el-row>
    <el-col
      class="row-column"
      :span="24">
      <h3>Параметры модели (человек)</h3>
      <el-select
        v-model="selectedModelId"
        :disabled="!canEdit"
        placeholder="Выберите модель">
        <el-option
          v-for="model in store.getters['photoModelsStore/photoModels']"
          :key="model.id"
          :value="model.id"
          :label="model.name">
          {{ model.name }}
        </el-option>
      </el-select>
    </el-col>
  </el-row>
  <el-row
    v-if="selectedModel"
    justify="space-between">
    <el-col
      class="row-column"
      :span="4">
      <span>Рост: </span>
      <el-input
        v-model="selectedModel.height"
        disabled />
    </el-col>
    <el-col
      class="row-column"
      :span="4">
      <span>Грудь:</span>
      <el-input
        v-model="selectedModel.chest"
        disabled />
    </el-col>
    <el-col
      class="row-column"
      :span="4">
      <span>Талия:</span>
      <el-input
        v-model="selectedModel.waist"
        disabled />
    </el-col>
    <el-col
      class="row-column"
      :span="4">
      <span>Бёдра:</span>
      <el-input
        v-model="selectedModel.hips"
        disabled />
    </el-col>
    <el-col
      class="row-column"
      :span="4">
      <span>Вес:</span>
      <el-input
        v-model="selectedModel.weight"
        disabled />
    </el-col>
    <el-col
      class="row-column"
      :span="2">
      <el-button
        type="primary"
        plain
        :disabled="modelLoading || !canEdit"
        @click="savePhotoModels">
        Сохранить
      </el-button>
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
.el-col {
  span {
    margin-right: 10px;
  }
}
</style>
