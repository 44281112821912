export default class ImageHelper {
  PRESETS = [
    'xs',
    'sm',
    'md',
    'lg',
  ]

  getUrl(id, sizes = 'xs', fm = 'webp') {
    if (this.PRESETS.includes(sizes) && id) {
      return process.env.VUE_APP_IMAGE_SERVER + '/images/' + `${id}_${sizes}.${fm}`
    }
    return null
  }
}
