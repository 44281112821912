<script setup>
  import { ref } from 'vue'
  import RequestHelper from '@/helpers/RequestHelper'
  import { useStore } from 'vuex'

  const store = useStore()
  const request = new RequestHelper()


  const dialogVisible = ref(false)
  const innerVisible = ref(false)

  const colors = ref([])
  const selectedColor = ref({})
  const shade = ref()

  function selectColor(color) {
    selectedColor.value = color
    shade.value = color.value
    innerVisible.value = true
  }

  async function openDialog() {
    await request.get('colors').then((res) => {
      colors.value = res.data.colors
      dialogVisible.value = true
    })
  }

  async function addColor() {
    await store.dispatch('productStore/attachColor', {
      id: selectedColor.value.id,
      shade: shade.value,
    }).then(() => {
      innerVisible.value = false
      dialogVisible.value = false
    })
  }

</script>

<template>
  <div class="add-color">
    <el-button
      size="large"
      type="info"
      text
      @click="openDialog">
      +
    </el-button>
    <el-dialog
      v-model="dialogVisible"
      width="670px"
      title="Добавление цвета к товару">
      <div class="cardColors">
        <div
          v-for="color in colors"
          :key="color.id"
          :class="{color: true}"
          :style="{background: color.value}"
          @click="selectColor(color)">
          <span>{{ color.name.ru }}</span>
        </div>
      </div>

      <el-dialog
        v-model="innerVisible"
        width="350px"
        :title="`Уточнение оттенка &quot;${selectedColor.name?.ru}&quot;`"
        append-to-body>
        <el-color-picker
          v-model="shade"
          size="large" />
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="innerVisible = false">Закрыть</el-button>
            <el-button
              type="primary"
              @click="addColor">Сохранить</el-button>
          </span>
        </template>
      </el-dialog>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="dialogVisible = false">Закрыть</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.add-color {
  > .el-button {
    width: 100%;
    font-size: 40px;
  }
}

.cardColors {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
</style>
