import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const sitesStore = {
  namespaced: true,
  state: () => ({
    sites: [],
  }),

  getters: {
    sites(state) {
      return state.sites
    },
  },

  mutations: {
    async set(state, data) {
      state.sites = data
    },
  },

  actions: {
    async fetch(context, brand_id) {
      let data = []
      await request.get('sites?brand_id='+brand_id).then((res) => {
        data = res.data.sites
      })
      await context.commit('set', data)
    },
  },
}
