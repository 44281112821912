<script setup>
  import { useStore } from 'vuex'
  import common from './common'


  defineProps({
    marketplace: Object,
    canEdit: {
      type: Boolean,
      default: false,
    },
  })
  const models = [
    {
      title: 'TITLE',
      key: 'title',
      strong: true,
    },
  ]

  const store = useStore()
  const actions = common(models)
// todo: исправить логику компонента
</script>

<template>
  <div>
    <!--    <el-row>-->
    <!--      <el-col :span="17"><h3>Данные в карточке товара для клиента</h3></el-col>-->
    <!--    </el-row>-->
    <!--    <el-row v-for="model in models" :key="model.key+'RU'">-->
    <!--      <el-col :class="{label: true, strong: model.strong}" :span="4">-->
    <!--        {{ model.title }}-->
    <!--      </el-col>-->
    <!--      <el-col class="mr-1" :span="13">-->
    <!--        <el-input-->
    <!--            v-model="actions.data[model.key]['ru']"-->
    <!--            :type="model.type"-->
    <!--            @change="actions.saveChanges(model.key, store.getters['productStore/selectedPlatform'])"-->
    <!--        />-->

    <!--      </el-col>-->
    <!--      <el-col :span="6">-->
    <!--        <el-select placeholder="Взять из цвета" v-if="!model.generated">-->
    <!--          <el-option-->
    <!--              v-for="color in store.getters['productStore/colors']"-->
    <!--              :key="color.id"-->
    <!--              :value="color.id"-->
    <!--              :label="color.name"-->
    <!--              @click="actions.takeFromColor(color.id, model.key, 'ru')"-->
    <!--          >-->
    <!--            {{ color.name }}-->
    <!--          </el-option>-->
    <!--        </el-select>-->
    <!--        <el-button-->
    <!--            v-else-->
    <!--            type="primary"-->
    <!--            @click="actions.createSlug('ru')"-->
    <!--            plain-->
    <!--        >Сгенерировать</el-button>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
  </div>
</template>

<style lang="scss" scoped>

</style>