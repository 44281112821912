<script setup>
  import { useStore } from 'vuex'
  import { defineProps } from 'vue'
  import axios from 'axios'

  const store = useStore()

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const changeActivity = async (value) => {
    await axios.put(`products/${store.getters['productStore/product'].id}/activation`, {
      enabled: value,
    })
  }
</script>

<template>
  <div>
    <el-switch
      v-model="store.getters['productStore/product'].enabled"
      :disabled="!canEdit"
      size="large"
      @change="changeActivity" />
    <strong>Артикул: {{ store.getters['productStore/product'].article_number }}
      <span v-if="store.getters['productStore/product'].enabled">(активен)</span>
      <span v-else>(не активен)</span>
    </strong>
  </div>
</template>

<style scoped>
.el-switch {
  margin-right: 20px;
}
</style>
