<template>
  <ConfirmModal
    v-model="deleteDialogVisible"
    :confirm-function="handleConfirmedDelete"
    text="Вы уверены, что хотите вид стирки?" />
  <WashingsCreate
    v-if="createDialogVisible"
    v-model="createDialogVisible"
    title="Создать вид стирки"
    @create="create" />
  <WashingsEdit
    v-if="editeDialogVisible"
    v-model="editeDialogVisible"
    :form-data="editingObject"
    :index="selectedIndex"
    title="Изменить вид стирки"
    @updateObject="updateObject" />

  <div>
    <header>
      <el-row
        justify="space-between"
        class="mb-2">
        <el-col :span="6">
          <h1>Список видов стирки</h1>
        </el-col>
        <el-col
          :span="12"
          justify="ri">
          <el-row justify="end">
            <el-button
              :disabled="!canEditDictionaries"
              type="success"
              class="inline-block"
              @click="createDialogVisible = true">
              Создать
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </header>

    <div v-if="!loadingData">
      <el-table
        :data="mainObjects"
        class="resource-table">
        <el-table-column
          label="ID"
          prop="id"
          sortable />
        <el-table-column
          label="Название"
          prop="name"
          sortable />

        <el-table-column
          label="Действия"
          fixed="right"
          width="120px">
          <template #default="scope">
            <el-space
              wrap
              size="large">
              <el-col :span="6">
                <el-button
                  :disabled="!canEditDictionaries"
                  type="primary"
                  icon="Edit"
                  circle
                  @click="handleEdit(scope.$index, scope.row)" />
              </el-col>
              <el-col :span="6">
                <el-button
                  :disabled="!canEditDictionaries"
                  type="danger"
                  icon="Delete"
                  circle
                  @click="handleDelete(scope.$index, scope.row)" />
              </el-col>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-1 text-danger">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import ConfirmModal from '@/components/shared/ConfirmModal'
  import { computed, ref } from 'vue'
  import useTable from '@/components/shared/table/useTable'

  import { Delete, Edit } from '@element-plus/icons-vue'
  import WashingsCreate from '@/components/washing/WashingsCreate'
  import WashingsEdit from '@/components/washing/WashingsEdit'
  import { useStore } from 'vuex'

  export default {
    name: 'WashingsPage',
    components: { WashingsEdit, WashingsCreate, ConfirmModal },
    setup() {
      const store = useStore()
      const modelName = ref('washing')
      const modelNamePlural = ref('washings')

      const tableHelper = useTable(modelName, modelNamePlural)

      const canEditDictionaries = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit dictionaries').length >= 1)

      return {
        ...tableHelper,
        Delete,
        Edit,
        modelNamePlural,
        canEditDictionaries,
      }
    },
  }
</script>
