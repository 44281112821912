<template>
  <PhotoModelForm
    :initial-form-data="{...formData}"
    :handle-save="save"
    :message="message"
    :is-creating="true"
    :model-value="modelValue"
    :title="title"
    @close="$emit('update:modelValue', false)" />
</template>

<script setup>
  import PhotoModelForm from '@/components/photo_model/PhotoModelsForm'
  import { ref } from 'vue'


  const emit = defineEmits(['create'])
  defineProps({
    modelValue: Boolean,
    title: String,
  })

  const formData = ref({
    name: '',
    group: 'Одежда',
  })
  const message = ref('')

  const save = async (newObject) => {
    emit('create', newObject)
  }

</script>

<style scoped>

</style>