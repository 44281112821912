<template>
  <div
    v-if="formData"
    class="category-edit">
    <CategoriesForm
      :initial-form-data="formData"
      :handle-save="handleSave"
      :is-creating="false"
      :model-value="modelValue"
      :title="title"
      :categories="categories"
      :size-charts="sizeCharts"
      @close="$emit('update:modelValue', false)">
      <div class="title">
        {{ formData.name }}
      </div>
    </CategoriesForm>
  </div>
</template>

<script setup>
  import CategoriesForm from '@/components/category/CategoriesForm'

  const emit = defineEmits(['updateObject'])
  const props = defineProps({
    formData: Object,
    index: Object,
    modelValue: Boolean,
    title: String,
    categories: Array,
    site_name: Object,
    sizeCharts: Array,
  })

  const handleSave = async (form) => {
    emit('updateObject', props.index, form)
  }
</script>

<style scoped></style>
