import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const languagesStore = {
  namespaced: true,
  state: () => ({
    languages: [],
  }),

  getters: {
    languages(state) {
      return state.languages
    },
  },

  mutations: {
    async fetchLanguages(state) {
      await request.get('languages').then((res)=>{
        state.languages = res.data.languages
      })
    },
  },

  actions: {
    async fetchLanguages(context, id) {
      await context.commit('fetchLanguages', id)
    },
  },
}