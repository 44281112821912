<script setup>
  import ArticleNumber from '@/components/product/stylist/ArticleNumber.vue'
  import ModelSelection from '@/components/product/stylist/ModelSelection.vue'
  import ColorSelector from '@/components/product/ColorSelector'
  import ProductRecommendedColors from '@/components/product/stylist/ProductRecommendedColors.vue'
  import ProductRelatedColors from '@/components/product/stylist/ProductRelatedColors.vue'
  import AddPhotos from '@/components/product/AddPhotos.vue'
  import { useStore } from 'vuex'
  import ImageHelper from '@/helpers/ImageHelper'
  import { ref } from 'vue'

  const store = useStore()
  const imageHelper = new ImageHelper()
  const dialogVisible = ref(false)

  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const mainImageId = () => {
    if (store.getters['productStore/imagesByColor'].length > 0) {
      return store.getters['productStore/imagesByColor'][0].id
    } else {
      return false
    }
  }
</script>
<template>
  <div>
    <div class="header">
      <article-number />
    </div>
    <div class="main">
      <div class="image">
        <img
          :src="imageHelper.getUrl(mainImageId(), 'sm')"
          width="300"
          height="450"
          alt="">
        <div>
          <el-button
            :disabled="!canEdit"
            type="primary"
            plain
            style="width: 100%"
            @click="dialogVisible = true">
            Добавить фотографии
          </el-button>
        </div>
      </div>
      <div class="content">
        <color-selector />
        <model-selection :can-edit="canEdit" />
        <el-divider />
        <product-recommended-colors :can-edit="canEdit" />
        <el-divider />
        <product-related-colors :can-edit="canEdit" />
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="Добавить фотографии"
      fullscreen>
      <add-photos />
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>

.main {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  flex-shrink: 0;

  .image {
    min-width: 300px;
    width: 300px;
    margin-right: 15px;
    position: relative;

    img{
      margin-bottom: 10px;
    }
    .mini_img{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .add_images_button{
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      bottom: -20px;
      top: -10px;
      background: white;
      height: 50px;
    }
  }

  .content{
    max-width: calc(100% - 320px);
  }
}

</style>