<script setup>
  import { useRoute } from 'vue-router'
  import { ref, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import ColorSelector from '@/components/product/ColorSelector'
  import MainContent from '@/components/product/content/MainContent'
  import BarcodesEdit from '@/components/product/content/BarcodesEdit'
  import PricesEdit from '@/components/product/content/PricesEdit'
  import CardPrices from '@/components/product/CardPrices.vue'
  import ActivePromotions from '@/components/product/ActivePromotions.vue'
  import ArticleActivity from '@/components/product/ArticleActivity.vue'
  import StylistsTab from '@/views/products/stylistsTab.vue'
  import AddPhotos from '@/components/product/AddPhotos.vue'
  import ImageHelper from '@/helpers/ImageHelper'
  import router from '@/route'
  import ColorActivityToggle from '@/components/product/content/ColorActivityToggle.vue'
  import ProductTabContent from '@/components/product/content/ProductTabContent.vue'
  import ProductCategories from '@/components/product/content/ProductCategories'
  import ProductCompositions from '@/components/product/content/ProductCompositions'
  import CapsuleSelect from '@/components/product/CapsuleSelect.vue'
  import PublishComponent from '@/components/product/content/PublishComponent.vue'
  import ArticleNewToggle from '@/components/product/ArticleNewToggle.vue'
  import SimplifiedLayoutTab from '@/views/products/simplifiedLayoutTab.vue'

  const route = useRoute()
  const store = useStore()
  const imageHelper = new ImageHelper()
  const dialogVisible = ref(false)

  const brandId = computed({
    get() {
      return store.getters['main/currentBrand']?.id
    },
  })

  const productId = ref(route.params.id)

  function setProductWithBarcode() {
    const searchInput = route.query.searchInput
    if (searchInput) {
      const barcode = store.getters['productStore/barcodes'].find(
        (b) => b.barcode == searchInput,
      )
      if (barcode) {
        store.commit('productStore/selectColorId', barcode.color_id)
      //store.commit('productStore/selectSizeId', barcode.size_id) загружает штрихкод, но не переключает таб
      }
    }
  }

  function mainImageId() {
    if (store.getters['productStore/imagesByColor'].length > 0) {
      return store.getters['productStore/imagesByColor'][0].id
    } else {
      return false
    }
  }

  const activeMainAction = ref(route.query.action ?? 'product')

  watch(brandId, (value) => {
    fetchProduct(value)
  })

  watch(activeMainAction, (value) => {
    router.push({ path: '', query: { action: value } })
  })


  const fetchProduct = async (brandId) => {
    if (brandId) {
      await store.dispatch('productStore/fetchProduct', { id: productId.value })
      await store.dispatch('languagesStore/fetchLanguages')
      await store.dispatch('photoModelsStore/fetchPhotoModels')
      await store.dispatch('categoriesStore/fetchCategories', brandId)
      await store.dispatch('colorsStore/fetchColors')
      await store.dispatch('sizesStore/fetchSizes')
      await store.dispatch('measurementsStore/fetchMeasurements')
      await store.dispatch('marketplacesStore/fetch')
      await store.dispatch('sitesStore/fetch', brandId)
      await store.dispatch('compositionsStore/fetchCompositions')
      await store.dispatch('fitsStore/fetchFits')
      await store.dispatch('manufactureGroupsStore/fetchManufactureGroups')
      await store.dispatch('manufactureCategoriesStore/fetchManufactureCategories')
      await store.dispatch('manufactureTypesStore/fetchManufactureTypes')
      await store.dispatch('capsulesStore/fetchCapsules')
      setProductWithBarcode()
    }
  }

  const canPublishProduct = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'publish products').length >= 1)
  const canEditProduct = computed(() => store.getters['userStore/getPermissions'].filter(e => e.name === 'edit products').length >= 1)
</script>

<template>
  <div class="container">
    <publish-component v-if="canPublishProduct" />
    <el-tabs type="border-card">
      <el-tab-pane label="Коммерция">
        <div class="header">
          <el-row>
            <el-col :span="6">
              <article-new-toggle :can-edit="canEditProduct" />
              <article-activity :can-edit="canEditProduct" />
            </el-col>
            <el-col :span="6">
              <capsule-select :can-edit="canEditProduct" />
            </el-col>
          </el-row>
          <el-divider />

          <product-categories :can-edit="canEditProduct" />
          <el-divider />
          <product-compositions :can-edit="canEditProduct" />
          <card-prices />
          <active-promotions />
        </div>
        <div class="main">
          <div class="image">
            <img
              :src="imageHelper.getUrl(mainImageId(), 'sm')"
              alt=""
              width="300"
              height="450">
            <div class="mini_img">
              <img
                v-for="img in store.getters['productStore/imagesByColor'].filter(
                  (el) => el.id !== mainImageId()
                )"
                :key="img.id"
                :src="imageHelper.getUrl(img.id)"
                width="145"
                height="220"
                alt="">
            </div>
            <div class="add_images_button">
              <el-button
                type="primary"
                plain
                style="width: 100%"
                :disabled="!canEditProduct"
                @click="dialogVisible = true">
                Добавить фотографии
              </el-button>
            </div>
          </div>
          <div class="content">
            <color-selector
              :can-add="canEditProduct"
              :can-edit="canEditProduct" />
            <div
              class="actions"
              :class="[
                activeMainAction === 'barcodes' || activeMainAction === 'pricing'
                  ? 'w-100'
                  : 'fit-content',
              ]">
              <div class="controls">
                <color-activity-toggle :can-edit="canEditProduct" />
              </div>

              <barcodes-edit
                v-if="activeMainAction === 'barcodes'"
                :can-edit="canEditProduct" />
              <prices-edit v-if="activeMainAction === 'pricing'" />
            </div>

            <el-divider />

            <main-content
              v-if="activeMainAction === 'product'"
              :can-edit="canEditProduct" />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Продукт">
        <product-tab-content :can-edit="canEditProduct" />
      </el-tab-pane>
      <el-tab-pane label="Стилист">
        <stylists-tab :can-edit="canEditProduct" />
      </el-tab-pane>
      <el-tab-pane label="Упрощенный макет">
        <simplified-layout-tab />
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      v-model="dialogVisible"
      title="Добавить фотографии"
      fullscreen>
      <add-photos />
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

.fit-content {
  width: fit-content;
}

.container {
  width: 100%;

  .main {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    .image {
      min-width: 300px;
      width: 300px;
      margin-right: 15px;
      position: relative;

      img {
        margin-bottom: 10px;
      }
      .mini_img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .add_images_button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        bottom: -20px;
        top: -10px;
        background: white;
        height: 50px;
      }
    }

    .actions {
      display: flex;

      .controls {
        .el-select {
          width: 120px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .content {
    width: 100%;

    .content_data {
      .measurements_108 {
      }

      .platform_content {
      }
    }
  }
}
</style>
