<template>
  <el-dialog
    :model-value="modelValue"
    width="800px"
    :title="title"
    class="custom-dialog"
    :before-close="handleClose">
    <slot />
    <el-row>
      <el-col :span="20">
        <el-form
          ref="ruleFormRef"
          :model="form"
          label-width="220px"
          :rules="rules">
          <el-form-item
            label="Название"
            prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item
            label="Цвет"
            prop="value">
            <el-input v-model="form.value" />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div v-if="message">
      {{ message }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="save(ruleFormRef)">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue'

  const props = defineProps({
    isCreating: Boolean,
    initialFormData: Object,
    handleSave: Function,
    message: String,
    modelValue: Boolean,
    title: String,
  })

  const form = ref({})
  const ruleFormRef = ref()

  const rules = reactive({
    name: [{ required: true, message: 'Пожалуйста, заполните поле' }],
    value: [{ required: true, message: 'Пожалуйста, заполните поле' }],
  })

  watch(props, (props) => {
    form.value = JSON.parse(JSON.stringify(props.initialFormData))
  })

  onMounted(() => {
    form.value = JSON.parse(JSON.stringify(props.initialFormData))
  })

  const save = async (formEl) => {
    await formEl.validate((valid) => {
      if (valid) {
        props.handleSave(form.value)
      }
    })
  }

  const emit = defineEmits(['close'])

  const handleClose = () => {
    emit('close')
  }
</script>

<style scoped></style>
