import axios from 'axios'
import authTokenService from '@/services/tokenService'

const setup = (store): void => {
  axios.interceptors.request.use(
    (config) => {
      const token = authTokenService.getAuthToken()
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
}

export default setup