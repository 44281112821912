<script setup>
  import { computed } from 'vue'
  import { useStore } from 'vuex'


  const store = useStore()

  const props = defineProps({
    selectedPlatforms: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  })
  const emit = defineEmits(['selectPlatforms'])

  const marketplaces = computed(() => {
    return store.state.marketplacesStore.marketplaces
  })
  const sites = computed(() => {
    return store.state.sitesStore.sites
  })
  const platformTree = computed(() => {
    const siteTree = [...sites.value]
    siteTree.forEach(item => {
      item.label = item.name
      item.value = `${item.id},site`
    })
    const marketplaceTree = [...marketplaces.value]
    marketplaceTree.forEach(item => {
      item.label = item.name
      item.value = `${item.id},marketplace`
    })
    return [
      {
        value: 'site',
        label: 'Сайты',
        children: siteTree,
      },
      {
        value: 'marketplace',
        label: 'Маркетплейсы',
        children: marketplaceTree,
      },
    ]
  })

  const isLoadingPlatformTree = computed(() => {
    return platformTree.value.length <= 0
  })

  const selectedPlatforms = computed({
    get() {
      if (props.multiple) {
        return props.selectedPlatforms
      } else {
        return props.selectedPlatforms[0]
      }
    },
    set(newSelectedPlatforms) {
      if (props.multiple) {
        emit('selectPlatforms', newSelectedPlatforms)
      } else {
        emit('selectPlatforms', [newSelectedPlatforms])
      }
    },
  })
</script>

<template>
  <el-tree-select
    v-model="selectedPlatforms"
    v-loading="isLoadingPlatformTree"
    :data="platformTree"
    placeholder="Выберите площадки"
    :multiple="multiple"
    :disabled="disabled"
    default-expand-all />
</template>


<style scoped>

</style>