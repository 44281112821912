export const useValidation = () => {
  const validateEmpty = (rule, value: any, callback): void => {
    if (['', null, undefined].includes(value)) {
      callback(new Error('Пожалуйста, заполните поле!'))
    } else {
      callback()
    }
  }

  const validateZeroOrLess = (rule, value: number, callback): void => {
    if (value <= 0) {
      callback(new Error('Число должно быть больше нуля!'))
    } else {
      callback()
    }
  }

  const validateEmail = (rule, value: string, callback): void => {
    if (value !== '' &&
      value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      !== null) {
      callback()
    } else {
      callback(new Error('Пожалуйста, заполните email корректно!'))
    }
  }

  const validateEmptyArray = (rule, value: [], callback): void => {
    if (value.length === 0) {
      callback(new Error('Пожалуйста, заполните поле!'))
    } else {
      callback()
    }
  }

  return {
    validateEmpty,
    validateZeroOrLess,
    validateEmail,
    validateEmptyArray,
  }
}