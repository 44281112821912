class TokenService {
  setAuthToken(token: string): void {
    localStorage.setItem('auth-token', token)
  }

  getAuthToken(): string|null {
    return localStorage.getItem('auth-token')
  }

  removeAuthToken(): void {
    return localStorage.removeItem('auth-token')
  }
}
  
export default new TokenService()
  