<template>
  <SitesForm
    :initial-form-data="{...formData}"
    :handle-save="save"
    :message="message"
    :is-creating="true"
    :model-value="modelValue"
    :title="title"
    @close="$emit('update:modelValue', false)" />
</template>

<script setup>

  import { watch } from 'vue'
  import SitesForm from '@/components/site/SitesForm'
  import { ref } from 'vue'
  import { useStore } from 'vuex'

  const emit = defineEmits(['create'])
  defineProps({
    modelValue: Boolean,
    title: String,
  })

  const store = useStore()
  // let currentBrand = computed(() => store.getters['main/currentBrand'])

  const formData = ref({
    name: '',
    short_name: '',
    url: '',
    currency_sign: '',
    brand_id: '',
  })

  const brand = store.getters['main/currentBrand']
  if (brand) {
    formData.value.brand_id = brand.id
  }

  watch(() => store.getters['main/currentBrand'], function(brand) {
    formData.value.brand_id = brand.id
  })

  const message = ref('')

  const save = async (newObject) => {
    emit('create', newObject)
  }

</script>


<style scoped>

</style>