import RequestHelper from '../helpers/RequestHelper'

const request = new RequestHelper()

export const colorsStore = {
  namespaced: true,
  state: () => ({
    colors: [],
  }),

  getters: {
    colors(state) {
      return state.colors
    },
    getColorNameById: (state) => (id) => {
      return state.colors.find(color => color.id === id)?.name?.ru
    },
  },

  mutations: {
    async fetchColors(state) {
      await request.get('colors').then((res)=>{
        state.colors = res.data.colors
      })
    },
  },

  actions: {
    async fetchColors(context, id) {
      await context.commit('fetchColors', id)
    },
  },
}
