import RequestHelper from '../helpers/RequestHelper'
import _ from 'lodash'
import { ElNotification } from 'element-plus'
import axios from 'axios'

const request = new RequestHelper()

export const productStore = {
  namespaced: true,
  state: () => ({
    product: {
      images: [],
      categories: {
        main: { id: null },
        sub: [],
      },
    },
    platforms: [
      {
        'id': 1,
        'name': 'To Be Blossom',
        'short_name': 'tbb.ru',
        'url': 'tobeblossom.ru',
        'currency_sign': null,
        'type': 'site',
      },
      {
        'id': 2,
        'name': 'Test',
        'short_name': 'test',
        'url': 'test.ru',
        'currency_sign': null,
        'type': 'site',
      },
    ],
    selectedColor: 0,
    selectedPlatform: 'ecom',
    selectedSize: null,
    relatedProductColors: [],
    recommendedProductColors: [],
    compositions: [],
    collections: [],
    contentEcomTemplate: {
      title: {
        ru: '',
        en: '',
      },
      description: {
        ru: '',
        en: '',
      },
      seo_title: {
        ru: '',
        en: '',
      },
      seo_description: {
        ru: '',
        en: '',
      },
      seo_keywords: {
        ru: '',
        en: '',
      },
      slug: {
        ru: '',
        en: '',
      },
      url: {
        ru: '',
        en: '',
      },
      material: {
        ru: '',
        en: '',
      },
      care: {
        ru: '',
        en: '',
      },
      platform_type: null,
      platform_id: null,
    },
    manufacture: {
      group: {
        id: null,
        name: null,
      },
      category: {
        id: null,
        name: null,
      },
      manufacture_type: {
        id: null,
        name: null,
      },
      class_warm: null,
      weight: null,
      box_width: null,
      box_height: null,
      box_length: null,
      is_refundable: null,
      compositions: [],
    },
    capsule: {
      id: null,
      name: null,
    },
    cares: [],
    selectedMarketplaceId: null,
  }),

  getters: {
    product(state) {
      return state.product
    },
    colors(state) {
      if (state.product.colors) {
        return state.product.colors
      } else {
        return []
      }
    },
    barcodes(state) {
      if (state.product.barcodes) {
        return state.product.barcodes
      } else {
        return []
      }
    },
    images(state) {
      return state.product.images
    },
    imagesByColor(state) {
      if (state.product.images.length > 0) {
        return state.product.images.filter(el => el.color_id === state.selectedColor)
      } else {
        return []
      }
    },
    platforms(state) {
      return state.platforms
    },
    selectedColor(state) {
      return state.selectedColor
    },
    selectedPlatform(state) {
      return state.selectedPlatform
    },
    selectedSize(state) {
      return state.selectedSize
    },
    content(state) {
      const result = state.product.contents.find(el => el.color_id === state.selectedColor && el.platform_id === (state.selectedPlatform === 'ecom' ? null : state.selectedPlatform.id))
      if (!result) {
        return {
          color_id: state.selectedColor,
          ...state.contentEcomTemplate,
        }
      }
      return result
    },
    categories(state) {
      return state.product.categories
    },
    subcategories(state) {
      const subCategories = []
      for (let i = 0; i < state.product.categories.sub.length; i++) {
        subCategories.push(state.product.categories.sub[i].id)
      }

      return subCategories
    },
    sizes(state) {
      return _.sortBy(state.product.sizes, 'position')
    },
    measurements(state) {
      return state.product.measurements
    },
    pricing(state) {
      return state.product.pricing
    },
    relatedProductColors(state) {
      return state.relatedProductColors
    },
    recommendedProductColors(state) {
      return state.recommendedProductColors
    },
    compositions(state) {
      return state.product.compositions
    },
    fits(state) {
      return state.product.fits
    },
    collections(state) {
      return state.product.collections
    },
    salePlatforms(state) {
      return state.product.sale_platforms
    },
    manufacture(state) {
      return state.product.manufacture
    },
    manufactureCompositions(state) {
      return state.product.manufacture ? state.product.manufacture.compositions : []
    },
    capsule(state) {
      return state.product.capsule
    },
    manufactureCares(state) {
      return state.product.manufacture ? state.product.manufacture.cares : []
    },
    selectedMarketplaceId(state) {
      return state.product.selectedMarketplaceId ? state.product.selectedMarketplaceId : 0
    },
  },

  mutations: {
    fetchProduct(state, data) {
      state.product = data
    },
    selectColorId(state, id) {
      state.selectedColor = id
    },
    selectPlatform(state, data) {
      state.selectedPlatform = data
    },
    selectMarketplace(state, id) {
      state.product.selectedMarketplaceId = id
    },
    selectSizeId(state, id) {
      state.selectedSize = id
    },
    fetchRelatedProductColors(state, data) {
      state.relatedProductColors = data
    },
    fetchRecommendedProductColors(state, data) {
      state.recommendedProductColors = data
    },
    selectMainCategory(state, id) {
      state.product.categories.main = { id: id }
    },
    selectSubcategories(state, categoryIds) {
      state.product.categories.sub = categoryIds
    },
    updateCollections(state, collections) {
      state.product.collections = collections
    },
    selectCompositions(state, compositionIds) {
      state.product.compositions = compositionIds
    },
    updateManufacture(state, manufacture) {
      state.product.manufacture = manufacture
    },
    updateManufactureCompositions(state, compositionIds) {
      state.product.manufacture.compositions = compositionIds
    },
    updateCapsule(state, capsuleId) {
      state.product.capsule = { id: capsuleId }
    },
    updateManufactureCares(state, careIds) {
      state.product.cares = careIds
    },
  },

  actions: {
    async fetchProduct(context, { id, color_id=null }) {
      await request.get(`products/${id}/card-data`).then((res) => {
        if (res.status === 200) {
          context.commit('fetchProduct', res.data)
          if (res.data.colors.length > 0) {
            if (color_id) {
              context.commit('selectColorId', color_id)
            } else {
              context.commit('selectColorId', context.state.selectedColor ? context.state.selectedColor : res.data.colors[0].id)
            }
          }
        }
      })
    },
    async createNewContent(context, id) {
      const template = JSON.stringify({ ru: {}, en: {} })
      await request.post(`products/${context.getters.product.id}/content`, {
        color_id: id,
        title: template,
        seo_title: template,
        description: template,
        seo_description: template,
        seo_keywords: template,
        url: template,
        care: template,
        material: template,
      })
    },
    async attachColor(context, {
      id,
      shade,
    }) {
      await request.put(`products/${context.getters.product.id}/attach/color`,
        {
          color_id: id,
          color: shade,
        },
      )
      //await context.dispatch('createNewContent', id)
      await context.dispatch('fetchProduct', { id: context.getters['product'].id })
    },
    async destroyColor(context, colorId) {
      await request.delete(`products/${context.getters.product.id}/colors/${colorId}`)
    },
    async destroySize(context, sizeId) {
      await request.delete(`products/${context.getters.product.id}/sizes/${sizeId}`)
    },
    async fetchRelatedProductColors({ commit, state }) {
      const url = 'products/related/product/colors'
      const queryParams = `?product_id=${state.product.id}&color_id=${state.selectedColor}`
      await request.get(url + queryParams).then((res) => {
        if (res.status === 200) {
          commit('fetchRelatedProductColors', res.data.related_product_colors)
        }
      })
    },
    async fetchRecommendedProductColors({ commit, state }) {
      const url = 'products/recommended/product/colors'
      const queryParams = `?product_id=${state.product.id}&color_id=${state.selectedColor}`
      await request.get(url + queryParams).then((res) => {
        if (res.status === 200) {
          commit('fetchRecommendedProductColors', res.data.recommended_product_colors)
        }
      })
    },
    async changeColorActivity(context, { colorId, enabled }) {
      try {
        await axios.put(`products/${context.getters.product.id}/change-activity/color`,
          {
            color_id: colorId,
            enabled,
          },
        )
        ElNotification({
          title: 'Сохранено',
          type: 'success',
          message: `Цвет товара ${enabled ? 'включен' : 'выключен'}`,
        })
      } catch (exception) {
        ElNotification({
          title: 'Ошибка',
          type: 'error',
          message: `${exception.response.data.message}`,
        })
      }

      await context.dispatch('fetchProduct', { id: context.getters['product'].id })
    },

    async publish({ commit, state }) {
      const url = `products/${state.product.id}/publish`
      await request.post(url)
    },
  },
}
