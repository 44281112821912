<template>
  <CertificateForm
    :initial-form-data="formData"
    :handle-save="handleSave"
    :is-creating="false"
    :model-value="modelValue"
    :title="title"
    @close="$emit('update:modelValue', false)" />
</template>

<script setup>
  import CertificateForm from '@/components/certificate/CertificateForm.vue'
  const emit = defineEmits(['updateObject'])

  const props = defineProps({
    formData: Object,
    updateObject: Function,
    index: Number,
    modelValue: Boolean,
    title: String,
  })

  const handleSave = async (form) => {
    emit('updateObject', props.index, props.formData.number, form)
  }
</script>


<style scoped>

</style>
