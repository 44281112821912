<script setup>

  import { onMounted, watch } from 'vue'
  import { useStore } from 'vuex'
  import { ref } from 'vue'
  import { ElNotification } from 'element-plus'
  import axios from 'axios'
  const store = useStore()

  const prices = ref(null)
  const readyToShow = ref(false)
  const dataLoaded = ref(false)
  const colSize = ref(3)
  const selectedColor = ref(1)//Это временное решение, когда будут другие цвета - надо будет поменять

  const makeEmptyPercentAndDiscount = (platformId) => {
    const newData = { platform_id: platformId, color_id: selectedColor.value }
    return { id: 0, price: { ...newData, price: null }, discount: { ...newData, price: null, percent: null } }
  }

  const fillCurrentColorPrices = () => {
    prices.value = {}
    const platformsFromStore = store.getters['productStore/platforms']
    for (const index in platformsFromStore) {
      const platformId = platformsFromStore[index].id
      prices.value[platformId] = makeEmptyPercentAndDiscount(platformId)
    }
  }

  const fillEmptyPriceAndDiscount = (platformId) => {
    const platformPriceData = prices.value[platformId]
    const newData = { platform_id: platformId, color_id: selectedColor.value }
    if (!platformPriceData?.price) {
      platformPriceData.price = { ...newData, price: null }
    }
    if (!platformPriceData?.discount) {
      platformPriceData.discount = { ...newData, price: null, percent: null }
    } else {
      platformPriceData.discount = { ...platformPriceData.discount, percent: Math.round(platformPriceData.discount.percent / 100) }
    }
  }

  const fillEmptyPlatform = (platformId) => {
    prices.value[platformId] = makeEmptyPercentAndDiscount(platformId)
  }

  const fillEmptyBarcodes = () => {
    const platformsFromStore = store.getters['productStore/platforms']
    if (!store.getters['productStore/colors'] || !platformsFromStore || !dataLoaded.value) {
      return true
    }

    if (!prices.value?.[selectedColor.value]) {
      fillCurrentColorPrices()
    } else {
      for (const index in platformsFromStore) {
        const platformId = platformsFromStore[index].id
        if (!prices.value?.[platformId]) {
          fillEmptyPlatform(platformId)
        } else {
          fillEmptyPriceAndDiscount(platformId)
        }
      }
    }
    readyToShow.value = true
  }

  const loadData = async () => {
    try {
      const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/products/'
        + store.getters['productStore/product'].id + '/prices'

      const response = await axios.get(url)
      prices.value= response.data.product_price[selectedColor.value]
      dataLoaded.value = true
      fillEmptyBarcodes()

    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось загрузить данные',
      })
    }
  }

  const checkAndCreatePrice = async (platformId, value) => {
    if (!value && value < 0) {
      return false
    }
    const saveData = prices.value[platformId].price

    try {
      if (saveData.id) {
        const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/product-prices/' + saveData.id
        await axios.patch(url, saveData)
      } else {
        saveData.platform_id = platformId
        const platform = store.getters['productStore/platforms'].find(el => el.id === platformId)
        saveData.platform_type = platform.type
        saveData.color_id = selectedColor.value
        saveData.product_id = store.getters['productStore/product'].id 
        await axios.post(process.env.VUE_APP_CATALOG_API_URL + '/api/v1/product-prices', saveData)
          .then(function (response) {
            prices.value[platformId].price.id = response.data.id
          })
      }
      ElNotification({
        title: 'Сохранено',
        type: 'info',
        message: 'Цена обновлена',
      })
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось обновить цену',
      })
    }

    checkAndCreateDiscount(platformId, prices.value[platformId].discount.price)
  }

  const checkAndCreateDiscount = async (platformId, value) => {
    if (!value && value < 0) {
      return false
    }
    const saveData = prices.value[platformId].discount

    try {
      if (saveData.id) {
        const url = process.env.VUE_APP_CATALOG_API_URL + '/api/v1/product-discounts/' + saveData.id
        await axios.patch(url, saveData)
      } else {
        saveData.platform_id = platformId
        const platform = store.getters['productStore/platforms'].find(el => el.id === platformId)
        saveData.platform_type = platform.type
        saveData.color_id = selectedColor.value
        saveData.product_id = store.getters['productStore/product'].id 
        await axios.post(process.env.VUE_APP_CATALOG_API_URL + '/api/v1/product-discounts', saveData)
          .then(function (response) {
            prices.value[platformId].discount.id = response.data.id
          })
      }
      ElNotification({
        title: 'Сохранено',
        type: 'info',
        message: 'Скидка обновлена',
      })
    } catch (error) {
      ElNotification({
        title: 'Ошибка',
        type: 'error',
        message: 'Не удалось обновить скидку',
      })
    }
  }

  const updateDiscountPercent = (platformId, discountPrice) => {
    const fullPrice = prices.value[platformId].price.price
    prices.value[platformId].discount.percent = fullPrice === 0? 0: Math.round((1 - discountPrice / fullPrice) * 100)
  }

  const updateDiscountPriceByPercent = (platformId, discountPercent) => {
    const fullPrice = prices.value[platformId].price.price
    prices.value[platformId].discount.price = fullPrice === 0? 0: Math.round(fullPrice * (1 - discountPercent / 100))
  }

  const updateDiscountPriceByFullPrice = (platformId, fullPrice) => {
    const discountPrice = prices.value[platformId].discount.price
    prices.value[platformId].discount.percent = fullPrice === 0? 0: Math.round((1 - discountPrice / fullPrice) * 100)
  }

  onMounted(() => {
    if (store.getters['productStore/product'].id) {
      loadData()
    }
  })

  watch(() => store.getters['productStore/product'], function() {
    loadData()
  })

  watch(() => store.getters['productStore/colors'], function() {
    fillEmptyBarcodes()
  })

  watch(() => store.getters['productStore/platforms'], function() {
    fillEmptyBarcodes()
  })
</script>

<template>
  <div
    v-if="readyToShow"
    class="main_content">
    <el-row
      :gutter="20"
      class="platforms">
      <el-col
        :span="1"
        class="row-name" />
      <el-col
        v-for="platform in store.getters['productStore/platforms']"
        :key="platform.id"
        :span="colSize">
        <div class="platform-name flex-center">
          {{ platform.name }}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="1"
        class="row-name">
        РРЦ
      </el-col>
      <el-col
        v-for="platform in store.getters['productStore/platforms']"
        :key="platform.id"
        :span="colSize">
        <el-input-number
          v-model="prices[platform.id].price.price"
          class="mw-100"
          :min="1"
          :controls="false"
          @input="value => updateDiscountPriceByFullPrice(platform.id, value)"
          @change="value => checkAndCreatePrice(platform.id, value)" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="1"
        class="row-name">
        SALE
      </el-col>
      <el-col
        v-for="platform in store.getters['productStore/platforms']"
        :key="platform.id"
        :span="colSize">
        <el-input-number
          v-model="prices[platform.id].discount.price"
          class="mw-100"
          :min="1"
          :controls="false"
          @input="value => updateDiscountPercent(platform.id, value)"
          @change="value => checkAndCreateDiscount( platform.id, value)" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :span="1"
        class="row-name">
        %
      </el-col>
      <el-col
        v-for="platform in store.getters['productStore/platforms']"
        :key="platform.id"
        :span="colSize">
        <el-input-number
          v-model="prices[platform.id].discount.percent"
          class="mw-100"
          :controls="false"
          @input="value => updateDiscountPriceByPercent(platform.id, value)"
          @change="value => checkAndCreateDiscount( platform.id, value)" />
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.main_content {
  width: 100%;
}

.platform-name {
  font-size: 1.3rem;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mw-100 {
  max-width: 100%;
}

.row-name {
  margin-top: 7px;
  margin-right: 10px;
}
</style>