<script setup>
  import { ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import ImageHelper from '@/helpers/ImageHelper'
  import RequestHelper from '@/helpers/RequestHelper'
  import { ElNotification } from 'element-plus'
  import ItemCard from './ItemCard'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import 'swiper/css'


  defineProps({
    canEdit: {
      type: Boolean,
      default: false,
    },
  })

  const dialog = ref({
    visible: false,
  })

  const store = useStore()
  const imageHelper = new ImageHelper()
  const request = new RequestHelper()

  const relatedProductColors = ref([])
  const deleteLoading = ref(false)

  watch(() => store.getters['productStore/relatedProductColors'], (result) => {
    relatedProductColors.value = result
  })

  watch(() => store.getters['productStore/selectedColor'], async () => {
    await store.dispatch('productStore/fetchRelatedProductColors')
  })

  const getColorName = (colorId) => {
    const colors = store.getters['colorsStore/colors']
    return colors.find(s => s.id === colorId)?.name?.ru
  }

  const searchInput = ref('')
  const searchResult = ref([])
  const currentSearchPage = ref(0)
  const isAll = ref(false)
  const isLoadingSearch = ref(false)

  watch(searchInput, () => {
    isLoadingSearch.value = true
    searchResult.value = []
    isAll.value = false
    currentSearchPage.value = 0

    const prevSearchInput = searchInput.value

    setTimeout(() => {
      if (prevSearchInput === searchInput.value) {
        search().finally(() => isLoadingSearch.value = false)
      }
    }, 500)
  })

  const search = async () => {
    if (isAll.value) {
      return
    }

    isLoadingSearch.value = true
    let searchQuery = '?filter[search]=' + searchInput.value
    currentSearchPage.value++

    if (currentSearchPage.value !== 0) {
      searchQuery += '&page=' + currentSearchPage.value
    }

    try {
      await request.get('products/search/list' + searchQuery).then((res) => {
        searchResult.value.push(...res.data.data)
        currentSearchPage.value = res.data.meta.current_page
        if (res.data.meta.current_page === res.data.meta.last_page) {
          isAll.value = true
        }
      })
    } finally {
      isLoadingSearch.value = false
    }
  }

  const infiniteScrollWatcher = () => {
    if (isLoadingSearch.value) {
      return
    }
    search()
  }

  const select = async (relatedProductId, relatedColorId) => {
    const productId = store.getters['productStore/product'].id
    const colorId = store.getters['productStore/selectedColor']

    isLoadingSearch.value = true

    await request.put('products/related/product/colors/attach', {
      product_id: productId,
      color_id: colorId,
      related_product_id: relatedProductId,
      related_color_id: relatedColorId,
    }).then((res) => {
      if (res.status === 200) {
        dialog.value.visible = false
        ElNotification({
          title: 'Успех',
          type: 'success',
          message: 'Образ добавлен',
        })
      } else {
        ElNotification({
          title: 'Ошибка',
          type: 'error',
          message: 'Образ не был добавлен',
        })
      }
    }).finally(() => isLoadingSearch.value = false)
    await store.dispatch('productStore/fetchRelatedProductColors')
  }

  const detach = async (relatedProductId, relatedColorId) => {
    const productId = store.getters['productStore/product'].id
    const colorId = store.getters['productStore/selectedColor']
    deleteLoading.value = true

    await request.put('products/related/product/colors/detach', {
      product_id: productId,
      color_id: colorId,
      related_product_id: relatedProductId,
      related_color_id: relatedColorId,
    }).then((res) => {
      if (res.status === 200) {
        dialog.value.visible = false
        ElNotification({
          title: 'Успех',
          type: 'success',
          message: 'Образ удален',
        })
      } else {
        ElNotification({
          title: 'Ошибка',
          type: 'error',
          message: 'Образ не был удален',
        })
      }
    }).finally(() => deleteLoading.value = false)

    await store.dispatch('productStore/fetchRelatedProductColors')
  }

  const checkIfAdded = (productItem) => {
    return relatedProductColors.value.filter(
      el => productItem.product_id === el.related_product_id &&
        productItem.color_id === el.related_color_id,
    ).length > 0
  }

  const closeDialog = () => {
    dialog.value.visible = false
    searchInput.value = ''
  }
</script>

<template>
  <div>
    <h3>Образ</h3>

    <swiper
      :slides-per-view="`auto`"
      :space-between="10"
      :grab-cursor="true"
      :mousewheel="true">
      <swiper-slide
        v-for="item in relatedProductColors"
        :key="item">
        <item-card
          :loading="deleteLoading"
          :image="imageHelper.getUrl(item.related_image_id)"
          width="200"
          height="200"
          :title="`${item.related_name} (${getColorName(item.related_color_id)})`"
          :price="item.related_price"
          @delete="detach(item.related_product_id, item.related_color_id)" />
      </swiper-slide>
    </swiper>

    <el-button
      :disabled="!canEdit"
      class="mt-2"
      type="primary"
      plain
      @click="dialog.visible = true">
      Добавить образ
    </el-button>


    <el-dialog
      v-model="dialog.visible"
      title="Добавить образ"
      width="700"
      @close="closeDialog">
      <div class="search-input">
        <el-input
          v-model="searchInput"
          placeholder="По артиклу, названию или штрихкоду"
          clearable>
          <template #prepend>
            <el-button icon="Search" />
          </template>
        </el-input>
      </div>

      <div>
        <ul
          v-infinite-scroll="infiniteScrollWatcher"
          v-loading="isLoadingSearch"
          class="infinite-list"
          style="overflow: auto">
          <li
            v-for="result in searchResult"
            :key="result"
            @click="select(result.product_id, result.color_id)">
            <div
              v-if="!checkIfAdded(result)"
              class="infinite-list-item">
              <el-col :span="3">
                <img
                  :src="imageHelper.getUrl(result.image_id)"
                  alt="Фотография товара">
              </el-col>
              <el-col
                :span="20"
                style="margin-left: 10px">
                <p>{{ result.name }} ({{ getColorName(result.color_id) }})</p>
                <p><strong>{{ result.article_number }}</strong></p>
                <p>{{ result.price }} ₽</p>
              </el-col>
            </div>
          </li>
        </ul>
        <p
          v-if="isLoadingSearch && searchResult.length > 0"
          v-loading="isLoadingSearch" />
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  flex-shrink: 0;
}

.swiper-slide {
  width: auto;
}


.infinite-list {
  height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;
  cursor: pointer;
}

.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  margin-bottom: 20px;
}
</style>
